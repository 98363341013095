<div class="home-page">
  <section aria-label="Find healthcare providers in your area">
    <div class="hero-banner">
      <div class="container">
        <div class="details">
          <div class="titles">
            <div class="main">
              <h1>Find healthcare providers in your area</h1>
            </div>
            <div class="sub">
              Search and book online appointments with your desired practice or
              healthcare provider.
            </div>
          </div>

          <div
            id="provider-search-box"
            class="banner-interactive banner-search"
          >
            <app-search [inline]="true"></app-search>
          </div>
        </div>
        <div class="pictures">
          <div class="sub"></div>
          <div class="main"></div>
          <div class="sub righcorner"></div>
        </div>
      </div>
    </div>
  </section>
  <section aria-label="Healthcare Provider categories">
    <div class="block">
      <div id="modality-icon-list" class="container">
        <h2>Search by Healthcare Provider</h2>
        <div
          class="row healthcare-provide-section hide-mobile modality-icons-lg"
        >
          <div class="healthcare-provide-bx">
            <a
              href="{{
                GlobalStaticSrv.whitecoatDomainPagesUrl
              }}/modalities/acupuncturist/"
              title="Acupuncturist"
            >
              <div class="icon-bx modality-acupuncturist"></div>
              <h4>Acupuncturist</h4>
            </a>
          </div>
          <div class="healthcare-provide-bx">
            <a
              href="{{
                GlobalStaticSrv.whitecoatDomainPagesUrl
              }}/modalities/audiologist/"
              title="Audiologist"
            >
              <div class="icon-bx modality-audiologist"></div>
              <h4>Audiologist</h4>
            </a>
          </div>
          <div class="healthcare-provide-bx">
            <a
              href="{{
                GlobalStaticSrv.whitecoatDomainPagesUrl
              }}/modalities/bowen-therapist/"
              title="Bowen Therapist"
            >
              <div class="icon-bx modality-bowen-therapist"></div>
              <h4>Bowen Therapist</h4>
            </a>
          </div>
          <div class="healthcare-provide-bx">
            <a
              href="{{
                GlobalStaticSrv.whitecoatDomainPagesUrl
              }}/modalities/chinese-herbal-medicine-practitioner/"
              title="Chinese Herbal Medicine Practitioner"
            >
              <div
                class="icon-bx modality-chinese-herbal-medicine-practitioner"
              ></div>
              <h4>Chinese Herbal Medicine Practitioner</h4>
            </a>
          </div>
          <div class="healthcare-provide-bx">
            <a
              href="{{
                GlobalStaticSrv.whitecoatDomainPagesUrl
              }}/modalities/chiropractor/"
              title="Chiropractor"
            >
              <div class="icon-bx modality-chiropractor"></div>
              <h4>Chiropractor</h4>
            </a>
          </div>

          <div class="healthcare-provide-bx">
            <a
              href="{{
                GlobalStaticSrv.whitecoatDomainPagesUrl
              }}/modalities/dentist/"
              title="Dentist"
            >
              <div class="icon-bx modality-dentist"></div>
              <h4>Dentist</h4>
            </a>
          </div>
          <div class="healthcare-provide-bx">
            <a
              href="{{
                GlobalStaticSrv.whitecoatDomainPagesUrl
              }}/modalities/dietitian/"
              title="Dietitian"
            >
              <div class="icon-bx modality-dietitian"></div>
              <h4>Dietitian</h4>
            </a>
          </div>
          <div class="healthcare-provide-bx">
            <a
              href="{{
                GlobalStaticSrv.whitecoatDomainPagesUrl
              }}/modalities/exercise-physiologist/"
              title="Exercise Physiologist"
            >
              <div class="icon-bx modality-exercise-physiologist"></div>
              <h4>Exercise Physiologist</h4>
            </a>
          </div>
          <div class="healthcare-provide-bx">
            <a
              href="{{
                GlobalStaticSrv.whitecoatDomainPagesUrl
              }}/modalities/general-practitioner/"
              title="General Practitioner"
            >
              <div class="icon-bx modality-general-practitioner"></div>
              <h4>General Practitioner</h4>
            </a>
          </div>
          <div class="healthcare-provide-bx">
            <a
              href="{{
                GlobalStaticSrv.whitecoatDomainPagesUrl
              }}/modalities/homeopath/"
              title="Homeopath"
            >
              <div class="icon-bx modality-homeopath"></div>
              <h4>Homeopath</h4>
            </a>
          </div>

          <div class="healthcare-provide-bx">
            <a
              href="{{
                GlobalStaticSrv.whitecoatDomainPagesUrl
              }}/modalities/myotherapist/"
              title="Myotherapist"
            >
              <div class="icon-bx modality-myotherapist"></div>
              <h4>Myotherapist</h4>
            </a>
          </div>
          <div class="healthcare-provide-bx">
            <a
              href="{{
                GlobalStaticSrv.whitecoatDomainPagesUrl
              }}/modalities/naturopath/"
              title="Naturopath"
            >
              <div class="icon-bx modality-naturopath"></div>
              <h4>Naturopath</h4>
            </a>
          </div>
          <div class="healthcare-provide-bx">
            <a
              href="{{
                GlobalStaticSrv.whitecoatDomainPagesUrl
              }}/modalities/nutritionist/"
              title="Nutritionist"
            >
              <div class="icon-bx modality-nutritionist"></div>
              <h4>Nutritionist</h4>
            </a>
          </div>
          <div class="healthcare-provide-bx">
            <a
              href="{{
                GlobalStaticSrv.whitecoatDomainPagesUrl
              }}/modalities/occupational-therapist/"
              title="Occupational Therapist"
            >
              <div class="icon-bx modality-occupational-therapist"></div>
              <h4>Occupational Therapist</h4>
            </a>
          </div>
          <div class="healthcare-provide-bx">
            <a
              href="{{
                GlobalStaticSrv.whitecoatDomainPagesUrl
              }}/modalities/optometrist/"
              title="Optometrist"
            >
              <div class="icon-bx modality-optometrist"></div>
              <h4>Optometrist</h4>
            </a>
          </div>

          <div class="healthcare-provide-bx">
            <a
              href="{{
                GlobalStaticSrv.whitecoatDomainPagesUrl
              }}/modalities/osteopath/"
              title="Osteopath"
            >
              <div class="icon-bx modality-osteopath"></div>
              <h4>Osteopath</h4>
            </a>
          </div>
          <div class="healthcare-provide-bx">
            <a
              href="{{
                GlobalStaticSrv.whitecoatDomainPagesUrl
              }}/modalities/personal-trainer/"
              title="Personal Trainer"
            >
              <div class="icon-bx modality-personal-trainer"></div>
              <h4>Personal Trainer</h4>
            </a>
          </div>
          <div class="healthcare-provide-bx">
            <a
              href="{{
                GlobalStaticSrv.whitecoatDomainPagesUrl
              }}/modalities/physiotherapist/"
              title="Physiotherapist"
            >
              <div class="icon-bx modality-physiotherapist"></div>
              <h4>Physiotherapist</h4>
            </a>
          </div>
          <div class="healthcare-provide-bx">
            <a
              href="{{
                GlobalStaticSrv.whitecoatDomainPagesUrl
              }}/modalities/pilates/"
              title="Pilates"
            >
              <div class="icon-bx modality-pilates"></div>
              <h4>Pilates</h4>
            </a>
          </div>
          <div class="healthcare-provide-bx">
            <a
              href="{{
                GlobalStaticSrv.whitecoatDomainPagesUrl
              }}/modalities/podiatrist/"
              title="Podiatrist"
            >
              <div class="icon-bx modality-podiatrist"></div>
              <h4>Podiatrist</h4>
            </a>
          </div>

          <div class="healthcare-provide-bx">
            <a
              href="{{
                GlobalStaticSrv.whitecoatDomainPagesUrl
              }}/modalities/psychologist/"
              title="Psychologist"
            >
              <div class="icon-bx modality-psychologist"></div>
              <h4>Psychologist</h4>
            </a>
          </div>
          <div class="healthcare-provide-bx">
            <a
              href="{{
                GlobalStaticSrv.whitecoatDomainPagesUrl
              }}/modalities/remedial-massage-therapist/"
              title="Remedial Massage Therapist"
            >
              <div class="icon-bx modality-remedial-massage-therapist"></div>
              <h4>Remedial Massage Therapist</h4>
            </a>
          </div>
          <div class="healthcare-provide-bx">
            <a
              href="{{
                GlobalStaticSrv.whitecoatDomainPagesUrl
              }}/modalities/shiatsu-therapist/"
              title="Shiatsu Therapist"
            >
              <div class="icon-bx modality-shiatsu-therapist"></div>
              <h4>Shiatsu Therapist</h4>
            </a>
          </div>
          <div class="healthcare-provide-bx">
            <a
              href="{{
                GlobalStaticSrv.whitecoatDomainPagesUrl
              }}/modalities/speech-pathologist/"
              title="Speech Pathologist"
            >
              <div class="icon-bx modality-speech-pathologist"></div>
              <h4>Speech Pathologist</h4>
            </a>
          </div>
          <div class="healthcare-provide-bx">
            <a
              href="{{
                GlobalStaticSrv.whitecoatDomainPagesUrl
              }}/modalities/western-herbalist/"
              title="Western Herbalist"
            >
              <div class="icon-bx modality-western-herbalist"></div>
              <h4>Western Herbalist</h4>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section aria-label="Healthcare Specialists categories">
    <div class="block">
      <div id="modality-icon-list" class="container">
        <h2>Specialists</h2>
        <div
          class="row healthcare-provide-section hide-mobile modality-icons-lg"
        >
          <div class="healthcare-provide-bx">
            <a
              href="{{
                GlobalStaticSrv.whitecoatDomainPagesUrl
              }}/specialists/audiologist/"
              title="Audiologist"
            >
              <div class="icon-bx modality-specialist-audiologist"></div>
              <h4>Audiologist</h4>
            </a>
          </div>
          <div class="healthcare-provide-bx">
            <a
              href="{{
                GlobalStaticSrv.whitecoatDomainPagesUrl
              }}/specialists/dermatologist/"
              title="Dermatologist"
            >
              <div class="icon-bx modality-specialist-dermatologist"></div>
              <h4>Dermatologist</h4>
            </a>
          </div>
          <div class="healthcare-provide-bx">
            <a
              href="{{
                GlobalStaticSrv.whitecoatDomainPagesUrl
              }}/specialists/endocrinologist/"
              title="Endocrinologist"
            >
              <div class="icon-bx modality-specialist-endocrinologist"></div>
              <h4>Endocrinologist</h4>
            </a>
          </div>
          <div class="healthcare-provide-bx">
            <a
              href="{{
                GlobalStaticSrv.whitecoatDomainPagesUrl
              }}/specialists/gynaecologist/"
              title="Gynaecologist"
            >
              <div class="icon-bx modality-specialist-gynaecologist"></div>
              <h4>Gynaecologist</h4>
            </a>
          </div>
          <div class="healthcare-provide-bx">
            <a
              href="{{
                GlobalStaticSrv.whitecoatDomainPagesUrl
              }}/specialists/neurologist/"
              title="Neurologist"
            >
              <div class="icon-bx modality-specialist-neurologist"></div>
              <h4>Neurologist</h4>
            </a>
          </div>

          <div class="healthcare-provide-bx">
            <a
              href="{{
                GlobalStaticSrv.whitecoatDomainPagesUrl
              }}/specialists/oncologist/"
              title="Oncologist"
            >
              <div class="icon-bx modality-specialist-oncologist"></div>
              <h4>Oncologist</h4>
            </a>
          </div>
          <div class="healthcare-provide-bx">
            <a
              href="{{
                GlobalStaticSrv.whitecoatDomainPagesUrl
              }}/specialists/orthopaedic-surgeon/"
              title="Orthopaedic Surgeon"
            >
              <div
                class="icon-bx modality-specialist-orthopaedic-surgeon"
              ></div>
              <h4>Orthopaedic Surgeon</h4>
            </a>
          </div>
          <div class="healthcare-provide-bx">
            <a
              href="{{
                GlobalStaticSrv.whitecoatDomainPagesUrl
              }}/specialists/radiologist/"
              title="Radiologist"
            >
              <div class="icon-bx modality-specialist-radiologist"></div>
              <h4>Radiologist</h4>
            </a>
          </div>
          <div class="healthcare-provide-bx">
            <a
              href="{{
                GlobalStaticSrv.whitecoatDomainPagesUrl
              }}/specialists/speech-pathologist/"
              title="Speech Pathologist"
            >
              <div class="icon-bx modality-specialist-speech-pathologist"></div>
              <h4>Speech Pathologist</h4>
            </a>
          </div>
          <div class="healthcare-provide-bx">
            <a
              href="{{
                GlobalStaticSrv.whitecoatDomainPagesUrl
              }}/specialists/urologist/"
              title="Urologist"
            >
              <div class="icon-bx modality-specialist-urologist"></div>
              <h4>Urologist</h4>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
