import { Component, Input } from '@angular/core';

import { Comment } from '../../../interfaces/comment';
import { ReviewRating } from '../../../interfaces/review-rating';

@Component({
  selector: 'app-practice-reviews-fap',
  templateUrl: './practice-reviews-fap.component.html',
  styleUrls: ['./practice-reviews-fap.component.scss'],
})
export class PracticeReviewsFapComponent {
  @Input()
  ratingSummaries: ReviewRating[];

  @Input('reviews')
  set setReviews(value: Comment[]) {
    if (!value) return;

    this.allReviews = value;

    this.numOfPages = this.allReviews.length / this.pageSize;
    this.pageNumbers = [];

    for (let i = 0; i <= this.numOfPages; i++) {
      this.pageNumbers[i] = i;
    }

    this.gotoPage(0);
  }

  allReviews: Comment[];
  pagedReviews: Comment[];

  pageSize: number;
  pageNumbers: number[];
  currentPage: number;
  numOfPages: number;
  startItem: number;
  endItem: number;

  constructor() {
    this.currentPage = 0;
    this.pageSize = 10;
    this.startItem = 1;
    this.endItem = 1;
    this.pagedReviews = [];
  }

  setCurrentPage() {
    if (!this.allReviews) return;

    let start = this.currentPage * this.pageSize;
    let end = start + this.pageSize;
    this.pagedReviews = this.allReviews.slice(start, end);
  }

  gotoPage(page: number) {
    this.currentPage = page;
    this.setCurrentPage();
  }
  averageStarRatingPercent(score: number) {
    return ((score / 5) * 100).toFixed(2);
  }
}
