import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ModalityService } from '../../../services/modality.service';
import { ModalityWithKeyword } from '../../../interfaces/modality-with-keyword';
import { SharedService } from '../../../services/shared.service';

@Component({
  selector: 'app-search-modality',
  templateUrl: './search-modality.component.html',
  styleUrls: ['./search-modality.component.scss'],
})
export class SearchModalityComponent implements OnInit {
  searchFormControl: FormControl;
  @Output()
  onModalitySelected = new EventEmitter<string | null>();
  @Input() inline = false;
  selectedModality: string;
  isModalitySuggestionBoxVisible: boolean;
  mostPopularModalities: string[] = [];
  allOtherModalities: string[] = [];
  empty: boolean;
  searchResults: string[];
  keywordSearchResults: ModalityWithKeyword[];

  optionTimeout: any;

  constructor(
    private modalityService: ModalityService,
    private sharedService: SharedService,
  ) {
    this.empty = true;
    this.hideModalitySuggestionBox();
    this.searchFormControl = new FormControl();
  }

  ngOnInit() {
    //initial the modality value
    this.setSelectedModality(this.sharedService.getSharedInitialModality());

    this.searchFormControl.valueChanges.forEach((value: string) => {
      this.filterModalitySuggestionBox(value);
    });

    this.modalityService.getAllTheOtherModalities().subscribe((x) => {
      this.allOtherModalities = x;
    });

    this.modalityService.getMostPopularModalites().subscribe((x) => {
      this.mostPopularModalities = x;
    });

    //so far only subscribe from the message subject to initialize the searchbox
    this.sharedService.message$.subscribe((msg) => {
      this.setSelectedModality(this.sharedService.getSharedInitialModality());
    });
  }

  clear() {
    this.selectedModality = '';
    this.onModalitySelected.emit(null);
  }

  setSelectedModality(modality: string) {
    if (modality && modality.length > 0) {
      this.selectedModality = modality
        ? modality.charAt(0).toUpperCase() + modality.slice(1)
        : '';
      this.onModalitySelected.emit(modality);
      this.hideModalitySuggestionBox();
    }
  }

  showModalitySuggestionBox() {
    this.isModalitySuggestionBoxVisible = true;
    clearTimeout(this.optionTimeout);
  }

  hideModalitySuggestionBox() {
    this.optionTimeout = setTimeout(() => {
      this.isModalitySuggestionBoxVisible = false;
    }, 200);
  }

  filterModalitySuggestionBox(value: string) {
    if (!value) {
      // nothing to filter for matching modalities
      this.empty = true;
      return;
    }
    // set flag to start showing matching results
    this.empty = false;

    // TODO: what if a user just pastes in a keyword, the below condition wouldn't be met
    if (value.length === 1) {
      // if we have 1 character, we can build up the keyword modality list
      this.addModalitiesFromKeywordToKeywordSearchResults(value);
    }

    // get matching results from modality list
    const loweredValue = value.toLowerCase();
    this.searchResults = this.mostPopularModalities
      .filter((item) => item.toLowerCase().indexOf(loweredValue) !== -1)
      .concat(
        this.allOtherModalities.filter(
          (item) => item.toLowerCase().indexOf(loweredValue) !== -1,
        ),
      );

    // get matching results from keywords and add the modalities to the searchResults array
    if (this.keywordSearchResults && this.keywordSearchResults.length > 0) {
      this.searchResults = this.searchResults.concat(
        this.keywordSearchResults
          .filter((x) => x.keyword) // remove null or undefined keywords
          .filter((x) => x.keyword.toLowerCase().indexOf(loweredValue) !== -1)
          .map((a) => a.name),
      );
    }
  }

  private addModalitiesFromKeywordToKeywordSearchResults(keyword: string) {
    this.modalityService.keywordSearch(keyword).subscribe(
      (results: ModalityWithKeyword[]) => {
        if (results.length > 0) {
          this.keywordSearchResults = results;
        }
      },
      (error) => {
        console.log(error);
      },
    );
  }
}
