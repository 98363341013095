import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ProviderSearchResult } from '../../../interfaces/provider-search-result';

import * as Hammer from '@egjs/hammerjs';

@Component({
  selector: 'app-provider-nearby',
  templateUrl: './provider-nearby.component.html',
  styleUrls: ['./provider-nearby.component.scss'],
})
export class ProviderNearbyComponent implements OnInit, AfterViewInit {
  @Input() isPractice: boolean;

  @Input() providerNearBy: ProviderSearchResult[];

  sectionTitle = '';
  @ViewChild('providerNearby') carousel: ElementRef;

  constructor() {
    this.isPractice = false;
  }

  ngOnInit() {
    this.sectionTitle = this.isPractice
      ? 'Similar practices nearby'
      : 'Similar practitioners nearby';
  }

  ngAfterViewInit(): void {
    this.initializeHammerEffect();
  }

  initializeHammerEffect() {
    const hammer: any = new Hammer.Manager(this.carousel.nativeElement);

    hammer
      .add(new Hammer.Swipe({ direction: Hammer.DIRECTION_HORIZONTAL }))
      .recognizeWith([]);

    // Handle swipe left and swipe right events
    hammer.on('swipeleft', () => {
      this.onSwipeLeft();
    });
    hammer.on('swiperight', () => {
      this.onSwipeRight();
    });
  }
  onSwipeLeft() {
    const nextButton = this.carousel.nativeElement.querySelector(
      '.right.carousel-control',
    );
    nextButton.click();
  }

  onSwipeRight() {
    const prevButton = this.carousel.nativeElement.querySelector(
      '.left.carousel-control',
    );
    prevButton.click();
  }
}
