import { Component } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { SettingsService } from '../../services/settings.service';

import { SearchCriteria } from '../../interfaces/search-criteria';
import { PracticeFap } from '../../interfaces/practice-fap';
import { ProviderSummary } from '../../interfaces/provider-summary';
import { PhiGroupProviderType } from '../../interfaces/phi-group-provider-type';
import { ProfileDetailsFap } from '../../interfaces/profile-details-fap';
import { GlobalStaticService } from 'src/app/services/global.static-service';

@Component({
  selector: 'app-practice-cbhs-corp-fap',
  templateUrl: './practice-cbhs-corp-fap.component.html',
  styleUrls: ['./practice-cbhs-corp-fap.component.scss'],
})
export class PracticeCbhsCorpFapComponent {
  updateMetaInfo: any;
  phi: string;
  practiceId: number;
  providerId: number;
  modalityId: number;
  activeTab: string;
  showServices: boolean;
  showPractitioners: boolean;
  showAbout: boolean;
  showHealthFunds: boolean;
  practiceDetails: ProfileDetailsFap;
  practitioners: ProviderSummary[];
  phiGroupProviderTypes: PhiGroupProviderType[];
  searchCriteria: SearchCriteria;
  metaTagContent: string;
  keywordsTagContent: string;
  url: string;
  schema: {};
  reviews: any[];
  hasReviewFeature: boolean = true;

  constructor(
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private meta: Meta,
    private settings: SettingsService,
    private httpClient: HttpClient,
    private datePipe: DatePipe,
  ) {
    this.hasReviewFeature = this.settings.hasReviewFeature();
    this.activeTab = this.hasReviewFeature ? 'reviews' : 'Practitioner';

    this.updateMetaInfo = true;
    this.url = GlobalStaticService.API_BASE_URL;

    this.phi = 'cbhscorporate';

    this.activatedRoute.params.subscribe((params: Params) => {
      this.providerId = params['providerId'];
      this.modalityId = params['modalityId'];
      this.searchCriteria = {
        modality: params['modality'],
        postcode: params['postcode'],
        state: params['state'],
        suburb: params['suburb'],
        searchText: '',
        isPractice: true,
      };
    });

    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.practiceId = params['id'];
    });

    if (this.practiceId) {
      this.httpClient
        .get<PracticeFap>(
          this.url +
            `api/practice/find-provider/${this.phi}/getpracticefapbyid/${this.practiceId}`,
        )
        .subscribe(
          (practice: PracticeFap) => {
            this.initPracticeDetails(practice);
          },
          (error) => console.error(error),
        );
    } else if (this.providerId) {
      this.httpClient
        .get<PracticeFap>(
          this.url +
            `api/practice/find-provider/${this.phi}/getPracticefapByProviderId/${this.providerId}/${this.modalityId}`,
        )
        .subscribe(
          (practice: PracticeFap) => {
            this.initPracticeDetails(practice);
          },
          (error) => console.error(error),
        );
    }
  }

  ngOnInit() {}

  initPracticeDetails(practice: PracticeFap) {
    if (practice) {
      this.practiceDetails = practice.practiceDetails;
      this.practitioners = practice.practitioners;
      this.phiGroupProviderTypes = practice.phiGroupProviderTypes;

      this.showServices =
        this.practiceDetails.services &&
        this.practiceDetails.services.length > 0;
      this.showPractitioners =
        this.practitioners && this.practitioners.length > 0;
      this.showAbout =
        (this.practiceDetails.bio != null &&
          this.practiceDetails.bio.text != null &&
          this.practiceDetails.bio.text.length > 0) ||
        (this.practiceDetails.associations != null &&
          this.practiceDetails.associations.length > 0);
      this.showHealthFunds =
        this.practiceDetails.healthFunds != null &&
        this.practiceDetails.healthFunds.length > 0;

      this.setPageTitle(this.practiceDetails.providerSummary);

      if (this.updateMetaInfo) {
        var msg = this.hasReviewFeature ? ', review ' : ' ';

        this.metaTagContent =
          this.practiceDetails.providerSummary.businessName +
          ' was found in ' +
          this.searchCriteria.suburb.substring(0, 1).toUpperCase() +
          this.searchCriteria.suburb.substring(1) +
          ` by Whitecoat search engine designed to help Australians to find, compare${msg}and book appointments for any kind of health care providers in any location Australia wide.`;
        this.keywordsTagContent =
          this.practiceDetails.providerSummary.businessName +
          (this.practiceDetails.providerSummary.modality.name
            ? ', ' + this.practiceDetails.providerSummary.modality.name
            : '') +
          (this.practiceDetails.providerSummary.address.suburb
            ? ', ' + this.practiceDetails.providerSummary.address.suburb
            : '') +
          (this.practiceDetails.providerSummary.address.postcode
            ? ', Postcode: ' +
              this.practiceDetails.providerSummary.address.postcode
            : '');
        this.meta.updateTag({
          name: 'description',
          content: this.metaTagContent,
        });
        this.meta.updateTag({
          property: 'og:description',
          content: this.metaTagContent,
        });
        this.meta.updateTag({
          name: 'keywords',
          content: this.keywordsTagContent,
        });
      }

      var reviews: Array<any> = [];

      if (this.hasReviewFeature) {
        practice.practiceDetails.comments.forEach((i) => {
          reviews.push({
            datePublished: this.datePipe.transform(i.createdDate, 'dd/MM/yyyy'),
            description: i.text,
            author: {
              '@type': 'Organization',
              name: 'Anonymous',
            },
          });
        });
      }

      this.schema = {
        '@context': 'http://schema.org',
        '@type': 'MedicalBusiness',
        name: practice.practiceDetails.providerSummary.displayName.asFullName,
        image: practice.practiceDetails.providerSummary.providerPicture,
        '@id': practice.practiceId,
        url: practice.practiceDetails.location.url,
        telephone: practice.practiceDetails.contactDetails.phone,
        PriceRange: '$',
        address: {
          '@type': 'PostalAddress',
          streetAddress: practice.practiceDetails.providerSummary.address.line1,
          addressLocality:
            practice.practiceDetails.providerSummary.address.suburb,
          addressRegion: practice.practiceDetails.providerSummary.address.state,
          postalCode: practice.practiceDetails.providerSummary.address.postcode,
          addressCountry: 'AU',
        },
        openingHoursSpecification: {
          '@type': 'OpeningHoursSpecification',
          dayOfWeek: [
            practice.practiceDetails.providerWeeklyOpening.mondayOpen
              ? 'Mo ' +
                practice.practiceDetails.providerWeeklyOpening.mondayOpening +
                '-' +
                practice.practiceDetails.providerWeeklyOpening.mondayClosing
              : 'Mo Closed',
            practice.practiceDetails.providerWeeklyOpening.tuesdayOpen
              ? 'Tu ' +
                practice.practiceDetails.providerWeeklyOpening.tuesdayOpening +
                '-' +
                practice.practiceDetails.providerWeeklyOpening.tuesdayClosing
              : 'Tu Closed',
            practice.practiceDetails.providerWeeklyOpening.wednesdayOpen
              ? 'We ' +
                practice.practiceDetails.providerWeeklyOpening
                  .wednesdayOpening +
                '-' +
                practice.practiceDetails.providerWeeklyOpening.wednesdayClosing
              : 'We Closed',
            practice.practiceDetails.providerWeeklyOpening.thursdayOpen
              ? 'Th ' +
                practice.practiceDetails.providerWeeklyOpening.thursdayOpening +
                '-' +
                practice.practiceDetails.providerWeeklyOpening.thursdayClosing
              : 'Th Closed',
            practice.practiceDetails.providerWeeklyOpening.fridayOpen
              ? 'Fr ' +
                practice.practiceDetails.providerWeeklyOpening.fridayOpening +
                '-' +
                practice.practiceDetails.providerWeeklyOpening.fridayClosing
              : 'Fr Closed',
            practice.practiceDetails.providerWeeklyOpening.saturdayOpen
              ? 'Sa ' +
                practice.practiceDetails.providerWeeklyOpening.saturdayOpening +
                '-' +
                practice.practiceDetails.providerWeeklyOpening.saturdayClosing
              : 'Sa Closed',
            practice.practiceDetails.providerWeeklyOpening.sundayOpen
              ? 'Su ' +
                practice.practiceDetails.providerWeeklyOpening.sundayOpening +
                '-' +
                practice.practiceDetails.providerWeeklyOpening.sundayClosing
              : 'Su Closed',
          ],
        },
      };

      if (this.hasReviewFeature) {
        Object.assign(this.schema, { review: reviews });
      }

      if (this.hasReviewFeature && practice.practiceDetails.averageStarRating) {
        Object.assign(this.schema, {
          aggregateRating: {
            '@type': 'AggregateRating',
            ratingValue: practice.practiceDetails.averageStarRating,
            bestRating: '5',
            worstRating: '1',
            reviewCount: practice.practiceDetails.comments.length,
          },
        });
      }
    }
  }

  private setPageTitle(summary: ProviderSummary) {
    let practiceName = summary.businessName ? summary.businessName : '';
    let modality =
      summary.modality && summary.modality.name ? summary.modality.name : '';

    let suburb =
      summary.address && summary.address.suburb ? summary.address.suburb : '';

    let practiceTitle = practiceName.endsWith('Practice')
      ? practiceName
      : `${practiceName} Practice`;

    this.titleService.setTitle(
      `${practiceTitle} - ${modality} ${suburb} - Whitecoat`,
    );
  }

  tabOnChange(activeTab: string) {
    this.activeTab = activeTab;
  }

  isDefaultProviderPicture(providerPicture: string): boolean {
    if (!providerPicture) {
      return true;
    }

    return providerPicture.endsWith('whitecoat-default-logo.png');
  }
}
