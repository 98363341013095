import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { finalize, switchMap } from 'rxjs/operators';
import { SettingsService } from '../../services/settings.service';
import { SeoService } from '../../services/seo.service';
import { PractitionerService } from '../../services/practitioner.service';
import { BaseProfileComponent } from '../../components/base-profile/base-profile.component';

import { ProviderSearchResult } from '../../interfaces/provider-search-result';
import { Practitioner } from '../../interfaces/practitioner';

@Component({
  selector: 'app-practitioner',
  templateUrl: './practitioner.component.html',
  styleUrls: ['./practitioner.component.scss'],
})
export class PractitionerComponent
  extends BaseProfileComponent
  implements OnInit
{
  constructor(
    protected override activatedRoute: ActivatedRoute,
    private practitionerService: PractitionerService,
    protected override settingsService: SettingsService,
    protected override titleService: Title,
    protected override seoService: SeoService,
    protected override meta: Meta,
  ) {
    super(seoService, settingsService, titleService, meta, activatedRoute);
  }

  ngOnInit() {
    this.loadingPersionalInfo = true;
    this.loadingNearbyProviders = true;
    this.initializeData();
  }

  initializeData() {
    this.activatedRoute.params.subscribe(
      (params: Params) => {
        this.loadingPersionalInfo = true;
        this.loadingNearbyProviders = true;
        this.modality = params['modality'];
        this.modalityId = params['modalityId'];
        this.state = params['state'];
        this.providerid = params['providerId'];
        this.suburb = params['suburb'];
        this.surname = params['surname'];
        if (this.providerid && this.modalityId) {
          this.practitionerService
            .getPractitionerByProfileId(this.providerid, this.modalityId)
            .pipe(
              switchMap((practitioner) => {
                this.initPractitionerDetails(practitioner);
                this.loadingPersionalInfo = false;

                //only show nearby for provider without subscription
                if (!this.detailAppointment) {
                  return this.practitionerService.getPractitionerListNearby(
                    this.providerid,
                    practitioner.profileDetails.personalSummary.modality.name,
                    this.state,
                    practitioner.profileDetails.personalSummary.address
                      .postcode,
                    practitioner.profileDetails.personalSummary.address.suburb,
                  );
                } else return null;
              }),
            )
            .pipe(
              finalize(() => {
                this.loadingNearbyProviders = false;
                this.setNearbySectionShown();
                console.log('getPractitionerListNearby is completed');
              }),
            )
            .subscribe(
              (resultList: ProviderSearchResult[]) => {
                if (resultList) {
                  this.providerNearBy = resultList.slice(0, 3);
                  this.loadingNearbyProviders = false;
                  this.setNearbySectionShown();
                }
              },
              (error) => console.log(error),
            );
        }

        this.createCanonicalURL();
      },
      (error) => console.error(error),
    );
  }

  initPractitionerDetails(practitioner: Practitioner) {
    this.detailDisplayName = practitioner.name;
    this.profileDetails = practitioner.profileDetails;
    this.SetProfileDetails(this.profileDetails, this.detailDisplayName);

    let providerTitle = this.detailDisplayName;
    let modalityName = this.profileDetails.personalSummary.modality.name;
    let suburb =
      this.profileDetails.personalSummary.address &&
      this.profileDetails.personalSummary.address.suburb
        ? this.profileDetails.personalSummary.address.suburb
        : '';
    this.setPageTitle(providerTitle, modalityName, suburb);
  }
}
