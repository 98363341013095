import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { SuburbAddress } from '../../../interfaces/suburb-address';
import { SearchTextComponent } from '../../search/search-text/search-text.component';
@Component({
  selector: 'app-search-modal',
  templateUrl: './search-modal.component.html',
  styleUrls: ['./search-modal.component.scss'],
})
export class SearchModalComponent implements OnInit {
  @ViewChild(SearchTextComponent) searchTextComponent: SearchTextComponent;

  modality: string;
  textFilter: string;

  latitude: number;
  longitude: number;
  suburb: SuburbAddress;
  @Output() redirectedToAnotherPage = new EventEmitter<void>();

  constructor() {}

  ngOnInit() {}

  onSearchClicked() {
    this.redirectedToAnotherPage.emit();
  }
}
