<div class="row openingHours" *ngIf="checkopeningHours()">
  <div class="title">Opening Hours</div>
  <table class="table table-condensed">
    <tbody>
      <tr [hidden]="!openingHours?.mondayOpen">
        <th>Mon</th>
        <td>{{openingHours?.mondayOpening}}</td>
        <td>-</td>
        <td>{{openingHours?.mondayClosing}}</td>
      </tr>
      <tr [hidden]="!openingHours?.tuesdayOpen">
        <th>Tue</th>
        <td>{{openingHours?.tuesdayOpening}}</td>
        <td>-</td>
        <td>{{openingHours?.tuesdayClosing}}</td>
      </tr>
      <tr [hidden]="!openingHours?.wednesdayOpen">
        <th>Wed</th>
        <td>{{openingHours?.wednesdayOpening}}</td>
        <td>-</td>
        <td>{{openingHours?.wednesdayClosing}}</td>
      </tr>

      <tr [hidden]="!openingHours?.thursdayOpen">
        <th>Thu</th>
        <td>{{openingHours?.thursdayOpening}}</td>
        <td>-</td>
        <td>{{openingHours?.thursdayClosing}}</td>
      </tr>
      <tr [hidden]="!openingHours?.fridayOpen">
        <th>Fri</th>
        <td>{{openingHours?.fridayOpening}}</td>
        <td>-</td>
        <td>{{openingHours?.fridayClosing}}</td>
      </tr>
      <tr [hidden]="!openingHours?.saturdayOpen">
        <th>Sat</th>
        <td>{{openingHours?.saturdayOpening}}</td>
        <td>-</td>
        <td>{{openingHours?.saturdayClosing}}</td>
      </tr>
      <tr [hidden]="!openingHours?.sundayOpen">
        <th>Sun</th>
        <td>{{openingHours?.sundayOpening}}</td>
        <td>-</td>
        <td>{{openingHours?.sundayClosing}}</td>
      </tr>
    </tbody>
  </table>
</div>
