import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ModalityWithKeyword } from '../interfaces/modality-with-keyword';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class ModalityService {
  private modalityApi;
  constructor(private http: HttpClient) {
    this.modalityApi = environment.API_BASE_URL + 'api/search';
  }

  keywordSearch(keyword: string): Observable<ModalityWithKeyword[]> {
    return this.http.get<ModalityWithKeyword[]>(
      `${this.modalityApi}/getModalitiesFromKeyword?keyword=${keyword}`,
    );
  }

  getAllTheOtherModalities(): Observable<string[]> {
    return of(AllOtherModalities);
  }

  getMostPopularModalites(): Observable<string[]> {
    return of(MostPopularModalities);
  }
}
const MostPopularModalities = [
  'Dentist',
  'Physiotherapist',
  'Remedial Massage Therapist',
  'Optometrist',
  'General Practitioner',
  'General Practitioner - After Hours',
  'Chiropractor',
  'Podiatrist',
  'Acupuncturist',
  'Osteopath',
  'Personal Trainer',
  'Dermal Clinician',
];

const AllOtherModalities = [
  'Allergy Specialist and Immunologist',
  'Anaesthetist',
  'Audiologist',
  'Audiometrist',
  'Bariatric (Obesity) Surgeon',
  'Bowen Therapist',
  'Breast Surgeon',
  'Cardiologist (Heart Specialist)',
  'Cardiologist',
  'Cardiothoracic Surgeon',
  'Chinese Herbal Medicine Practitioner',
  'Clinical Geneticist',
  'Clinical Hypnotherapy',
  'Clinical Psychologist',
  'Colorectal Surgeon (Bowel)',
  'Cosmetic Practitioner',
  'Counsellor',
  'COVID-19 Vaccine Clinic',
  'Dental Other',
  'Dermatologist (Skin Specialist)',
  'Dietitian',
  'Ear Nose and Throat (ENT) Surgeon',
  'Emergency Specialist',
  'Endocrine Surgeon (Thyroid Parathyroid Adrenal)',
  'Endocrinologist',
  'Exercise Physiologist',
  'Family Planning',
  'Gastroenterologist - Capsule Endoscopy',
  'Gastroenterologist - Colonoscopy',
  'Gastroenterologist - Endoscopist',
  'Gastroenterologist - ERCP',
  'Gastroenterologist - EUS',
  'Gastroenterologist',
  'General Surgeon - Capsule Endoscopy',
  'General Surgeon - Colonoscopy',
  'General Surgeon - Endoscopist',
  'General Surgeon - ERCP',
  'General Surgeon - EUS',
  'General Surgeon - Gastroscopy',
  'General Surgeon',
  'Geriatrician (Aged Care Specialist)',
  'Gynaecological Oncologist',
  'Haematologist (Blood Disorders Specialist)',
  'Hand Surgeon',
  'Homeopath',
  'Infectious Diseases Specialist',
  'Intensive Care Specialist',
  'Kinesiology',
  'Maternal Fetal Medicine Specialist',
  'Medical Centre',
  'Medical Oncologist (Cancer Specialist)',
  'Mental Health Social Worker',
  'Meditation',
  'Myotherapist',
  'Naturopath',
  'Neonatologist',
  'Nephrologist (Kidney Specialist)',
  'Neurologist (Brain Specialist)',
  'Neurosurgeon (Brain and Spine)',
  'Nuclear Medicine Physician',
  'Nutritionist',
  'Obstetrician and Gynaecologist - Infertility (IVF)',
  'Obstetrician and Gynaecologist - Urinary incontinence',
  'Obstetrician and Gynaecologist',
  'Obstetrics and Gynaecological Ultrasound Specialist',
  'Occupational Therapist',
  'Ophthalmologist (Eye Specialist)',
  'Oral and Maxillofacial Surgeon',
  'Oral Surgeon',
  'Orthodontist',
  'Orthopaedic Surgeon - Ankle',
  'Orthopaedic Surgeon - Foot',
  'Orthopaedic Surgeon - Hand',
  'Orthopaedic Surgeon - Hip',
  'Orthopaedic Surgeon - Knee',
  'Orthopaedic Surgeon - Paediatric',
  'Orthopaedic Surgeon - Shoulder and Elbow',
  'Orthopaedic Surgeon - Spine',
  'Orthopaedic Surgeon - Trauma-Fracture',
  'Orthopaedic Surgeon',
  'Other',
  'Paediatric Allergist and Immunologist',
  'Paediatric Cardiologist',
  'Paediatric Emergency Physician',
  'Paediatric Endocrinologist',
  'Paediatric Gastroenterologist',
  'Paediatric Geneticist',
  'Paediatric Haematologist',
  'Paediatric Infectious Diseases Physician',
  'Paediatric Intensive Care Physician',
  'Paediatric Medical Oncologist',
  'Paediatric Nephrologist',
  'Paediatric Neurologist',
  'Paediatric Rehabilitation Physician',
  'Paediatric Respiratory and Sleep Medicine Physician',
  'Paediatric Rheumatologist',
  'Paediatric Surgeon',
  'Paediatrician',
  'Pain Medicine Specialist',
  'Palliative Medicine Specialist',
  'Pathologist',
  'Pharmacy',
  'Physician (General)',
  'Pilates',
  'Plastic Surgeon (Reconstructive and Cosmetic)',
  'Podiatric Surgeon',
  'Provisional Psychologist',
  'Psychiatrist',
  'Psychologist',
  'Psychotherapist',
  'Radiation Oncologist',
  'Radiologist (Nuclear Medicine)',
  'Radiologist',
  'Rehabilitation Specialist',
  'Respiratory and Sleep Medicine Physician',
  'Rheumatologist',
  'Shiatsu Therapist',
  'Speech Pathologist',
  'Social Worker',
  'Swim School',
  'Upper GI Surgeon (Abdominal)',
  'Urologist - Neuromodulation',
  'Urologist - Overactive bladder (OAB)',
  'Urologist',
  'Vascular Surgeon',
  'Western Herbalist',
];
