// shared.service.ts
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  private readonly searchModalType = 'search';
  private readonly navModalType = 'nav';

  private initialModality: string;
  private initialSearchLoation: string;

  //so far only used for initialize searching box from modal
  private messageSubject = new Subject<string>();
  message$: Observable<string> = this.messageSubject.asObservable();

  sendSubjectMsg(msg: string) {
    this.messageSubject.next(msg);
  }
  setSharedInitialModality(value: string): void {
    this.initialModality = value ? value : '';
    this.sendSubjectMsg('update searching box');
    console.log('modality name is set to: ' + this.initialModality);
  }

  getSharedInitialModality(): string {
    return this.initialModality;
  }

  setSharedInitialSearchLoation(value: string): void {
    this.initialSearchLoation = value ? value : '';
    this.sendSubjectMsg('update searching box');
    console.log('searchlocation name is set to: ' + this.initialSearchLoation);
  }

  getSharedInitialSearchLoation(): string {
    return this.initialSearchLoation;
  }

  getSearchModalType(): string {
    return this.searchModalType;
  }

  getNavModalType(): string {
    return this.navModalType;
  }
}
