<div class="input-bx">
  <input
    type="text"
    [formControl]="searchFormControl"
    (focus)="showTextSuggestionBox()"
    (blur)="hideTextSuggestionBox()"
    [(ngModel)]="textFilter"
    autocomplete="off"
    class="form-control form-control-text-search"
    [class.short]="inline"
    placeholder="Suburb or postcode"
    title="enter name, suburb or postcode"
    [class.form-control-text-search--content]="textFilter"
  />
  <div class="clear" (click)="clear()" *ngIf="textFilter"></div>

  <div
    class="text-suggestion"
    [class.short]="inline"
    [hidden]="!isTextSuggestionBoxVisible"
  >
    <p
      tabindex="0"
      (focus)="showTextSuggestionBox()"
      (blur)="hideTextSuggestionBox()"
      *ngFor="let s of searchResults"
      (click)="setSelectedLocation(s)"
      (keyup.enter)="setSelectedLocation(s)"
    >
      {{ s.suburb }} {{ s.state }} {{ s.postcode }}
    </p>
  </div>
</div>
