import { NgModule } from '@angular/core';
import {
  BrowserModule,
  HAMMER_GESTURE_CONFIG,
} from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { GoogleMapsModule } from '@angular/google-maps';

import { HammerConfig } from './configs/hammer-config';

// Main
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// Components
import { FooterComponent } from './components/footer/footer.component';
import { ToastNotificationComponent } from './components/toast-notification/toast-notification.component';
import { LoaderCardComponent } from './components/base-profile/loader-card/loader-card.component';
import { ProviderCardComponent } from './components/provider-card/provider-card.component';
import { LoaderMapComponent } from './components/loader-map/loader-map.component';
import { LoaderComponent } from './components/loader/loader.component';
// - base-profile
import { BaseProfileComponent } from './components/base-profile/base-profile.component';
import { InfoHeaderComponent } from './components/base-profile/info-header/info-header.component';
import { InfoPanelComponent } from './components/base-profile/info-panel/info-panel.component';
import { ListContentComponent } from './components/base-profile/list-content/list-content.component';
import { OpeningHoursComponent } from './components/base-profile/opening-hours/opening-hours.component';
import { RegisteredTickComponent } from './components/base-profile/registered-tick/registered-tick.component';
import { SimpleProviderCardComponent } from './components/base-profile/simple-provider-card/simple-provider-card.component';
import { SubProviderListComponent } from './components/base-profile/sub-provider-list/sub-provider-list.component';
import { ProviderNearbyComponent } from './components/base-profile/provider-nearby/provider-nearby.component';
import { MapLocationProfileComponent } from './components/base-profile/map-location-profile/map-location-profile.component';
// - header
import { HeaderComponent } from './components/header/header.component';
import { HeaderModalComponent } from './components/header/header-modal/header-modal.component';
import { NavModalComponent } from './components/header/nav-modal/nav-modal.component';
import { SearchModalComponent } from './components/header/search-modal/search-modal.component';
// - fap
import { BookingFapComponent } from './components/fap/booking-fap/booking-fap.component';
import { BookingIframeFapComponent } from './components/fap/booking-iframe-fap/booking-iframe-fap.component';
import { BookingRedirectFapComponent } from './components/fap/booking-redirect-fap/booking-redirect-fap.component';
import { PracticeContactFapComponent } from './components/fap/practice-contact-fap/practice-contact-fap.component';
import { PracticeLinksFapComponent } from './components/fap/practice-links-fap/practice-links-fap.component';
import { PracticeLocationFapComponent } from './components/fap/practice-location-fap/practice-location-fap.component';
import { PracticeOpeningHoursFapComponent } from './components/fap/practice-openinghours-fap/practice-openinghours-fap.component';
import { PracticePractitionersFapComponent } from './components/fap/practice-practitioners-fap/practice-practitioners-fap.component';
import { PracticeReviewsFapComponent } from './components/fap/practice-reviews-fap/practice-reviews-fap.component';
import { StarRatingFapComponent } from './components/fap/star-rating-fap/star-rating-fap.component';
// - list
import { ListNotFoundComponent } from './components/list/list-not-found/list-not-found.component';
import { ListPracticeComponent } from './components/list/list-practice/list-practice.component';
import { ListPractitionerComponent } from './components/list/list-practitioner/list-practitioner.component';
import { ListToolbarComponent } from './components/list/list-toolbar/list-toolbar.component';
import { MapLocationComponent } from './components/list/map-location/map-location.component';
// - practice
import { PracticeAboutComponent } from './components/practice/practice-about/practice-about.component';
import { PracticeHealthFundsComponent } from './components/practice/practice-health-funds/practice-health-funds.component';
import { PracticeServicesComponent } from './components/practice/practice-services/practice-services.component';
// - search
import { SearchComponent } from './components/search/search.component';
import { SearchModalityComponent } from './components/search/search-modality/search-modality.component';
import { SearchTextComponent } from './components/search/search-text/search-text.component';

// Pages
import { HomeComponent } from './pages/home/home.component';
// Pages - directory
import { ListComponent } from './pages/list/list.component';
import { PracticeComponent } from './pages/practice/practice.component';
import { PractitionerComponent } from './pages/practitioner/practitioner.component';
import { PracticeFapComponent } from './pages/practice-fap/practice-fap.component';
import { PracticeCbhsCorpFapComponent } from './pages/practice-cbhs-corp-fap/practice-cbhs-corp-fap.component';
// Pages - providers
import { PasswordResetRequestComponent } from './pages/password-reset-request/password-reset-request.component';
import { PasswordResetEnterComponent } from './pages/password-reset-enter/password-reset-enter.component';
// Pages - error
import { ErrorComponent } from './pages/error/error.component';

// Pipes
import { DatePipe } from '@angular/common';
import { HypheniseUrlPipe } from './pipes/hyphenise-url.pipe';
import { TextFilterPipe } from './pipes/text-filter.pipe';
import { EscapeUrlPipe } from './pipes/escape-url.pipe';
import { UrlSafePipe } from './pipes/url-safe.pipe';
import { GroupByPipe } from './pipes/group-by.pipe';
import { Time12HourPipe } from './pipes/time-12-hour.pipe';

// Services
import { GlobalStaticService } from './services/global.static-service';
import { LocationService } from './services/location.service';
import { ModalityService } from './services/modality.service';
import { SettingsService } from './services/settings.service';
import { SharedService } from './services/shared.service';
import { PracticeService } from './services/practice.service';
import { PractitionerService } from './services/practitioner.service';
import { GoogleTagManagerService } from './services/google-tag-manager.service';
import { SeoService } from './services/seo.service';
import { SearchBaseService } from './services/search-base.service';
import { BookingService } from './services/booking.service';
import { WindowService } from './services/window.service';

@NgModule({
  declarations: [
    AppComponent,
    // components
    FooterComponent,
    ToastNotificationComponent,
    LoaderCardComponent,
    ProviderCardComponent,
    LoaderMapComponent,
    LoaderComponent,
    // - base-profile
    BaseProfileComponent,
    InfoHeaderComponent,
    InfoPanelComponent,
    ListContentComponent,
    OpeningHoursComponent,
    RegisteredTickComponent,
    SimpleProviderCardComponent,
    SubProviderListComponent,
    ProviderNearbyComponent,
    MapLocationProfileComponent,
    // - header
    HeaderComponent,
    HeaderModalComponent,
    NavModalComponent,
    SearchModalComponent,
    // - fap
    BookingFapComponent,
    BookingIframeFapComponent,
    BookingRedirectFapComponent,
    PracticeContactFapComponent,
    PracticeLinksFapComponent,
    PracticeLocationFapComponent,
    PracticeOpeningHoursFapComponent,
    PracticePractitionersFapComponent,
    PracticeReviewsFapComponent,
    StarRatingFapComponent,
    // - list
    ListNotFoundComponent,
    ListPracticeComponent,
    ListPractitionerComponent,
    ListToolbarComponent,
    MapLocationComponent,
    // - practice
    PracticeAboutComponent,
    PracticeHealthFundsComponent,
    PracticeServicesComponent,
    // - search
    SearchComponent,
    SearchModalityComponent,
    SearchTextComponent,
    // pages
    HomeComponent,
    // pages - directory
    ListComponent,
    PracticeComponent,
    PractitionerComponent,
    PracticeFapComponent,
    PracticeCbhsCorpFapComponent,
    // pages - providers
    PasswordResetRequestComponent,
    PasswordResetEnterComponent,
    // pages - error
    ErrorComponent,
    // pipies
    HypheniseUrlPipe,
    TextFilterPipe,
    EscapeUrlPipe,
    UrlSafePipe,
    GroupByPipe,
    Time12HourPipe,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    GoogleMapsModule,
    AppRoutingModule,
  ],
  providers: [
    // pipies
    DatePipe,
    HypheniseUrlPipe,
    TextFilterPipe,
    EscapeUrlPipe,
    UrlSafePipe,
    GroupByPipe,
    Time12HourPipe,
    // services
    GlobalStaticService,
    LocationService,
    ModalityService,
    SettingsService,
    SharedService,
    PracticeService,
    PractitionerService,
    GoogleTagManagerService,
    SeoService,
    SearchBaseService,
    BookingService,
    WindowService,
    { provide: HAMMER_GESTURE_CONFIG, useClass: HammerConfig },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
