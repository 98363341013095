import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { GlobalStaticService } from 'src/app/services/global.static-service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  GlobalStaticSrv = GlobalStaticService;
  title = `Find Doctors & Healthcare Specialists – Book Appointment Online`;
  description = `We are one of Australia's leading healthcare directories, giving you the ability to find trusted healthcare professionals and book your appointments online`;

  constructor(
    private titleService: Title,
    private meta: Meta,
  ) {}

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.meta.updateTag({
      property: 'og:title',
      content: this.title,
    });
    this.meta.updateTag({
      name: 'description',
      content: this.description,
    });
    this.meta.updateTag({
      property: 'og:description',
      content: this.description,
    });
  }
}
