<div class="input-bx">
  <input
    type="text"
    [formControl]="searchFormControl"
    (focus)="showModalitySuggestionBox()"
    (blur)="hideModalitySuggestionBox()"
    [(ngModel)]="selectedModality"
    autocomplete="off"
    class="form-control form-control-modality-search"
    [class.short]="inline"
    placeholder="I'm looking for a ..."
    title="select a modality"
    [class.form-control-modality-search--content]="selectedModality"
  />

  <div class="clear" (click)="clear()" *ngIf="selectedModality"></div>

  <div
    class="modality-suggestion"
    [class.short]="inline"
    [hidden]="!isModalitySuggestionBoxVisible"
  >
    <ng-template [ngIf]="!empty">
      <h5>Matching results</h5>

      <p
        tabindex="0"
        (focus)="showModalitySuggestionBox()"
        (blur)="hideModalitySuggestionBox()"
        *ngFor="let m of searchResults"
        (click)="setSelectedModality(m)"
        (keyup.enter)="setSelectedModality(m)"
      >
        {{ m }}
      </p>
    </ng-template>

    <ng-template [ngIf]="empty">
      <h5>Most popular searches</h5>

      <p
        tabindex="0"
        (focus)="showModalitySuggestionBox()"
        (blur)="hideModalitySuggestionBox()"
        *ngFor="let m of mostPopularModalities | textFilter: selectedModality"
        (click)="setSelectedModality(m)"
        (keyup.enter)="setSelectedModality(m)"
      >
        {{ m }}
      </p>
    </ng-template>
    <h5>All Specialists</h5>

    <p
      tabindex="0"
      (focus)="showModalitySuggestionBox()"
      (blur)="hideModalitySuggestionBox()"
      *ngFor="let m of allOtherModalities | textFilter: selectedModality"
      (click)="setSelectedModality(m)"
      (keyup.enter)="setSelectedModality(m)"
    >
      {{ m }}
    </p>
  </div>
</div>
