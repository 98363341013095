import { Component, OnInit } from '@angular/core';
import { SettingsService } from '../../services/settings.service';
import { GlobalStaticService } from 'src/app/services/global.static-service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  GlobalStaticSrv = GlobalStaticService;

  // component for mobile view dropdown icon
  downArrowImage = 'url(/assets/images/chevron-down.svg)';
  upArrowImage = 'url(/assets/images/chevron-up.svg)';

  // sets copyright year to current year display
  year: number = new Date().getFullYear();

  isCompanyExpanded = false;
  isPatientExpanded = false;
  isProviderExpanded = false;

  constructor() {}

  // component for mobile view dropdown icon
  ngOnInit() {}

  // component for mobile view dropdown icon
  toggleImage(ddpName: string) {
    switch (ddpName) {
      case 'company':
        this.isCompanyExpanded = !this.isCompanyExpanded;
        break;
      case 'patient':
        this.isPatientExpanded = !this.isPatientExpanded;
        break;
      case 'provider':
        this.isProviderExpanded = !this.isProviderExpanded;
        break;
      default:
        break;
    }
  }

  returnImage(ddpName: string) {
    let isExpanded = false;
    switch (ddpName) {
      case 'company':
        isExpanded = this.isCompanyExpanded;
        break;
      case 'patient':
        isExpanded = this.isPatientExpanded;
        break;
      case 'provider':
        isExpanded = this.isProviderExpanded;
        break;
      default:
        break;
    }
    if (isExpanded) {
      return this.upArrowImage;
    } else {
      return this.downArrowImage;
    }
  }
}
