<section aria-label="Whitecoat website footer">
  <div class="footer-background">
    <div class="footer-container">
      <footer class="footer">
        <div class="container">
          <div class="row">
            <div class="col-md-2 img">
              <div class="logo">
                <a href="/">
                  <img
                    class="img"
                    src="./assets/images/whitecoat-logo.svg"
                    alt="Whitecoat Logo"
                    width="134"
                    height="48"
                /></a>
              </div>
            </div>

            <div class="col-md-2">
              <button
                type="button"
                class="dropdown"
                data-bs-toggle="collapse"
                data-bs-target="#listMain1"
                [style.background-image]="returnImage('company')"
                (click)="toggleImage('company')"
              >
                Company
              </button>

              <ul id="listMain1" class="list-group collapse">
                <li class="list-group-item">
                  <a href="{{ GlobalStaticSrv.whitecoatDomainPagesUrl }}/about/"
                    >About</a
                  >
                </li>
                <li class="list-group-item">
                  <a
                    href="{{
                      GlobalStaticSrv.whitecoatDomainPagesUrl
                    }}/contact/"
                    >Contact us</a
                  >
                </li>
                <li class="list-group-item">
                  <a href="{{ GlobalStaticSrv.whitecoatDomainPagesUrl }}/faqs/"
                    >FAQs</a
                  >
                </li>
                <li class="list-group-item">
                  <a
                    href="{{
                      GlobalStaticSrv.whitecoatDomainPagesUrl
                    }}/website-terms-and-conditions/"
                    >Terms of use</a
                  >
                </li>
                <li class="list-group-item">
                  <a
                    href="{{
                      GlobalStaticSrv.whitecoatDomainPagesUrl
                    }}/privacy-policy/"
                    >Privacy policy</a
                  >
                </li>
              </ul>
            </div>

            <div class="col-md-2">
              <button
                type="button"
                class="dropdown"
                data-bs-toggle="collapse"
                data-bs-target="#listMain2"
                [style.background-image]="returnImage('patient')"
                (click)="toggleImage('patient')"
              >
                For patients
              </button>

              <ul id="listMain2" class="list-group collapse">
                <li class="list-group-item">
                  <a href="/">Find a healthcare provider</a>
                </li>
              </ul>
            </div>

            <div class="col-md-2">
              <button
                type="button"
                class="dropdown"
                data-bs-toggle="collapse"
                data-bs-target="#listMain3"
                [style.background-image]="returnImage('provider')"
                (click)="toggleImage('provider')"
              >
                For providers
              </button>

              <ul id="listMain3" class="list-group collapse">
                <li class="list-group-item">
                  <a
                    href="{{
                      GlobalStaticSrv.whitecoatDomainPagesUrl
                    }}/providers/"
                    >Overview</a
                  >
                </li>
                <li class="list-group-item">
                  <a
                    href="{{
                      GlobalStaticSrv.whitecoatDomainPagesUrl
                    }}/profile/"
                    >Claim your profile</a
                  >
                </li>
                <li class="list-group-item">
                  <a
                    href="{{
                      GlobalStaticSrv.whitecoatDomainPagesUrl
                    }}/providers/practice-listings/"
                    >List your practice</a
                  >
                </li>
              </ul>
            </div>
          </div>

          <div class="copy">
            Copyright &copy; {{ year }} Whitecoat. All rights reserved.
          </div>
        </div>
      </footer>
    </div>
  </div>
</section>
