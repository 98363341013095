import { Component, OnInit, Input, ViewChild } from '@angular/core';

import { GoogleMap } from '@angular/google-maps';
import { Location } from '../../../interfaces/location';
import { Address } from '../../../interfaces/address';

@Component({
  selector: 'app-practice-location-fap',
  templateUrl: './practice-location-fap.component.html',
  styleUrls: ['./practice-location-fap.component.scss'],
})
export class PracticeLocationFapComponent {
  location: Location;

  @Input('location')
  set setLocation(location: Location) {
    if (!location) {
      return;
    }
    location.position = {};
    location.position.lat = location.latitude;
    location.position.lng = location.longitude;
    this.location = location;
    this.latlngBounds = new google.maps.LatLngBounds();
    this.latlngBounds.extend(
      new google.maps.LatLng(
        this.location.position.lat,
        this.location.position.lng,
      ),
    );
    this.map.fitBounds(this.latlngBounds);
  }

  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;

  selectedMarker: any;

  latlngBounds: any = false;

  @Input() address: Address;
  @Input() iconName: string;
}
