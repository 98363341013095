<a *ngIf="!useAltView" (click)="openBookingUrl($event)" class="btn btn-book-iframe pull-right">
  <span class="glyphicon glyphicon-ok" aria-hidden="true"></span>&nbsp;&nbsp;Book Now
</a>

<a *ngIf="useAltView" (click)="openBookingUrl($event)" class="btn btn-default book-iframe">
  Book
</a>

<a target="_blank" *ngIf="acceptTelehealth && !useAltView" href="{{acceptTelehealthUrl}}" class="btn-teleHealth btn btn-primary btn-book pull-right">
  <span class="glyphicon glyphicon-ok" aria-hidden="true"></span>&nbsp;&nbsp;Telehealth appointment
</a>

<!-- Modal -->
<div id="iframe-booking" (click)="$event.stopPropagation();" class="modal fade in not-clickable" [style.display]="isOpen ? 'block' : 'none'">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" id="closeBooking" (click)="close($event)" data-dismiss="modal">&times;</button>
        <h4 class="modal-title text-center">Booking for {{ providerName }}</h4>
      </div>
      <div class="modal-body">
        <iframe [style.height.px]="onlineBookingDetails.widgetHeight" [attr.src]="bookingUrl | urlSafe"></iframe>
      </div>
      <div class="modal-footer">
        <p class="text-danger text-center">Whitecoat online bookings are for standard appointments only.</p>
        <p class="text-danger text-center">For emergencies, please dial 000.</p>
      </div>
    </div>

  </div>
</div>
