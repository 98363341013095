import { Component, Input } from '@angular/core';

import { ContactDetails } from '../../../interfaces/contact-details';
import { WebsiteAndSocialMediaLinks } from '../../../interfaces/website-and-social-media-links';
import { ProviderSummary } from '../../../interfaces/provider-summary';
import { OnlineBookingDetails } from '../../../interfaces/online-booking-details';
import { SearchCriteria } from '../../../interfaces/search-criteria';
import { SettingsService } from '../../../services/settings.service';

@Component({
  selector: 'app-practice-contact-fap',
  templateUrl: './practice-contact-fap.component.html',
  styleUrls: ['./practice-contact-fap.component.scss'],
})
export class PracticeContactFapComponent {
  hasReviewFeature: boolean = true;
  constructor(private settings: SettingsService) {
    this.hasReviewFeature = this.settings.hasReviewFeature();
  }

  @Input()
  searchCriteria: SearchCriteria;

  @Input()
  gaTagCSSSuffix: string;

  @Input()
  contactDetails: ContactDetails;

  @Input()
  websiteAndSocialMediaLinks: WebsiteAndSocialMediaLinks;

  @Input()
  providerSummary: ProviderSummary;

  @Input('onlineBookingDetails')
  set model(value: OnlineBookingDetails) {
    this.onlineBookingDetails = value;
  }

  @Input()
  index: number;

  onlineBookingDetails: OnlineBookingDetails;
}
