import { Component, Input } from '@angular/core';

import { ModalityService } from '../../../interfaces/modality-service';

@Component({
  selector: 'app-practice-services',
  templateUrl: './practice-services.component.html',
  styleUrls: ['./practice-services.component.scss'],
})
export class PracticeServicesComponent {
  constructor() {}

  @Input() services: ModalityService[];
}
