import {
  Component,
  Input,
  ViewChild,
  OnChanges,
  PLATFORM_ID,
  Inject,
  AfterViewInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';

import { Location } from '../../../interfaces/location';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-map-location',
  templateUrl: './map-location.component.html',
  styleUrls: ['./map-location.component.scss'],
})
export class MapLocationComponent implements OnChanges, AfterViewInit {
  locations: Location[];
  isBrowser: boolean;

  @Input('locations')
  set setLocations(locations: Location[]) {
    for (let location of locations) {
      location.position = {};
      location.position.lat = location.latitude;
      location.position.lng = location.longitude;
    }
    this.locations = locations;
  }

  @Input()
  userLocation: Location;

  @Input()
  type: string;

  @Input()
  view: string;

  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;

  @ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow;

  @Output()
  viewUpdate: EventEmitter<any> = new EventEmitter();

  latlngBounds: any = false;
  userLatitude: any;
  userLongitude: any;

  selectedMarker: any;

  constructor(@Inject(PLATFORM_ID) platformId: Object) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngAfterViewInit(): void {
    this.setUserLocation();
    this.refresh();
  }

  ngOnChanges(): void {
    this.refresh();
  }

  setUserLocation() {
    if (this.userLocation) {
      // remove old location
      const userLocationIndex = this.locations.findIndex(
        (l) => l.label === 'You are here!',
      );
      if (userLocationIndex > -1) {
        this.locations.splice(userLocationIndex, 1);
      }

      this.locations.push({
        label: 'You are here!',
        position: {
          lat: this.userLocation.latitude,
          lng: this.userLocation.longitude,
        },
        iconUrl: '/assets/images/my-location.svg',
      });
    }
  }

  refresh() {
    if (!this.map) {
      return;
    }

    if (!this.locations || this.locations.length === 0) {
      return;
    }

    this.latlngBounds = new google.maps.LatLngBounds();
    this.locations.forEach((location) => {
      this.latlngBounds.extend(
        new google.maps.LatLng(location.latitude, location.longitude),
      );
    });
    this.map.fitBounds(this.latlngBounds);
  }

  openInfo(element, marker) {
    this.selectedMarker = marker;
    this.infoWindow.open(element);
  }

  buttonClicked() {
    this.viewUpdate.emit();
  }
}
