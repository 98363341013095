<div class="list-content" *ngIf="openingTime">
  <div *ngFor="let item of openingTime" class="item">
    <div class="hour-part weekday">
      <span>{{ item.weekday }}</span>
    </div>
    <div class="hour-part">
      <span>{{
        item.isClosed ? "Closed" : (item.openingTime | time12Hour)
      }}</span>
    </div>
    <div *ngIf="!item.isClosed"><span>-</span></div>
    <div *ngIf="!item.isClosed" class="hour-part closing-time">
      <span>{{ item.closingTime | time12Hour }}</span>
    </div>
  </div>
</div>
