import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  ViewChild,
} from '@angular/core';
import { AssociatedAttribute } from '../../../interfaces/associated-attribute';
import { OpeningHours } from '../../../interfaces/opening-hours';
import { PractitionerBriefSummary } from '../../../interfaces/practitioner-brief-summary';
import { ProviderWeeklyOpening } from '../../../interfaces/provider-weekly-opening';

@Component({
  selector: 'app-info-panel',
  templateUrl: './info-panel.component.html',
  styleUrls: ['./info-panel.component.scss'],
})
export class InfoPanelComponent implements OnChanges {
  readonly hiddenClass = 'hide-tab';
  readonly contentIdAttri = 'data-bs-content-id';

  readonly providerTabId = 'providersTab';
  readonly aboutTabId = 'aboutTab';
  readonly serviceTabId = 'serviceTab';
  readonly facilityTabId = 'faciltiyTab';
  readonly openHourTabId = 'openhourTab';

  readonly providerContentId = 'providers';
  readonly aboutContentId = 'about';
  readonly serviceContentId = 'service';
  readonly facilityContentId = 'faciltiy';
  readonly openHourContentId = 'openhour';

  showAbout: boolean;
  showOpenHours: boolean;
  showServices: boolean;
  showProviders: boolean;
  showFacilities: boolean;
  showPanel: boolean;
  foundActivity: boolean;
  activeTabContentId: string;
  serviceList: string[];
  facilityList: string[];
  openinghourList: OpeningHours[];
  bio: string;

  @Input() about: string;
  @Input() practitioners: PractitionerBriefSummary[];
  @Input() openHours: ProviderWeeklyOpening;
  @Input() services: AssociatedAttribute[];
  @Input() facilities: AssociatedAttribute[];

  @ViewChild('tabListHeader') tabHeadList: ElementRef;
  @ViewChild('tabListContent') tabContentList: ElementRef;

  constructor() {
    this.showAbout = false;
    this.showOpenHours = false;
    this.showServices = false;
    this.showProviders = false;
    this.showFacilities = false;
    this.showPanel = false;
  }

  ngOnChanges() {
    this.foundActivity = false;
    this.setFlags();
    this.setActiveTab();
  }

  setFlags() {
    this.initilaizeBio(this.about);
    this.initilaizeOpenHoursList(this.openHours);
    this.initilaizeServiceList(this.services);
    this.initilaizePractitionerList(this.practitioners);
    this.initilaizeFacilityList(this.facilities);
    this.setPanelVisibility();
  }

  setActiveTab() {
    try {
      this.setActiveTabHeader();
      this.setActiveTabContent();
    } catch (error) {
      // Handle the error
      this.foundActivity = false;
      console.log('An error occurred:', error.message);
    }
  }

  isShowPanel(): Boolean {
    return this.showPanel;
  }

  setPanelVisibility() {
    this.showPanel =
      this.showAbout ||
      this.showProviders ||
      this.showOpenHours ||
      this.showServices ||
      this.showFacilities;
  }

  initilaizeBio(about: string) {
    if (about && about.trim().length > 0) {
      this.showAbout = true;
      this.bio = about;
    } else {
      this.showAbout = false;
    }
  }

  initilaizePractitionerList(practitioners: PractitionerBriefSummary[]) {
    if (practitioners && practitioners.length > 0) {
      this.showProviders = true;
    } else {
      this.showProviders = false;
    }
  }
  initilaizeServiceList(contentList: AssociatedAttribute[]) {
    if (contentList && contentList.length > 0) {
      this.showServices = true;
      this.serviceList = contentList.map((x) => x.name);
    } else {
      this.showServices = false;
    }
  }

  initilaizeFacilityList(contentList: AssociatedAttribute[]) {
    if (contentList && contentList.length > 0) {
      this.showFacilities = true;
      this.facilityList = contentList.map((x) => x.name);
    } else {
      this.showFacilities = false;
    }
  }

  initilaizeOpenHoursList(openHours: ProviderWeeklyOpening) {
    if (openHours) {
      this.showOpenHours = true;
      this.openinghourList = [];
      this.openinghourList.push(
        this.getOpenHoursOfTheDay(
          'Mon',
          openHours.mondayOpening,
          openHours.mondayClosing,
          !openHours.mondayOpen,
        ),
      );
      this.openinghourList.push(
        this.getOpenHoursOfTheDay(
          'Tue',
          openHours.tuesdayOpening,
          openHours.tuesdayClosing,
          !openHours.tuesdayOpen,
        ),
      );
      this.openinghourList.push(
        this.getOpenHoursOfTheDay(
          'Wed',
          openHours.wednesdayOpening,
          openHours.wednesdayClosing,
          !openHours.wednesdayOpen,
        ),
      );
      this.openinghourList.push(
        this.getOpenHoursOfTheDay(
          'Thur',
          openHours.thursdayOpening,
          openHours.thursdayClosing,
          !openHours.thursdayOpen,
        ),
      );
      this.openinghourList.push(
        this.getOpenHoursOfTheDay(
          'Fri',
          openHours.fridayOpening,
          openHours.fridayClosing,
          !openHours.fridayOpen,
        ),
      );
      this.openinghourList.push(
        this.getOpenHoursOfTheDay(
          'Sat',
          openHours.saturdayOpening,
          openHours.saturdayClosing,
          !openHours.saturdayOpen,
        ),
      );
      this.openinghourList.push(
        this.getOpenHoursOfTheDay(
          'Sun',
          openHours.sundayOpening,
          openHours.sundayClosing,
          !openHours.sundayOpen,
        ),
      );

      if (
        this.openinghourList.length === 0 ||
        this.openinghourList.every((x) => x.isClosed === true)
      ) {
        this.showOpenHours = false;
      }
    } else {
      this.showOpenHours = false;
    }
  }

  getOpenHoursOfTheDay(
    weekDay: string,
    openTime: string,
    closeTime: string,
    isClosed: boolean,
  ): OpeningHours {
    return {
      weekday: weekDay,
      isClosed: isClosed,
      openingTime: openTime,
      closingTime: closeTime,
    };
  }

  checkHeaderWithFlag(id: string): boolean {
    switch (id) {
      case this.providerTabId:
        return this.showProviders;
      case this.aboutTabId:
        return this.showAbout;
      case this.serviceTabId:
        return this.showServices;
      case this.facilityTabId:
        return this.showFacilities;
      case this.openHourTabId:
        return this.showOpenHours;
      default:
        return false;
    }
  }

  setActiveTabHeader() {
    let liList = null;
    if (this.tabHeadList && this.tabHeadList.nativeElement) {
      liList = this.tabHeadList.nativeElement.childNodes;
    }
    if (liList) {
      for (let i = 0; i < liList.length; i++) {
        const item = liList[i];
        if (item) {
          let id = item.id;

          const showHeader = this.checkHeaderWithFlag(id);

          if (!showHeader) {
            //hidden tab header should not have active
            item.classList.remove('active');
            item.childNodes[0].classList.remove('active');

            //add hidden class
            if (!item.classList.contains(this.hiddenClass)) {
              item.classList.add(this.hiddenClass);
            }
          } else {
            //get related content id
            const contentId = item.getAttribute(this.contentIdAttri);
            //show the tab header
            item.classList.remove(this.hiddenClass);

            if (contentId !== this.activeTabContentId && this.foundActivity) {
              //if current tab is not the activity tab, then it should not be 'active'
              item.classList.remove('active');
              item.childNodes[0].classList.remove('active');
            }

            if (!this.foundActivity) {
              //if the only activity tabheader is not found then set current tab header as the active header
              //and then set the flag to stop adding multiple active headers
              item.classList.add('active');
              item.childNodes[0].classList.add('active');

              //record the related content id to show
              this.activeTabContentId = contentId;
              this.foundActivity = true;
              continue;
            }
          }
        }
      }
    }
  }

  setActiveTabContent() {
    let contents = null;
    if (this.tabContentList && this.tabContentList.nativeElement) {
      contents = this.tabContentList.nativeElement.childNodes;
    }
    if (this.activeTabContentId) {
      if (contents) {
        for (let i = 0; i < contents.length; i++) {
          const content = contents[i];
          if (content) {
            content.classList.remove('active', 'in', 'show');
          }
        }

        //only set when initialization
        let contentToActive = document.getElementById(this.activeTabContentId);
        if (contentToActive) {
          contentToActive.classList.add('in', 'active', 'show');
          this.activeTabContentId = null;
        }
      }
    }
  }
}
