<div class="row page-password-reset-request">
    <ng-container *ngIf="!submitted">
        <div class="col-lg-6 page-password-reset-request__form">
            <div class="page-password-reset-request__form-wrapper">

                <form class="page-password-reset-request__reset-form" [formGroup]="resetForm">
                    <div class="page-password-reset-request__instruction">
                        Password must be minimum 8 characters with at least one uppercase letter, one lowercase letter, one numeric and one special character @$!%*?&
                    </div>
                    <div
                    class="page-password-reset-request__reset-form-field"
                    [class.page-password-reset-request__reset-form-field--focus-decoration]="resetForm.controls['password'].invalid"
                    [class.page-password-reset-request__reset-form-field--valid-decoration]="resetForm.controls['password'].valid"

                    role="group"
                    aria-labelledby="password"
                    tabindex="0"
                    >
                        <h2>Enter new password</h2>
                        <input type="password" formControlName="password" placeholder="Password" aria-required="true">
                        <div
                        *ngIf="
                            resetForm.controls['password'].invalid &&
                            (resetForm.controls['password'].dirty ||
                            resetForm.controls['password'].touched)
                        "
                        class="page-password-reset-request__reset-form-error"
                        aria-live="polite"
                        >
                            <div
                                *ngIf="
                                resetForm.controls['password'].errors['required']
                                "
                            >
                                Please fill out this field
                            </div>
                            <div
                                *ngIf="
                                !resetForm.controls['password'].errors['required']
                                "
                            >
                                Invalid Password
                            </div>
                        </div>
                    </div>
                    <div
                    class="page-password-reset-request__reset-form-field page-password-reset-request__reset-form-field--line-decoration-long"
                    [class.page-password-reset-request__reset-form-field--focus-decoration]="resetForm.controls['confirmPassword'].invalid"
                    [class.page-password-reset-request__reset-form-field--valid-decoration]="resetForm.controls['confirmPassword'].valid"

                    role="group"
                    aria-labelledby="confirmPassword"
                    tabindex="0"
                    >
                        <h2>Confirm password</h2>
                        <input type="password" formControlName="confirmPassword" placeholder="Confirm password" aria-required="true">
                        <div
                        *ngIf="
                            resetForm.controls['confirmPassword'].invalid &&
                            (resetForm.controls['confirmPassword'].dirty ||
                            resetForm.controls['confirmPassword'].touched)
                        "
                        class="page-password-reset-request__reset-form-error"
                        aria-live="polite"
                        >
                            <div
                                *ngIf="
                                resetForm.controls['confirmPassword'].errors['required']
                                "
                            >
                                Please fill out this field
                            </div>
                            <div
                                *ngIf="
                                !resetForm.controls['confirmPassword'].errors['required']
                                "
                            >
                                Password does not match
                            </div>
                        </div>
                    </div>
                    <div
                    class="page-password-reset-request__reset-form-field 
                    page-password-reset-request__reset-form-field--line-decoration
                    page-password-reset-request__reset-form-field--static-decoration"
                    >
                        <button
                        class="page-password-reset-request__reset-form-button"
                        (click)="submitForm()"
                        type="button"
                        >
                        RESET PASSWORD
                        <span class="glyphicon glyphicon-menu-right"></span>
                        </button>
                    </div>
                    <div
                        *ngIf="error"
                        class="page-password-reset-request__reset-form-server-error"
                        aria-live="polite"
                        >
                        Something wrong is happening, please try again later.
                    </div>
                </form>
            </div>
        </div>
        <div class="col-lg-6 page-password-reset-request__intro">
            <div class="page-password-reset-request__intro-wrapper">
                <h1>Whitecoat Online Appointments</h1>
                <p>Have you tried Whitecoat's online appointments?</p>
                <ul class="page-password-reset-request__intro-list">
                    <li class="easy">
                        Easy to use, easy to setup. One off flat <br>fee - starting at less than $2 per week
                    </li>
                    <li class="never">
                        Never miss another appointment. <br>40% of requests are made out of hours.
                    </li>
                    <li class="link">
                        Link to your existing PMS, appointment <br>engine, or website
                    </li>
                </ul>
            </div>
        </div>
    </ng-container>

    <div class="page-password-reset-request__result" *ngIf="submitted">
        <div class="page-password-reset-request__result-wrapper">
         
            <h2>Password reset</h2>
        
            <p>Your password has been reset.</p>
        </div>
    </div>
</div>