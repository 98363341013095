import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { GlobalStaticService } from 'src/app/services/global.static-service';

@Component({
  selector: 'app-list-toolbar',
  templateUrl: './list-toolbar.component.html',
  styleUrls: ['./list-toolbar.component.scss'],
})
export class ListToolbarComponent implements OnInit {
  isLoading: boolean = false;
  whitecoatDomainUrl: string;

  constructor() {}

  ngOnInit() {
    this.whitecoatDomainUrl = GlobalStaticService.whitecoatDomainUrl;
  }

  @Input()
  isMoreResultsAvailable: boolean;

  @Input('isLoading')
  set model(value: boolean) {
    this.isLoading = value;
  }

  @Output()
  onMoreClick: EventEmitter<any> = new EventEmitter();

  onMore() {
    this.onMoreClick.emit();
  }
}
