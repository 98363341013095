import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { InfoPanelComponent } from './info-panel/info-panel.component';
import { AssociatedAttribute } from '../../interfaces/associated-attribute';
import { ProviderSearchResult } from '../../interfaces/provider-search-result';
import { ProviderWeeklyOpening } from '../../interfaces/provider-weekly-opening';
import { ProfileDetails } from '../../interfaces/profile-details';
import { Location } from '../../interfaces/location';
import { SeoService } from '../../services/seo.service';
import { SettingsService } from '../../services/settings.service';
import { GlobalStaticService } from 'src/app/services/global.static-service';

@Component({
  selector: 'app-base-profile',
  template: '',
})
export class BaseProfileComponent {
  readonly defaultImage = 'default-profile.png';

  detailImage: string;
  detailDisplayName: string;
  detailBusinessName: string;
  detailModality: string;
  detailWebsite: string;
  detailWebsiteText: string;
  detailEmail: string;
  detailPhone: string;
  detailAddress: string;
  detailAppointment: string;
  detailIntegrationType: string;
  locations: Location[];

  loadingPersionalInfo: boolean;
  loadingNearbyProviders: boolean;
  showProviderNearby: boolean;

  providerNearBy: ProviderSearchResult[];
  isRegistered: boolean;
  about: string;
  services: AssociatedAttribute[];
  openHours: ProviderWeeklyOpening;
  facilities: AssociatedAttribute[];

  modality: string;
  state: string;
  suburb: string;
  surname: string;
  providerid: string;
  modalityId: string;
  canonical: string;
  profileDetails: ProfileDetails;

  domainUrl: string;
  signUpPage: string;

  @ViewChild(InfoPanelComponent) infoPanel: InfoPanelComponent;

  constructor(
    protected seoService: SeoService,
    protected settingsService: SettingsService,
    protected titleService: Title,
    protected meta: Meta,
    protected activatedRoute: ActivatedRoute,
  ) {
    this.domainUrl = GlobalStaticService.whitecoatDomainUrl;
    this.signUpPage = `${this.domainUrl}`;
  }

  //TODO: need to check the link for seo
  createCanonicalURL() {
    this.canonical =
      this.modality +
      '/' +
      this.state +
      '/' +
      this.suburb +
      '/' +
      this.surname +
      '/' +
      this.providerid;

    this.seoService.createCanonicalURL(this.canonical);
  }

  private IfNullThenEmpty(value: string) {
    return value ? value : '';
  }

  setPageTitle(name: string, modalityName: string, suburbstr: string) {
    let providerName = this.IfNullThenEmpty(name);
    let modality = this.IfNullThenEmpty(modalityName);

    let suburb = this.IfNullThenEmpty(suburbstr);

    const title = `${providerName} - ${modality} ${suburb} - Whitecoat`;
    this.titleService.setTitle(title);
    this.meta.updateTag({
      property: 'og:title',
      content: title,
    });
  }

  SetProfileDetails(details: ProfileDetails, name: string) {
    if (details) {
      this.locations = [
        {
          label: name,
          latitude: details.geoLocatoin.latitude,
          longitude: details.geoLocatoin.longitude,
          url: details.personalSummary.providerUrl,
        },
      ];
      this.detailImage = this.isDefaultPhoto(
        details.personalSummary.providerPicture,
      )
        ? null
        : details.personalSummary.providerPicture;
      this.detailBusinessName = details.personalSummary.businessName;
      this.detailModality = details.personalSummary.modality.name;
      this.detailWebsite = details.websiteLink;
      this.detailWebsiteText = this.filterWebsiteUrlText(this.detailWebsite);
      this.detailEmail = details.contactDetails.email;
      this.detailPhone = details.contactDetails.phone;
      this.detailAddress = details.personalSummary.address
        ? this.addressLines(
            details.personalSummary.address.line1,
            details.personalSummary.address.line2,
          ) +
          ', ' +
          details.personalSummary.address.suburb +
          ', ' +
          details.personalSummary.address.state +
          ' ' +
          details.personalSummary.address.postcode
        : null;
      this.detailAppointment =
        details.onlineBookingDetails && details.onlineBookingDetails.bookingId
          ? details.onlineBookingDetails.bookingUrl
          : null;
      this.detailIntegrationType = (
        details.onlineBookingDetails.integrationType || ''
      ).toLowerCase();
      this.isRegistered = details.isRegistered;

      this.about = details.bio;
      this.services = details.services;
      this.openHours = details.providerWeeklyOpening;
      this.facilities = details.facilities;

      const metaTagContent = `${name} is a ${this.detailModality} located in ${
        details.personalSummary.address.suburb.substring(0, 1).toUpperCase() +
        details.personalSummary.address.suburb.substring(1)
      } - Search and book healthcare providers on the Whitecoat Directory`;

      this.meta.updateTag({
        name: 'description',
        content: metaTagContent,
      });
      this.meta.updateTag({
        property: 'og:description',
        content: metaTagContent,
      });
    }
  }

  private addressLines(line1: string, line2: string) {
    if (line1 && line2) {
      return line1 + ', ' + line2;
    } else {
      return line1 ? line1 : '' + line2 ? line2 : '';
    }
  }

  setNearbySectionShown() {
    this.showProviderNearby =
      this.providerNearBy != null && this.providerNearBy.length > 0;
  }

  //only show the seperator when nearby and tabPanel panels display in the same time
  isShowSeperator(): boolean {
    if (this.infoPanel) {
      return this.infoPanel.isShowPanel() && this.showProviderNearby;
    }
    return false;
  }

  isShowNearbyPanel(): boolean {
    return this.showProviderNearby && !this.loadingNearbyProviders;
  }

  isLoadingPersionalDeatils(): boolean {
    return this.loadingPersionalInfo;
  }
  isLoadingNearbyProviders(): boolean {
    return this.loadingNearbyProviders;
  }

  isShowTabPanel(checkRegistered: boolean = true): boolean {
    return (
      !this.isLoadingPersionalDeatils() &&
      (this.isRegistered || !checkRegistered)
    );
  }

  isDefaultPhoto(picUrl: string): boolean {
    if (picUrl) {
      return picUrl.includes(this.defaultImage);
    }
    return false;
  }

  filterWebsiteUrlText(websiteUrl: string): string {
    if (websiteUrl) {
      return websiteUrl
        .trim()
        .replace(/^https?:\/\//, '')
        .toLowerCase();
    }
    return null;
  }
}
