<div
  class="container toolbar-lg col-xs-12"
  *ngIf="isMoreResultsAvailable"
  [class.active]="isLoading"
>
  <div class="row">
    <a class="col-xs-12 more-btn-lg" (click)="onMore()"
      >Load more<span *ngIf="!isLoading" class="more-btn-arrow"></span
      ><app-loader *ngIf="isLoading" class="more-btn-loader"></app-loader>
    </a>
  </div>
</div>
