import { Component, Input, OnInit } from '@angular/core';
import { GlobalStaticService } from 'src/app/services/global.static-service';

@Component({
  selector: 'app-nav-modal',
  templateUrl: './nav-modal.component.html',
  styleUrls: ['./nav-modal.component.scss'],
})
export class NavModalComponent implements OnInit {
  GlobalStaticSrv = GlobalStaticService;

  isOpen: boolean = false;
  downArrowImage = 'url(/assets/images/chevron-down-black.svg)';
  upArrowImage = 'url(/assets/images/chevron-up-black.svg)';
  toggleArrow = this.downArrowImage;

  constructor() {}
  ngOnInit(): void {}

  resetStatus() {
    if (this.isOpen) this.toggerSubMenu();
  }

  toggerSubMenu() {
    this.isOpen = !this.isOpen;
    if (this.isOpen) this.toggleArrow = this.upArrowImage;
    else this.toggleArrow = this.downArrowImage;
  }
}
