<div class="row">
  <div class="panel panel-default col-md-12">
    <div class="panel-body">
      <table>
        <tbody>
          <tr>
            <td class="info align-middle">
              Sorry, we don't have any matching results.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
