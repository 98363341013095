import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-loader-card',
  templateUrl: './loader-card.component.html',
  styleUrls: ['./loader-card.component.scss'],
})
export class LoaderCardComponent {
  @Input() hideTick: boolean = false;
}
