
<div class="row">
  <div class="col-xs-6  col-sm-6 col-md-3" *ngFor="let p of practitioners">
    <div class="panel practicioner-panel">
      <div class="panel-body">
        <div class="profile-picture">
          <img [src]=" p.providerPicture" class="img" alt="" />
        </div>
        <p  *ngIf="hasReviewFeature" class="label label-default star-rating center-block">
          <span class="glyphicon glyphicon-star" aria-hidden="true"></span> {{ p.averageStarRating | number:'1.1-1' }}
        </p>
        <p class="title" style="text-align:center">
          <a href="{{getProviderUrl(p.providerUrl)}}">{{ p.displayName.asFullName }}</a>
        </p>
        <p class="subtitle">
          <a href="{{getProviderUrl(p.providerUrl)}}">View Profile</a>
        </p>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <span class="whitecoat-logo " title="Whitecoat"></span>
</div>

