<div class="row list-column">
  <div class="list-column-left">
    <app-list-not-found
      *ngIf="!isLoading && searchResults.length === 0"
    ></app-list-not-found>
    <div class="panel-container">
      <ng-container *ngIf="isLoading && searchResults.length === 0">
        <app-loader-card></app-loader-card>
        <app-loader-card></app-loader-card>
        <app-loader-card></app-loader-card>
        <app-loader-card></app-loader-card>
        <app-loader-card></app-loader-card>
        <app-loader-card></app-loader-card>
      </ng-container>
      <div [hidden]="!showResults" *ngFor="let p of searchResults">
        <app-provider-card [isPractice]="true" [provider]="p">
        </app-provider-card>
      </div>
      <div *ngIf="searchResults.length > 0">
        <app-list-toolbar
          [isLoading]="isLoading"
          [isMoreResultsAvailable]="isMoreResultsAvailable"
          (onMoreClick)="onMoreClick()"
        ></app-list-toolbar>
      </div>
    </div>
  </div>
  <div class="list-column-right right-col map desktop-only">
    <app-loader-map
      *ngIf="isLoading && searchResults.length === 0"
    ></app-loader-map>
    <app-map-location
      [hidden]="locations.length === 0"
      [locations]="locations"
      [userLocation]="location"
    ></app-map-location>
  </div>
</div>
