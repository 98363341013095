import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { SearchResponse } from '../interfaces/search-response';
import { Location } from '../interfaces/location';

@Injectable()
export class SearchBaseService {
  readonly searchByText = 'text';
  readonly searchByGeo = 'geo';
  readonly searchByAddress = 'address';
  constructor(protected http: HttpClient) {}
  protected prepQueryString(
    method: string,
    state: string,
    postcode: string,
    suburb: string,
    searchText: string,
    latitude: number,
    longitude: number,
    pageNumber: number,
    withBookingOnly: boolean,
    IsTeleHealth: boolean,
    isPaywithWhitecoat: boolean,
    isDynamoDb: boolean,
  ) {
    let qs = this.prepSearchMethodString(
      method,
      state,
      postcode,
      suburb,
      searchText,
      latitude,
      longitude,
    );
    qs = this.prepFilterQueryString(
      qs,
      pageNumber,
      withBookingOnly,
      IsTeleHealth,
      isPaywithWhitecoat,
      isDynamoDb,
    );

    return qs;
  }

  protected direcotrySearch(
    searchApiUrl: string,
    modality: string,
    searchText: string,
    state: string,
    postcode: string,
    suburb: string,
    location: Location,
    pageNumber: number,
    isDynamoDb: boolean = false,
    withBookingOnly: boolean,
    IsTeleHealth: boolean,
    isPaywithWhitecoat: boolean,
  ): Observable<SearchResponse> {
    let qs = '';
    if (searchText) {
      qs = this.prepQueryString(
        this.searchByText,
        '',
        '',
        '',
        searchText,
        0,
        0,
        pageNumber,
        withBookingOnly,
        IsTeleHealth,
        isPaywithWhitecoat,
        isDynamoDb,
      );
    } else if (
      location &&
      location.latitude !== 0 &&
      location.longitude !== 0
    ) {
      qs = this.prepQueryString(
        this.searchByGeo,
        '',
        '',
        '',
        '',
        location.latitude!,
        location.longitude!,
        pageNumber,
        withBookingOnly,
        IsTeleHealth,
        isPaywithWhitecoat,
        isDynamoDb,
      );
    } else {
      qs = this.prepQueryString(
        this.searchByAddress,
        state,
        postcode,
        suburb,
        '',
        0,
        0,
        pageNumber,
        withBookingOnly,
        IsTeleHealth,
        isPaywithWhitecoat,
        isDynamoDb,
      );
    }

    return this.http.get(searchApiUrl + `/cards/${modality}${qs}`).pipe(
      map((response: any) => {
        return response as SearchResponse;
      }),
      catchError(<T>(error: any, c?: T) => {
        return throwError(() => {
          new Error(error.error || 'Server error');
        });
      }),
    );
  }

  protected getNearbyQueryString(
    excludedId: string,
    state: string,
    postcode: string,
    suburb: string,
  ) {
    return `?excludedId=${excludedId}&state=${state}&postcode=${postcode}&suburb=${suburb}`;
  }

  private prepSearchMethodString(
    method: string,
    state: string,
    postcode: string,
    suburb: string,
    searchText: string,
    latitude: number,
    longitude: number,
  ) {
    let qs = '';
    qs = `${this.getPrefix(qs)}method=${method}`;

    switch (method) {
      case this.searchByText:
        qs = `${this.getPrefix(qs)}searchText=${searchText}`;
        break;
      case this.searchByGeo:
        qs = `${this.getPrefix(qs)}latitude=${latitude}`;
        qs = `${this.getPrefix(qs)}longitude=${longitude}`;
        break;
      case this.searchByAddress:
        qs = `${this.getPrefix(qs)}state=${state}`;
        qs = `${this.getPrefix(qs)}postcode=${postcode}`;
        qs = `${this.getPrefix(qs)}suburb=${suburb}`;
        break;
      default:
        break;
    }

    return qs;
  }

  private prepFilterQueryString(
    searchMethodQuery: string,
    pageNumber: number,
    withBookingOnly: boolean,
    IsTeleHealth: boolean,
    isPaywithWhitecoat: boolean,
    isDynamoDb: boolean,
  ) {
    let qs = searchMethodQuery;

    qs = `${this.getPrefix(qs)}pageNumber=${pageNumber}`;

    if (!!withBookingOnly) {
      qs = `${this.getPrefix(qs)}withBookingOnly=${withBookingOnly}`;
    }

    if (!!IsTeleHealth) {
      qs = `${this.getPrefix(qs)}isTeleHealth=${IsTeleHealth}`;
    }

    if (!!isPaywithWhitecoat) {
      qs = `${this.getPrefix(qs)}isPaywithWhitecoat=${isPaywithWhitecoat}`;
    }

    return qs;
  }

  protected getPrefix(qs: string) {
    if (!qs || qs.length === 0) {
      qs = '?';
    } else {
      qs = qs + '&';
    }

    return qs;
  }
}
