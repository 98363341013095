import { Component, OnInit, Input } from '@angular/core';
import { ProviderWeeklyOpening } from '../../../interfaces/provider-weekly-opening';

@Component({
  selector: 'app-practice-openinghours-fap',
  templateUrl: './practice-openinghours-fap.component.html',
  styleUrls: ['./practice-openinghours-fap.component.scss'],
})
export class PracticeOpeningHoursFapComponent implements OnInit {
  constructor() {}

  @Input() openingHours: ProviderWeeklyOpening;

  ngOnInit() {}

  checkopeningHours(): boolean {
    if (this.openingHours == null) return false;

    if (!!this.openingHours.mondayOpen) return true;
    else if (!!this.openingHours.tuesdayOpen) return true;
    else if (!!this.openingHours.wednesdayOpen) return true;
    else if (!!this.openingHours.thursdayOpen) return true;
    else if (!!this.openingHours.fridayOpen) return true;
    else if (!!this.openingHours.saturdayOpen) return true;
    else if (!!this.openingHours.sundayOpen) return true;

    return false;
  }
}
