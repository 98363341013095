<div class="container container-body" *ngIf="showListPage()">
  <div class="search-results col-md-12">
    <div class="row desktop-only">
      <section aria-label="Search on desktop only">
        <app-search [inline]="true"></app-search>
      </section>
    </div>

    <div class="row mobile-filter-section mobile-only">
      <div style="padding: 0">
        <app-loader-map *ngIf="isDataLoading()"></app-loader-map>
        <app-map-location
          *ngIf="
            (practiceLoaded || practicionerLoaded) && locations?.length > 0
          "
          [type]="'mobile'"
          [view]="view === 'list' ? 'list' : 'map'"
          [locations]="locations"
          [userLocation]="location"
          (viewUpdate)="mapViewUpdate()"
        ></app-map-location>
      </div>

      <div class="mobile-filter-label" [class.desktop-only]="view === 'map'">
        Results for <b class="mobile-filter-label--modality">{{ modality }}</b
        ><ng-container *ngIf="searchLocation">
          in <b>{{ searchLocation }}</b></ng-container
        >
      </div>
    </div>
    <div class="row filter-container" [class.desktop-only]="view === 'map'">
      <section aria-label="Filter results by practice or practitioner">
        <div class="filter-section" *ngIf="!hideFilter()">
          <span>Filter results by</span>
          <a
            class="searchTabItem first"
            [routerLink]="['./']"
            [queryParams]="{ activeTab: 'practice' }"
            [ngClass]="{ active: isPracticeTab() }"
            >Practice</a
          >
          <a
            class="searchTabItem"
            [ngClass]="{ active: isPractitionerTab() }"
            [routerLink]="['./']"
            [queryParams]="{ activeTab: 'practitioner' }"
            >Practitioner</a
          >
        </div>
      </section>
    </div>

    <section aria-label="Search result list">
      <div class="row" [class.desktop-only]="view === 'map'">
        <div class="col-md-12">
          <app-list-practice
            [modality]="listModality"
            [searchText]="searchText"
            [gaTagCSSSuffix]="'Search'"
            [state]="state"
            [suburb]="suburb"
            [postcode]="postcode"
            [location]="location"
            [hidden]="!isPracticeTab()"
            [isDynamoDb]="isDynamoDb"
            [isPaywithWhitecoat]="isPaywithWhitecoat"
            [isTelehealth]="isTelehealth"
            [withBookingOnly]="isOnlineAppointment"
            (onResultsLoaded)="onPracticeResultsLoaded($event)"
          >
          </app-list-practice>

          <app-list-practitioner
            [modality]="listModality"
            [gaTagCSSSuffix]="'Search'"
            [searchText]="searchText"
            [state]="state"
            [suburb]="suburb"
            [postcode]="postcode"
            [location]="location"
            [hidden]="!isPractitionerTab()"
            [isDynamoDb]="isDynamoDb"
            [isPaywithWhitecoat]="isPaywithWhitecoat"
            [isTelehealth]="isTelehealth"
            [withBookingOnly]="isOnlineAppointment"
            (onResultsLoaded)="onPracticionerResultsLoaded($event)"
          >
          </app-list-practitioner>
        </div>
      </div>
    </section>
  </div>
</div>

<div
  class="empty"
  *ngIf="isPracticeResultEmpty() && isPractitionerResultEmpty()"
>
  <div>
    <p class="head"><b>We're sorry!</b></p>
    <p>
      We can't find any results that match your search for
      <b class="mobile-filter-label--modality">"{{ modality }}"</b
      ><ng-container *ngIf="searchLocation">
        in <b>"{{ searchLocation }}"</b></ng-container
      >
    </p>
    <a href="/">Try again</a>
  </div>
</div>

<app-toast-notification></app-toast-notification>
