import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  constructor(@Inject(DOCUMENT) private dom) {}

  createCanonicalURL(canonical: string) {
    let baseUrl = 'https://www.whitecoat.com.au';

    let gBaseUrl = environment.whitecoatDomainUrl;
    if (gBaseUrl !== '' && gBaseUrl !== undefined) {
      baseUrl = gBaseUrl;
    }

    baseUrl =
      baseUrl.charAt(baseUrl.length - 1) === '/'
        ? baseUrl.substring(0, baseUrl.length - 1)
        : baseUrl;

    let canUrl = baseUrl + '/directory/practice/' + canonical.toLowerCase();

    let link: HTMLLinkElement = this.dom.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.dom.head.appendChild(link);
    link.setAttribute('href', canUrl);
  }
}
