import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-loader-map',
  templateUrl: './loader-map.component.html',
  styleUrls: ['./loader-map.component.scss'],
})
export class LoaderMapComponent {
  @Input() type: string;
}
