import { Component, OnInit, Input } from '@angular/core';
import { ProviderSummary } from '../../../interfaces/provider-summary';
import { SettingsService } from '../../../services/settings.service';
import { GlobalStaticService } from 'src/app/services/global.static-service';
import { url } from 'node:inspector';

@Component({
  selector: 'app-practice-practitioners-fap',
  templateUrl: './practice-practitioners-fap.component.html',
  styleUrls: ['./practice-practitioners-fap.component.scss'],
})
export class PracticePractitionersFapComponent implements OnInit {
  @Input()
  practiceName: string;

  @Input()
  practitioners: ProviderSummary[];

  @Input()
  practiceId: number;

  @Input() 
  isCbhsCorp: boolean;

  whitecoatDomainUrl: string;

  hasReviewFeature: boolean;

  constructor(private settingsService: SettingsService) {
    this.hasReviewFeature = this.settingsService.hasReviewFeature();
  }

  ngOnInit() {
    this.whitecoatDomainUrl = GlobalStaticService.whitecoatDomainUrl;
  }

  getProviderUrl(providerUrl?: string): string {
    if (!providerUrl) return '';
      if (this.isCbhsCorp) {
      const updatedUrl = providerUrl.replace('cbhs', 'cbhs-corporate');
      return this.whitecoatDomainUrl + updatedUrl;
    } else {
      return this.whitecoatDomainUrl + providerUrl;
    }
  }
  
}