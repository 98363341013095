<div class="row info-panel" [hidden]="!isShowPanel()">
  <div class="nav-container">
    <ul #tabListHeader class="nav nav-tabs col-xs-12" role="tablist" id="tab">
      <li
        [id]="providerTabId"
        [attr.data-bs-content-id]="providerContentId"
        role="presentation"
        class="nav-item"
      >
        <a
          data-bs-toggle="tab"
          role="tab"
          [attr.data-bs-target]="'#' + providerContentId"
          class="active"
          >Practitioners</a
        >
      </li>
      <li
        [id]="aboutTabId"
        [attr.data-bs-content-id]="aboutContentId"
        role="presentation"
        class="nav-item"
      >
        <a
          data-bs-toggle="tab"
          role="tab"
          [attr.data-bs-target]="'#' + aboutContentId"
          >About</a
        >
      </li>
      <li
        [id]="serviceTabId"
        [attr.data-bs-content-id]="serviceContentId"
        role="presentation"
        class="nav-item"
      >
        <a
          data-bs-toggle="tab"
          role="tab"
          [attr.data-bs-target]="'#' + serviceContentId"
          >Services</a
        >
      </li>
      <li
        [id]="facilityTabId"
        [attr.data-bs-content-id]="facilityContentId"
        role="presentation"
        class="nav-item"
      >
        <a
          data-bs-toggle="tab"
          role="tab"
          [attr.data-bs-target]="'#' + facilityContentId"
          >Facilities</a
        >
      </li>
      <li
        [id]="openHourTabId"
        [attr.data-bs-content-id]="openHourContentId"
        role="presentation"
        class="nav-item"
      >
        <a
          data-bs-toggle="tab"
          role="tab"
          [attr.data-bs-target]="'#' + openHourContentId"
          >Opening hours</a
        >
      </li>
    </ul>
  </div>

  <div #tabListContent class="tab-content col-xs-12">
    <div
      [id]="providerContentId"
      class="tab-pane fade active show sub-providers"
      [class.hide-tab]="!showProviders"
      role="tabpanel"
    >
      <app-sub-provider-list [practitioners]="practitioners">
      </app-sub-provider-list>
    </div>
    <div
      [id]="aboutContentId"
      class="tab-pane fade about"
      [class.hide-tab]="!showAbout"
      role="tabpanel"
    >
      <span>
        {{ bio }}
      </span>
    </div>
    <div
      [id]="serviceContentId"
      class="tab-pane fade"
      [class.hide-tab]="!showServices"
      role="tabpanel"
    >
      <app-list-content [contentList]="serviceList"></app-list-content>
    </div>
    <div
      [id]="facilityContentId"
      class="tab-pane fade"
      [class.hide-tab]="!showFacilities"
      role="tabpanel"
    >
      <app-list-content [contentList]="facilityList"></app-list-content>
    </div>
    <div
      [id]="openHourContentId"
      class="tab-pane fade"
      [class.hide-tab]="!showOpenHours"
      role="tabpanel"
    >
      <app-opening-hours [openingTime]="openinghourList"> </app-opening-hours>
    </div>
  </div>
</div>
