 <app-booking-redirect-fap *ngIf="isBookingType('httpredirect')"
                          [gaTagCSSSuffix]="gaTagCSSSuffix"
                          [providerId]="providerId"
                          [searchCriteria]="searchCriteria"
                          [useAltView]="useAltView"
                          [bookingUrl]="onlineBookingDetails.bookingUrl"
                           [acceptTelehealth]="onlineBookingDetails.acceptTelehealth"
                      [acceptTelehealthUrl]="onlineBookingDetails.acceptTelehealthUrl"></app-booking-redirect-fap>

<app-booking-iframe-fap *ngIf="isBookingType('iframe')"
                        [gaTagCSSSuffix]="gaTagCSSSuffix"
                        [searchCriteria]="searchCriteria"
                        [useAltView]="useAltView"
                        [providerId]="providerId"
                        [providerName]="providerName"
                        [onlineBookingDetails]="onlineBookingDetails"
                        [acceptTelehealth]="onlineBookingDetails.acceptTelehealth"
                    [acceptTelehealthUrl]="onlineBookingDetails.acceptTelehealthUrl"></app-booking-iframe-fap>

