import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'time12Hour',
})
export class Time12HourPipe implements PipeTransform {
  transform(time: string): string {
    try {
      if (!time) return 'Closed';
      const timeRegex = /^([01]?[0-9]|2[0-3]):([0-5][0-9])$/;
      const match = timeRegex.exec(time);
      if (!match) {
        return 'Closed';
      }
      const [hour, minute] = match.slice(1);
      const hourNum = parseInt(hour, 10);
      const ampm = hourNum >= 12 ? 'PM' : 'AM';
      const displayHour = hourNum % 12 || 12;
      return `${displayHour}:${minute} ${ampm}`;
    } catch (e) {
      return 'Closed';
    }
  }
}
