import { Component, Input } from '@angular/core';

import { OnlineBookingDetails } from '../../../interfaces/online-booking-details';
import { SearchCriteria } from '../../../interfaces/search-criteria';
import { GoogleTagManagerService } from '../../../services/google-tag-manager.service';
import { BookingService } from '../../../services/booking.service';

@Component({
  selector: 'app-booking-iframe-fap',
  templateUrl: './booking-iframe-fap.component.html',
  styleUrls: ['./booking-iframe-fap.component.scss'],
})
export class BookingIframeFapComponent {
  @Input()
  searchCriteria: SearchCriteria;

  @Input()
  gaTagCSSSuffix: string;

  @Input()
  useAltView: boolean;

  @Input()
  onlineBookingDetails: OnlineBookingDetails;

  @Input()
  providerName: string;

  @Input()
  providerId: number;

  isOpen: boolean;
  bookingUrl: string = '';

  @Input()
  acceptTelehealth: boolean;

  @Input()
  acceptTelehealthUrl: string;

  constructor(
    private gtmService: GoogleTagManagerService,
    private bookingService: BookingService,
  ) {
    this.useAltView = false;
    this.isOpen = false;
  }

  openBookingUrl(event: any): void {
    if (!!this.providerId) {
      this.bookingService
        .bookNow(this.providerId, 'iFrame')
        .subscribe(() => {});
    }

    if (!this.onlineBookingDetails || !this.onlineBookingDetails.bookingUrl)
      return;

    this.gtmService.trigger(
      this.gaTagCSSSuffix + 'BookNowClickedEvent',
      this.searchCriteria,
      this.providerId,
    );
    this.bookingUrl = this.onlineBookingDetails.bookingUrl;
    this.isOpen = true;

    this.gtmService.trigger(
      'BookingLoadedEvent',
      this.searchCriteria,
      this.providerId,
    );
    event.stopPropagation();
  }

  close(event: any): void {
    this.isOpen = false;
    event.stopPropagation();
  }
}
