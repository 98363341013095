import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { SearchBaseService } from './search-base.service';
import { EscapeUrlPipe } from '../pipes/escape-url.pipe';
import { Practice } from '../interfaces/practice';
import { SearchResponse } from '../interfaces/search-response';
import { Location } from '../interfaces/location';
import { ProviderSearchResult } from '../interfaces/provider-search-result';
import { environment } from 'src/environments/environment';

@Injectable()
export class PracticeService extends SearchBaseService {
  private practiceApiUrl = environment.API_BASE_URL + 'api/practice';

  constructor(
    protected override http: HttpClient,
    private escapeUrlPipe: EscapeUrlPipe,
  ) {
    super(http);
  }

  getPractice(id: string): Observable<Practice> {
    return this.http.get(this.practiceApiUrl + `/getpracticebyid/${id}`).pipe(
      map((response: any) => response as Practice),
      catchError(<T>(error: any, c?: T) => {
        return throwError(() => new Error(error.error || 'Server error'));
      }),
    );
  }

  getPracticeByProviderId(
    providerId: string,
    modalityId: string,
  ): Observable<Practice> {
    return this.http
      .get(this.practiceApiUrl + `/${providerId}/${modalityId}`)
      .pipe(
        map((response: any) => response as Practice),
        catchError(<T>(error: any, c?: T) => {
          return throwError(() => new Error(error.error || 'Server error'));
        }),
      );
  }

  search(
    modality: string,
    searchText: string,
    state: string,
    postcode: string,
    suburb: string,
    location: Location,
    pageNumber: number,
    isDynamoDb: boolean = false,
    withBookingOnly: boolean,
    IsTeleHealth: boolean,
    isPaywithWhitecoat: boolean,
  ): Observable<SearchResponse> {
    return this.direcotrySearch(
      this.practiceApiUrl,
      modality,
      searchText,
      state,
      postcode,
      suburb,
      location,
      pageNumber,
      isDynamoDb,
      withBookingOnly,
      IsTeleHealth,
      isPaywithWhitecoat,
    );
  }

  searchSimulation(providerId: number, modalityId: number) {
    return this.http
      .get(
        this.practiceApiUrl + `/search-simulation/${providerId}/${modalityId}`,
      )
      .pipe(
        map((response: any) => response as SearchResponse),
        catchError(<T>(error: any, c?: T) => {
          return throwError(() => new Error(error.error || 'Server error'));
        }),
      );
  }

  getPracticeUrl(p: ProviderSearchResult): string {
    if (!p) {
      return '';
    }
    var businessName = '';
    if (p.businessName) {
      businessName = p.businessName;
    } else {
      businessName = p.displayName;
    }
    const providerUrl = `/directory/practice/${this.escapeUrl(p.modality.name)}/${p.state}/${this.escapeUrl(p.suburb)}/${this.escapeUrl(businessName)}/${p.providerId}/${p.modality.id}`;
    return providerUrl;
  }

  getPracticeListNearby(
    excludedId: string,
    modality: string,
    state: string,
    postcode: string,
    suburb: string,
  ): Observable<ProviderSearchResult[]> {
    return this.http
      .get(
        this.practiceApiUrl +
          `/nearby/${modality}${this.getNearbyQueryString(excludedId, state, postcode, suburb)}`,
      )
      .pipe(
        map((response: any) => response as ProviderSearchResult[]),
        catchError(<T>(error: any, c?: T) => {
          return throwError(() => new Error(error.error || 'Server error'));
        }),
      );
  }

  calculatePageToLoad(offsetNumber: number, currentPageNumber: number) {
    if (offsetNumber == 0 && currentPageNumber == 0) {
      currentPageNumber = 1;
    }
    return currentPageNumber;
  }

  private escapeUrl(url: string): string {
    return this.escapeUrlPipe.transform(url);
  }
}
