import { Injectable } from '@angular/core';
import { SearchCriteria } from '../interfaces/search-criteria';

export type GtmData = { [key: string]: any };

@Injectable()
export class GoogleTagManagerService {
  constructor() {
    // Make sure the dataLayer property exists on the window object,
    // or we will not be able to communicate with GTM.
    //if (!Object.hasOwnProperty.call(window, "dataLayer")) {
    //    throw new Error("'window.dataLayer' is not defined.");
    //}
  }

  public trigger(
    event: string,
    searchCriteria: SearchCriteria,
    providerId?: number,
  ) {
    try {
      (window as any)['dataLayer'].push({
        event: event,
        modality: searchCriteria.modality,
        postcode: searchCriteria.postcode,
        searchText: searchCriteria.searchText,
        state: searchCriteria.state,
        suburb: searchCriteria.suburb,
        providerId: providerId,
        providerType: searchCriteria.isPractice ? 'Practice' : 'Practitioner',
      });
    } catch (ignore) {}
  }
}
