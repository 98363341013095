import { Component, Input } from '@angular/core';
import { HealthFund } from '../../../interfaces/health-fund';

@Component({
  selector: 'app-practice-health-funds',
  templateUrl: './practice-health-funds.component.html',
  styleUrls: ['./practice-health-funds.component.scss'],
})
export class PracticeHealthFundsComponent {
  @Input()
  healthFunds: HealthFund[];
}
