<div class="nav-content">
  <div class="nav-item">
    <span
      ><a href="{{ GlobalStaticSrv.whitecoatDomainPagesUrl }}/about/"
        >About</a
      ></span
    >
  </div>
  <div class="nav-item">
    <span
      ><a href="{{ GlobalStaticSrv.whitecoatDomainPagesUrl }}/providers/"
        >For Providers</a
      ></span
    >
  </div>
  <div class="nav-item">
    <span
      ><a href="{{
            GlobalStaticSrv.ProviderPortalUrl
          }}/login"
        >Login</a
      ></span
    >
  </div>

  <div
    class="nav-item expanded"
    (click)="toggerSubMenu()"
    [style.background-image]="toggleArrow"
  >
    <span>Register</span>
  </div>

  <div [style.display]="isOpen ? 'block' : 'none'">
    <div class="expanded-details">
      <span
        ><a
          href="{{
            GlobalStaticSrv.whitecoatDomainPagesUrl
          }}/providers/practice-listings/"
          >Practice</a
        ></span
      >
    </div>
    <div class="expanded-details">
      <span
        ><a href="{{
            GlobalStaticSrv.whitecoatDomainUrl
          }}"
          >Practitioner</a
        ></span
      >
    </div>
  </div>
</div>
