import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError, shareReplay } from 'rxjs/operators';
import { KeyValue } from '../interfaces/key-value';
import { environment } from 'src/environments/environment';

@Injectable()
export class SettingsService {
  _whitecoatDomainUrl: Observable<KeyValue>;
  _whitecoatDomainPagesUrl: Observable<KeyValue>;
  _userAthentication: Observable<KeyValue>;
  _athentication: Observable<KeyValue>;

  constructor(private http: HttpClient) {}

  login(model: string): Observable<KeyValue> {
    if (!this._userAthentication) {
      this._userAthentication = this.http
        .post(environment.API_BASE_URL + 'api/account/login', model)
        .pipe(
          map((response: any) => response as KeyValue),
          catchError(<T>(error: any, c?: T) => {
            return throwError(() => new Error(error.error || 'Server error'));
          }),
          shareReplay(1),
        );
    }
    return this._userAthentication;
  }

  isAthentication(): Observable<KeyValue> {
    if (!this._athentication) {
      this._athentication = this.http.get<KeyValue>(
        environment.API_BASE_URL + 'api/account/athentication',
      );
    }
    return this._athentication;
  }

  hasReviewFeature(): boolean {
    return false;
  }

  sendResetLink(email: string): Observable<any> {
    return this.http
      .post(
        environment.API_BASE_URL + `api/account/password-token/send-reset-link`,
        {
          email: email,
        },
      )
      .pipe(
        map((response: any) => response),
        catchError(<T>(error: any, c?: T) => {
          return throwError(() => {
            error.error || 'Server error';
          });
        }),
      );
  }

  resetPassword(
    token: string,
    newPassword: string,
    confirmedPassword: string,
  ): Observable<any> {
    return this.http
      .post(environment.API_BASE_URL + `api/account/membership/password`, {
        token: token,
        newPassword: newPassword,
        confirmedPassword: confirmedPassword,
      })
      .pipe(
        map((response: any) => response),
        catchError(<T>(error: any, c?: T) => {
          return throwError(() => {
            error.error || 'Server error';
          });
        }),
      );
  }
}
