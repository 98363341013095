<section aria-label="Whitecoat website header">
  <nav class="navbar">
    <div class="container">
      <div class="row">
        <div class="mobile">
          <div class="logo">
            <a class="mobile-toggle collapsed pull-left" href="/">
              <img
                class="img-responsive logo-small"
                src="./assets/images/whitecoat-logo-small.png"
                alt="Whitecoat Logo"
                width="16"
                height="16"
              />
            </a>
          </div>

          <div class="mobile-header">
            <div class="arrow-container">
              <a
                class="modal-back pull-left"
                (click)="goBack()"
                *ngIf="page !== '/'"
              >
                <img class="img-back" src="./assets/images/arrow-left.svg" />
              </a>
            </div>

            <a class="modal-title" href="/">
              <img
                class="img-title"
                src="./assets/images/whitecoat-logo-text.svg"
                alt="Whitecoat Logo"
                width="97"
                height="19"
              />
            </a>

            <div style="display: flex; flex-direction: row">
              <button
                type="button"
                class="btn btn-primary nav-button search-button"
                data-bs-toggle="modal"
                data-bs-target="#headerModal"
                (click)="openModal(searchModalType)"
              ></button>

              <button
                type="button"
                class="btn btn-primary nav-button burger-button"
                data-bs-toggle="modal"
                data-bs-target="#headerModal"
                (click)="openModal(navModalType)"
              ></button>
            </div>
          </div>
        </div>

        <div class="col-md-3 logo">
          <a href="/">
            <img
              class="img"
              src="./assets/images/whitecoat-logo.svg"
              alt="Whitecoat Logo"
              width="154"
              height="55"
          /></a>
        </div>

        <div class="col-md-9 body">
          <ul class="content text-right">
            <li>
              <a href="{{ GlobalStaticSrv.whitecoatDomainPagesUrl }}/about/"
                >About</a
              >
            </li>
            <li>
              <a href="{{ GlobalStaticSrv.whitecoatDomainPagesUrl }}/providers/"
                >For providers</a
              >
            </li>
            <li>
              <a href="{{ GlobalStaticSrv.ProviderPortalUrl }}/login"
                >Login</a
              >
            </li>
            <li>
              <div class="dropdown">
                <button
                  type="button"
                  id="registerButton"
                  class="dropdown-btn"
                  data-bs-toggle="collapse"
                  href="#registerList"
                  [style.background-image]="returnImage('register')"
                  (click)="toggleImage('register')"
                  (blur)="blurRegister()"
                >
                  Register
                </button>

                <div class="dropdown-content">
                  <ul id="registerList" class="list-group collapse">
                    <li class="list-group-item">
                      <a
                        href="{{
                          GlobalStaticSrv.whitecoatDomainPagesUrl
                        }}/providers/practice-listings/"
                         (focus)="focusRegister()"
                         (blur)="blurRegister()"
                      >
                        <span>Practice</span></a
                      >
                    </li>

                    <li class="list-group-item">
                      <a
                        href="{{
                          GlobalStaticSrv.whitecoatDomainUrl
                        }}"
                         (focus)="focusRegister()"
                         (blur)="blurRegister()"
                      >
                        <span>Practitioner</span></a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <app-header-modal [modalType]="modalType"></app-header-modal>
  </nav>
</section>
