<div class="row maplocation">
  <div class="map">
    <google-map height="100%" width="100%" [zoom]="16">
      <map-marker
          *ngIf="location"
          #markerElem="mapMarker"
          [position]="location.position"
        >
        </map-marker>
      </google-map>
  </div>
</div>