import { Component, Input, OnInit } from '@angular/core';
import { OpeningHours } from '../../../interfaces/opening-hours';

@Component({
  selector: 'app-opening-hours',
  templateUrl: './opening-hours.component.html',
  styleUrls: ['./opening-hours.component.scss'],
})
export class OpeningHoursComponent implements OnInit {
  @Input() openingTime: OpeningHours[];
  constructor() {}

  ngOnInit() {}
}
