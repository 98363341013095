import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'escapeUrl',
})
export class EscapeUrlPipe implements PipeTransform {
  transform(url: string) {
    try {
      if (!url) return '';

      return url
        .toLowerCase()
        .replace(/ /g, '-')
        .replace(/\//g, ' ')
        .replace(/&/g, '-and-');
    } catch (e) {
      return '';
    }
  }
}
