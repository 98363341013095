<div class="container">
  <div class="row container-body">
    <div class="col-md-3 d-none d-sm-block">
      <div class="row practice-summary">

        <div *ngIf="isDefaultProviderPicture(practiceDetails?.providerSummary?.providerPicture); else profilePicture" class="col-md-12 hero">
          <div class="col-md-12">
            <table>
              <tbody>
                <tr>
                  <td class="text-center align-middle"><h3>{{practiceDetails?.providerSummary?.businessName}}</h3></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <ng-template #profilePicture>
          <div class="col-md-12 hero-img-container">
            <div class="hero-img">
              <div class="practice-img" [ngStyle]="{'background-image': 'url(' + practiceDetails?.providerSummary?.providerPicture + ')'}"></div>
            </div>
          </div>
        </ng-template>

        <div class="col-md-12">
          <app-practice-contact-fap [contactDetails]="practiceDetails?.contactDetails"
                                    [websiteAndSocialMediaLinks]="practiceDetails?.websiteAndSocialMediaLinks"
                                    [providerSummary]="practiceDetails?.providerSummary"
                                    [onlineBookingDetails]="practiceDetails?.onlineBookingDetails"
                                    [gaTagCSSSuffix]="'Profile'"
                                    [searchCriteria]="searchCriteria"
                                    [index]="0">
          </app-practice-contact-fap>
        </div>

        <div class="col-md-12">
          <app-practice-location-fap [location]="practiceDetails?.location" [address]="practiceDetails?.providerSummary?.address" iconName="cbhs"></app-practice-location-fap>
        </div>

        <div class="col-md-12">
          <app-practice-openinghours-fap [openingHours]="practiceDetails?.providerWeeklyOpening"></app-practice-openinghours-fap>
        </div>

      </div>
    </div>

    <div class="col-xs-12 col-md-9">
      <div class="row practice-body">

        <div class="col-xs-12 hero-img-container d-block d-sm-none">
          <div class="hero-img">
            <div class="practice-img" [ngStyle]="{'background-image': 'url(' + practiceDetails?.providerSummary?.providerPicture + ')'}"></div>
          </div>
        </div>


        <div class="col-xs-12 col-md-12 ">

          <div class="row d-none d-sm-block">
            <div class="breadcrumb">{{practiceDetails?.providerSummary?.address?.state}} - {{practiceDetails?.providerSummary?.address?.suburb}} - {{practiceDetails?.providerSummary?.modality?.name}}</div>
          </div>
          <div class="row">
            <div class="col-md-8">

              <h1 class="newTest">
                {{practiceDetails?.providerSummary?.businessName}}
                <span *ngIf="practiceDetails?.isRegistered" class="checked"></span>
              </h1>
              <h4>
                {{practiceDetails?.providerSummary?.address?.line1}}
                {{practiceDetails?.providerSummary?.address?.suburb}} {{practiceDetails?.providerSummary?.address?.state}} {{practiceDetails?.providerSummary?.address?.postcode}}
              </h4>

              <app-star-rating-fap *ngIf = "hasReviewFeature"
                                   [averageStarRating]="practiceDetails?.averageStarRating"
                                   [reviewCount]="practiceDetails?.reviewCount"></app-star-rating-fap>

              <br class="d-block d-sm-none" />

              <div *ngIf="hasReviewFeature && (practiceDetails?.reviewCount > 100)" class="row review-image" [ngClass]="{'img1000' : practiceDetails?.reviewCount >= 1000,'img750': practiceDetails?.reviewCount >= 750 && practiceDetails?.reviewCount < 1000,'img500': practiceDetails?.reviewCount >= 500 && practiceDetails?.reviewCount < 750,'img250': practiceDetails?.reviewCount >= 250 && practiceDetails?.reviewCount < 500,'img100': practiceDetails?.reviewCount >= 100 && practiceDetails?.reviewCount < 250,'imgHide': practiceDetails?.reviewCount < 100}"></div>
            </div>


            <div class="col-xs-12 cbhs-logo d-block d-sm-none" *ngIf="phiGroupProviderTypes != null && phiGroupProviderTypes.length > 0">
              <img src="https://whitecoat-res.s3.ap-southeast-2.amazonaws.com/cbhs/cbhs_logo.png" alt="CBHS" class="cbhs-logo-size" />
            </div>

            <div class="col-xs-12 col-sm-12 col-md-4">
              <app-booking-fap [providerId]="practiceDetails?.providerSummary.providerId"
                               [searchCriteria]="searchCriteria"
                               [providerName]="practiceDetails?.providerSummary.displayName.asFullName"
                               [modality]="practiceDetails?.providerSummary.modality.name"
                               [gaTagCSSSuffix]="'Profile'"
                               [onlineBookingDetails]="practiceDetails?.onlineBookingDetails"></app-booking-fap>

              <a *ngIf="hasReviewFeature" href="/review-a-provider/{{practiceDetails?.providerSummary?.modality?.name}}/{{practiceDetails?.providerSummary?.providerId}}" class="btn leave-review pull-right d-none d-sm-block">Leave a Review</a>

              <div class="col-xs-12 cbhs-logo d-none d-sm-block" *ngIf="phiGroupProviderTypes != null && phiGroupProviderTypes.length > 0">
                <img src="https://whitecoat-res.s3.ap-southeast-2.amazonaws.com/cbhs/cbhs_logo.png" alt="CBHS" class="cbhs-logo-size" />
              </div>

            </div>

            <div class="col-xs-12 d-block d-sm-none">
              <app-practice-contact-fap [contactDetails]="practiceDetails?.contactDetails"
                                        [websiteAndSocialMediaLinks]="practiceDetails?.websiteAndSocialMediaLinks"
                                        [providerSummary]="practiceDetails?.providerSummary"
                                        [onlineBookingDetails]="practiceDetails?.onlineBookingDetails"
                                        [gaTagCSSSuffix]="'Profile'"
                                        [searchCriteria]="searchCriteria"
                                        [index]="1">
              </app-practice-contact-fap>
            </div>


          </div>

          <div class="accordion d-block d-sm-none" id="accordion1">
            <div class="accordion-group">
              <div class="accordion-heading">
                <div class="accordion-toggle" data-bs-toggle="collapse" data-bs-parent="#accordion1" href="#collapse1">
                  Map Location
                </div>
              </div>
              <div id="collapse1" class="accordion-body collapse in show">
                <app-practice-location-fap [location]="practiceDetails?.location" [address]="practiceDetails?.providerSummary?.address"></app-practice-location-fap>
              </div>

              <div class="accordion-heading">
                <div class="accordion-toggle" data-bs-toggle="collapse" data-bs-parent="#accordion1" href="#collapse2">
                  Opening Hours
                </div>
              </div>
              <div id="collapse2" class="accordion-body collapse in">
                <app-practice-openinghours-fap [openingHours]="practiceDetails?.providerWeeklyOpening"></app-practice-openinghours-fap>
              </div>
            </div>
          </div>

          <app-practice-links-fap [activeLink]="activeTab"
                                  [showServices]="showServices"
                                  [showHealthfunds]="showHealthFunds"
                                  [showPractitioners]="showPractitioners"
                                  [showAbout]="showAbout"
                                  (onLinkClicked)="tabOnChange($event)"></app-practice-links-fap>

          <app-practice-practitioners-fap *ngIf="showPractitioners" [practiceName]="practiceDetails?.providerSummary?.businessName" [practitioners]="practitioners" [practiceId]="practiceDetails?.providerSummary?.providerId" [hidden]="activeTab!=='Practitioner'"></app-practice-practitioners-fap>

          <app-practice-about *ngIf="showAbout" [practiceDetails]="practiceDetails" [hidden]="activeTab!=='about'"></app-practice-about>
          <app-practice-services *ngIf="showServices" [services]="practiceDetails?.services" [hidden]="activeTab!=='services'"></app-practice-services>
          <app-practice-health-funds *ngIf="showHealthFunds" [healthFunds]="practiceDetails?.healthFunds" [hidden]="activeTab!=='healthfunds'"></app-practice-health-funds>
          <app-practice-reviews-fap *ngIf = "hasReviewFeature" [ratingSummaries]="practiceDetails?.ratingSummaries" [reviews]="practiceDetails?.comments" [hidden]="activeTab!=='reviews'"></app-practice-reviews-fap>

        </div>

      </div>
    </div>

  </div>
</div>
<div class="container container-footer d-none d-sm-block">
  <div class="container">
    <div class="row">
      {{practiceDetails?.providerSummary?.address?.state}} - {{practiceDetails?.providerSummary?.address?.suburb}} - {{practiceDetails?.providerSummary?.modality?.name}}
    </div>
  </div>
</div>


