import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from './pages/home/home.component';

// directory
import { ListComponent } from './pages/list/list.component';
import { PracticeComponent } from './pages/practice/practice.component';
import { PractitionerComponent } from './pages/practitioner/practitioner.component';
import { PracticeFapComponent } from './pages/practice-fap/practice-fap.component';
import { PracticeCbhsCorpFapComponent } from './pages/practice-cbhs-corp-fap/practice-cbhs-corp-fap.component';

// providers
import { PasswordResetRequestComponent } from './pages/password-reset-request/password-reset-request.component';
import { PasswordResetEnterComponent } from './pages/password-reset-enter/password-reset-enter.component';

// error
import { ErrorComponent } from './pages/error/error.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  // Directory
  { path: 'directory', component: ListComponent },
  {
    path: 'directory/location/:modality/:latitude/:longitude',
    component: ListComponent,
  },
  {
    path: 'directory/:modality/:state/:postcode/:suburb',
    component: ListComponent,
  },
  { path: 'directory/:modality/:state/:suburb', component: ListComponent },
  { path: 'directory/:modality/:searchText', component: ListComponent },
  //  practice profile
  {
    path: 'directory/practice/:modality/:state/:suburb/:businessName/:providerId/:modalityId',
    component: PracticeComponent,
  },
  //  practitioner profile
  {
    path: 'directory/practitioner/:modality/:state/:suburb/:surname/:providerId/:modalityId',
    component: PractitionerComponent,
  },
  //  CBHS
  {
    path: 'directory/practice/find-provider/cbhs/:modality/:state/:suburb/:businessName/:providerId/:modalityId',
    component: PracticeFapComponent,
  },
  {
    path: 'directory/practice/find-provider/cbhs-corporate/:modality/:state/:suburb/:businessName/:providerId/:modalityId',
    component: PracticeCbhsCorpFapComponent,
  },
  // Providers
  {
    path: 'providers/password/reset/request',
    component: PasswordResetRequestComponent,
  },
  {
    path: 'providers/password/reset/enter',
    component: PasswordResetEnterComponent,
  },

    // Error
   
    {
      path: '**',
      component: ErrorComponent,
    }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
