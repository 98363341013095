<div class="row contact">
  <div class="col-xs-6 phone-desktop" *ngIf="contactDetails?.phone">
    <a
      data-bs-toggle="modal" [attr.data-bs-target]="'#phoneModal' + index"
      [href]="'tel:' + contactDetails.phone"
      class="btn btn-default call"
      target="_blank"
    >
      Phone</a
    >
  </div>
  <div class="col-xs-6" *ngIf="contactDetails?.email">
    <a
      [href]="'mailto:' + contactDetails.email"
      data-rel="external"
      class="btn btn-default email"
      target="_blank"
      >Email</a
    >
  </div>

  <div class="col-xs-6" *ngIf="websiteAndSocialMediaLinks?.websiteUrl">
    <a
      [href]="websiteAndSocialMediaLinks?.websiteUrl"
      target="_blank"
      class="btn btn-default website"
      >Website</a
    >
  </div>

  <div class="col-xs-6">
    <app-booking-fap
      class="d-none d-sm-block"
      [useAltView]="true"
      [providerId]="providerSummary?.providerId!"
      [providerName]="providerSummary?.displayName!.asFullName!"
      [searchCriteria]="searchCriteria"
      [gaTagCSSSuffix]="gaTagCSSSuffix + 'Contact'"
      [modality]="providerSummary?.modality!.name"
      [onlineBookingDetails]="onlineBookingDetails"
    ></app-booking-fap>

    <a
      *ngIf="hasReviewFeature"
      href="/review-a-provider/{{ providerSummary?.modality?.name }}/{{
        providerSummary?.providerId
      }}"
      class="btn btn-defaul leave-review d-block d-sm-none"
      >Review</a
    >
  </div>
</div>

<!-- Show Phone Modal -->
<div
  class="modal fade in"
  [id]="'phoneModal' + index"
>
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Phone Number</h4>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
        >
        </button>
      </div>
      <div class="modal-body">
        <div class="text-center">
          {{ contactDetails?.phone }}
        </div>
      </div>
    </div>
  </div>
</div>
