import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  PLATFORM_ID,
  Inject,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import { GlobalStaticService } from 'src/app/services/global.static-service';

import { HypheniseUrlPipe } from '../../pipes/hyphenise-url.pipe';

import { SuburbAddress } from '../../interfaces/suburb-address';

import { SearchTextComponent } from './search-text/search-text.component';
import { WindowService } from 'src/app/services/window.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
  GlobalStaticSrv = GlobalStaticService;
  @ViewChild(SearchTextComponent) searchTextComponent: SearchTextComponent;
  @Output() searchClicked = new EventEmitter<void>();

  @Input() inline = false;

  modality: string | null;
  errorMessage: boolean;
  searchSuburb: SuburbAddress | null;
  listModality: string;
  searchLocation: string;

  textFilter: string | null;
  latitude: number;
  longitude: number;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private hypheniseUrl: HypheniseUrlPipe,
    private windowService: WindowService,
  ) {}

  ngOnInit() {}

  onModalitySelected(modality: string | null) {
    this.modality = modality;
    this.errorMessage = false;
  }

  onSuburbSelected(suburb: SuburbAddress | null) {
    this.searchSuburb = suburb;
    this.errorMessage = false;
  }

  onTextFilterChanged(textFilter: string | null) {
    this.textFilter = textFilter;
    this.errorMessage = false;
  }

  onSearchClicked() {
    if (this.search()) {
      //only trigger when searching is operated
      this.searchClicked.emit();
    }
  }

  search() {
    if (!this.modality) {
      this.errorMessage = true;
      return false;
    }
    if (!this.textFilter && !this.searchSuburb) {
      this.errorMessage = true;
      return false;
    }
    if (this.modality) {
      this.listModality = this.modality;
    }
    const modalitySearch = this.hypheniseUrl
      .transform(this.modality)
      .replaceAll('(', '%28')
      .replaceAll(')', '%29');

    if (!this.searchSuburb) {
      this.searchSuburb = this.tryParseAsSuburbAddress(this.textFilter);
    }

    if (this.searchSuburb) {
      const suburbFilter = this.hypheniseUrl.transform(
        this.searchSuburb.suburb,
      );
      if (isPlatformBrowser(this.platformId)) {
        this.windowService.nativeWindow.location.href = `/directory/${modalitySearch}/${this.searchSuburb.state}/${this.searchSuburb.postcode}/${suburbFilter}`;
      }
    } else if (this.textFilter) {
      if (isPlatformBrowser(this.platformId)) {
        this.windowService.nativeWindow.location.href = `/directory/${modalitySearch}/${this.textFilter}`;
      }
    }
    return true;
  }

  tryParseAsSuburbAddress(value: string | null): SuburbAddress | null {
    if (!value) {
      return null;
    }

    const address: SuburbAddress = {
      suburb: '',
      state: '',
      postcode: '',
    };
    const states = ['NSW', 'QLD', 'SA', 'VIC', 'WA', 'TAS', 'NT', 'ACT'];
    const parts = value.split(' ');

    if (!parts || parts.length < 3) {
      return null;
    }

    for (const i in parts) {
      if (parts.hasOwnProperty(i)) {
        const part = parts[i];

        if (!part || part.length === 0) {
          continue;
        }

        if (part.length === 4 && !isNaN(part as unknown as number)) {
          address.postcode = part;
        } else if (states.indexOf(part.toUpperCase()) > -1) {
          address.state = part.toLowerCase();
        } else {
          if (address.suburb) {
            address.suburb += ' ' + part;
          } else {
            address.suburb = part;
          }
        }
      }
    }

    if (address.state === null || address.postcode === null) {
      return null;
    }

    return address;
  }
}
