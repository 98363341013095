import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';

import { environment } from 'src/environments/environment';

import { ListPractitionerComponent } from '../../components/list/list-practitioner/list-practitioner.component';
import { ListPracticeComponent } from '../../components/list/list-practice/list-practice.component';
import { SearchTextComponent } from '../../components/search/search-text/search-text.component';

import { GoogleTagManagerService } from '../../services/google-tag-manager.service';
import { SettingsService } from '../../services/settings.service';
import { SharedService } from '../../services/shared.service';

import { SearchCriteria } from '../../interfaces/search-criteria';
import { Location } from '../../interfaces/location';
import { SuburbAddress } from '../../interfaces/suburb-address';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListComponent implements OnInit {
  readonly practiceTab = 'practice';
  readonly practitionerTab = 'practitioner';
  view = 'list';
  activeTab: string;
  practiceHasResults: boolean = false;
  practicionerHasResults: boolean = false;
  practiceLoaded: boolean = false;
  practicionerLoaded: boolean = false;
  listModality: string;
  modality: string;
  textFilter: string;
  searchSuburb: SuburbAddress;
  latitude: number;
  longitude: number;
  searchLocation: string;
  searchText: string;
  errorMessage = false;
  state: string;
  postcode: string;
  suburb: string;
  location: Location;
  locations: Location[];
  tabLoadedEventRaised: boolean = false;
  title = `Find Doctors & Healthcare Specialists – Book Appointment Online`;
  searchTitle: string;
  hasResults: boolean = false;
  searchPageNumber = 1; //no more than 5 premium partner per modality at the stage
  isDynamoDb: boolean;
  hasReviewFeature: boolean;
  practiceCount: number = 0;
  practitionerCount: number = 0;

  //filters may not be used, but still used in html
  isOnlineAppointment: boolean = false;
  isTelehealth: boolean = false;
  isPaywithWhitecoat: boolean = false;

  @ViewChild(SearchTextComponent) searchTextComponent: SearchTextComponent;
  @ViewChild(ListPractitionerComponent)
  practitionerList: ListPractitionerComponent;
  @ViewChild(ListPracticeComponent) practiceList: ListPracticeComponent;

  constructor(
    private route: ActivatedRoute,
    private gtmService: GoogleTagManagerService,
    private settingsService: SettingsService,
    private sharedService: SharedService,
    private titleService: Title,
    private meta: Meta,
  ) {
    this.hasReviewFeature = this.settingsService.hasReviewFeature();
    this.practiceHasResults = false;
    this.setActiveTab(this.practiceTab);
    this.isDynamoDb = environment.isDynamoDb === 'true' ? true : false;
    this.route.queryParamMap.subscribe((params) => {
      if (
        params.get('isDynamoDb') !== undefined &&
        params.get('isDynamoDb') !== null
      ) {
        this.isDynamoDb =
          params.get('isDynamoDb').toLowerCase() === 'true' ? true : false;
      }
      this.queryParametersChanged(params);
    });
  }

  ngAfterViewInit() {
    this.route.queryParamMap.subscribe((params) => {
      this.queryParametersChanged(params);
    });
  }

  ngOnInit() {
    this.route.paramMap.subscribe((val) => {
      this.initialize(val);
      this.sharedService.setSharedInitialModality(this.modality);
      this.sharedService.setSharedInitialSearchLoation(this.searchLocation);
    });
  }

  private setActiveTab(tabValue: string) {
    //default active tab is practice
    let activeValue = '';
    if (!tabValue) activeValue = this.practiceTab;
    else activeValue = tabValue.toLocaleLowerCase();

    this.activeTab =
      tabValue === this.practitionerTab
        ? this.practitionerTab
        : this.practiceTab;
  }

  queryParametersChanged(params: ParamMap) {
    this.tabLoadedEventRaised = false;
    this.setActiveTab(params.get('activeTab'));

    this.raiseTabLoadedEvent();
    this.refreshMap();
  }

  initialize(params: ParamMap) {
    this.modality = params.get('modality');
    if (this.modality) {
      this.modality = this.modality.replace(/-/g, ' ').replace(/    /g, ' - ');
    }
    this.suburb = params.get('suburb');
    if (this.suburb) {
      let suburb = '';
      this.suburb.split('-').forEach((e) => {
        if (suburb) {
          suburb += ' ';
        }
        suburb += e.substring(0, 1).toUpperCase() + e.substring(1);
      });
      this.suburb = suburb;
    }
    this.listModality = this.modality;
    this.state = params.get('state');
    this.postcode = params.get('postcode');
    this.searchText = params.get('searchText');
    const latitude = +params.get('latitude');
    const longitude = +params.get('longitude');

    if (latitude !== 0 && longitude !== 0) {
      this.location = {
        label: null,
        latitude: latitude,
        longitude: longitude,
        iconUrl: null,
      };
    }

    this.tabLoadedEventRaised = false;
    this.gtmService.trigger('SearchClickedEvent', this.searchCriteria());
    this.setSearchLocation(
      this.suburb,
      this.state,
      this.postcode,
      this.searchText,
    );

    var showModality =
      this.modality.substring(0, 1).toUpperCase() + this.modality.substring(1);
    this.searchTitle =
      "Whitecoat's search result for " +
      showModality +
      this.searchLocation +
      ' allowing users to seek for a healthcare practitioner or practice in any specific location.';
    var msg = this.hasReviewFeature ? ' or leave a review ' : ' ';

    this.titleService.setTitle(this.title);
    this.meta.updateTag({
      property: 'og:title',
      content: this.title,
    });
    let description =
      'Find a ' +
      this.modality +
      this.searchLocation +
      `. Book Appointment ${msg} today!`;
    this.meta.updateTag({
      name: 'description',
      content: description,
    });
    this.meta.updateTag({
      property: 'og:description',
      content: description,
    });
  }

  setSearchLocation(
    suburb: string,
    state: string,
    postcode: string,
    searchText: string,
  ) {
    this.searchLocation = '';

    if (suburb && suburb.length > 0) {
      this.appendToSearchLocation(
        suburb.substr(0, 1).toUpperCase() + suburb.substr(1),
      );
    }
    if (state && state.length > 0) {
      this.appendToSearchLocation(state.toUpperCase());
    }
    if (postcode && postcode.length > 0) {
      this.appendToSearchLocation(postcode);
    }
    if (!this.searchLocation || this.searchLocation.length == 0) {
      this.appendToSearchLocation(searchText);
    }
  }

  appendToSearchLocation(part: string) {
    if (part && part.length > 0) {
      if (this.searchLocation && this.searchLocation.length > 0) {
        this.searchLocation += ' ';
      }
      this.searchLocation += part;
    }
  }

  searchCriteria(): SearchCriteria {
    return {
      modality: this.modality,
      postcode: this.postcode,
      state: this.state,
      suburb: this.suburb,
      searchText: this.searchText,
    };
  }

  raiseTabLoadedEvent(): void {
    if (this.tabLoadedEventRaised) {
      return;
    }

    var searchCriteria = this.searchCriteria();
    searchCriteria.isPractice = this.isPracticeTab();
    this.gtmService.trigger('TabLoadedEvent', searchCriteria);

    this.tabLoadedEventRaised = true;
  }

  onPracticeResultsLoaded(event: any) {
    this.locations = event.locations;
    this.practiceCount = event.number;
    this.practiceHasResults = event.number > 0;
    this.practiceLoaded = true;
    this.CheckSearchContent();
    this.raiseTabLoadedEvent();
    //if practicelist is empty, then try show practitioner
    if (this.isPracticeResultEmpty()) this.setActiveTab(this.practitionerTab);
  }

  onPracticionerResultsLoaded(event: any) {
    this.locations = event.locations;
    this.practitionerCount = event.number;
    this.practicionerHasResults = event.number > 0;
    this.practicionerLoaded = true;
    this.CheckSearchContent();
    //if practitioner list is empty, then try show practice
    if (this.isPractitionerResultEmpty()) this.setActiveTab(this.practiceTab);
  }

  CheckSearchContent() {
    if (this.practiceLoaded && this.practicionerLoaded) {
      if (!this.practiceHasResults && !this.practicionerHasResults) {
        // insert no index tag (Page with no results)
        //this.meta.addTag({name: 'robots', content: 'noindex'});
      } else {
        // improve this idea for setting title (it demands handling a number agreement with modality noun)
        //this.titleService.setTitle('Find ' + this.modality + ' & Healthcare specialists powered by Whitecoat');
        this.meta.updateTag({ name: 'description', content: this.searchTitle });
        this.meta.updateTag({
          property: 'og:description',
          content: this.searchTitle,
        });
      }
    }
  }

  refreshMap() {
    if (this.practitionerList === undefined || this.practiceList === undefined)
      return;

    if (this.isPractitionerTab()) {
      this.practitionerList.refreshMap();
    } else {
      this.practiceList.refreshMap();
    }
  }

  pluralModality(modality): string {
    let singulers = ['PHARMACY'];
    if (singulers.indexOf(modality.toUpperCase()) > -1) {
      return '';
    }
    return 's';
  }

  mapViewUpdate() {
    this.view = this.view === 'list' ? 'map' : 'list';
  }

  isPracticeTab(): boolean {
    return this.activeTab === this.practiceTab;
  }

  isPractitionerTab(): boolean {
    return this.activeTab === this.practitionerTab;
  }
  showListPage(): boolean {
    return !this.isPracticeResultEmpty() || !this.isPractitionerResultEmpty();
  }

  showEmptyPage(): boolean {
    return this.isPracticeResultEmpty() && this.isPractitionerResultEmpty();
  }

  hideFilter(): boolean {
    return this.isPracticeResultEmpty() !== this.isPractitionerResultEmpty();
  }

  isPracticeResultEmpty(): boolean {
    return this.practiceLoaded && !this.practiceHasResults;
  }

  isPractitionerResultEmpty(): boolean {
    return this.practicionerLoaded && !this.practicionerHasResults;
  }

  isDataLoading(): boolean {
    return !this.practiceLoaded && !this.practicionerLoaded;
  }
}
