import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-registered-tick',
  templateUrl: './registered-tick.component.html',
  styleUrls: ['./registered-tick.component.scss'],
})
export class RegisteredTickComponent implements OnInit {
  tickClass = 'registered';
  @Input() isSmall: boolean;

  constructor() {}

  ngOnInit() {
    if (this.isSmall) {
      this.tickClass = 'registered-small';
    } else this.tickClass = 'registered';
  }
}
