<div class="maplocation">
  <div class="map">
    <div class="google-maps">
      <google-map height="100%" width="100%" [zoom]="14" [options]="{maxZoom: 17, minZoom: 13, scrollwheel: false, streetViewControl: false, zoomControlOptions: {position: 4}, mapTypeControlOptions: { mapTypeIds: [] }}">
        <map-marker
          #markerElem="mapMarker"
          *ngFor="let marker of locations"
          [position]="marker.position"
          (mapClick)="openInfo(markerElem, marker)"
        >
        </map-marker>
        <map-info-window>
          <div class="profile" *ngIf="selectedMarker">
            <a *ngIf="selectedMarker.url" href="{{selectedMarker.url}}">{{ selectedMarker.label }}</a>
            <p *ngIf="!selectedMarker.url">{{selectedMarker.label}}</p>
          </div>  
        </map-info-window>
      </google-map>
    </div>
  </div>
</div>
