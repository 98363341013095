import { Component, Input, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { SearchCriteria } from '../../../interfaces/search-criteria';
import { GoogleTagManagerService } from '../../../services/google-tag-manager.service';
import { BookingService } from '../../../services/booking.service';
import { WindowService } from 'src/app/services/window.service';

@Component({
  selector: 'app-booking-redirect-fap',
  templateUrl: './booking-redirect-fap.component.html',
  styleUrls: ['./booking-redirect-fap.component.scss'],
})
export class BookingRedirectFapComponent {
  @Input()
  useAltView: boolean;

  @Input()
  providerId: number;

  @Input()
  searchCriteria: SearchCriteria;

  @Input()
  gaTagCSSSuffix: string;

  @Input()
  bookingUrl: string;

  @Input()
  acceptTelehealth: boolean;

  @Input()
  acceptTelehealthUrl: string;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private gtmService: GoogleTagManagerService,
    private bookingService: BookingService,
    private windowService: WindowService,
  ) {
    this.useAltView = false;
  }

  openBookingUrl(event: any) {
    this.bookingService
      .bookNow(this.providerId, 'redirect')
      .subscribe(() => {});

    this.gtmService.trigger(
      this.gaTagCSSSuffix + 'BookNowClickedEvent',
      this.searchCriteria,
      this.providerId,
    );
    if (this.bookingUrl) {
      this.gtmService.trigger(
        'BookingLoadedEvent',
        this.searchCriteria,
        this.providerId,
      );
      if (isPlatformBrowser(this.platformId)) {
        this.windowService.nativeWindow.open(this.bookingUrl);
      }
    }
    event.stopPropagation();
  }
}
