import { HammerGestureConfig } from '@angular/platform-browser';
import * as Hammer from '@egjs/hammerjs';

export class HammerConfig extends HammerGestureConfig {
  override overrides = {
    swipe: { direction: Hammer.DIRECTION_ALL },
    pinch: { enable: false },
    rotate: { enable: false },
  };
}
