<div class="m-toast-notification" *ngIf="isShow">
  <div class="m-toast-notification--content">
    <div class="m-toast-notification--info"></div>
    <div class="m-toast-notification--text">
      We've updated our
      <a href="/pages/website-terms-and-conditions/" target="_blank"
        >Website Terms of Use</a
      >
      and
      <a href="/pages/privacy-policy/" target="_blank">Privacy Policy</a>.
    </div>
  </div>
  <div class="m-toast-notification--close" (click)="close()"></div>
</div>
