<form class="form-inline" [class.form-flex-inline]="inline">
  <div class="form-group search-input">
    <app-search-modality
      [inline]="inline"
      (onModalitySelected)="onModalitySelected($event)"
    ></app-search-modality>
    <div class="error-message" [hidden]="!errorMessage || modality">
      Please select a speciality
    </div>
  </div>

  <div class="form-group search-margin search-input">
    <app-search-text
      [inline]="inline"
      (onTextFilterChanged)="onTextFilterChanged($event)"
      (onSuburbSelected)="onSuburbSelected($event)"
    ></app-search-text>
    <div
      class="error-message margin-left-fix"
      [hidden]="!errorMessage || textFilter || searchSuburb"
    >
      Please select your location
    </div>
  </div>

  <div class="form-group">
    <button
      class="btn btn-green search-button"
      (click)="onSearchClicked()"
      type="button"
    >
      Search
    </button>
  </div>
</form>
