<div class="star-rating-summary col-md-12  d-none d-sm-block">
  <div class="pull-left ratingCount">
    {{averageStarRating | number:'1.1-1' }}
  </div>
  <div class="pull-left star-rating">
    <div class="star-ratings-sprite-{{size}}"><span [style.width.%]="averageStarRatingPercent()" class="star-ratings-sprite-{{size}}-rating"></span></div>
  </div>
  <div class="pull-left ratingCount" [hidden]="!reviewCount">
    ({{reviewCount}} Customer Reviews)
  </div>
</div>

<div class="star-rating-summary col-xs-12 d-block d-sm-none">
  <div class="star-rating col-xs-12 ">
    <div class="star-ratings-sprite-{{size}}"><span [style.width.%]="averageStarRatingPercent()" class="star-ratings-sprite-{{size}}-rating"></span></div>
  </div>
  <div class="ratingCount col-xs-12 " [hidden]="!reviewCount">
    {{averageStarRating | number:'1.1-1' }} ({{reviewCount}} Customer Reviews)
  </div>
</div>

