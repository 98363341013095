import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textFilter',
})
export class TextFilterPipe implements PipeTransform {
  transform(items: any[], arg?: string): any {
    if (!arg) return items;

    arg = arg.toLowerCase();
    return items.filter((item) => item.toLowerCase().indexOf(arg) !== -1);
  }
}
