import {
  Component,
  Input,
  OnInit,
  Output,
  OnChanges,
  ViewChild,
  EventEmitter,
} from '@angular/core';
import { MapLocationComponent } from '../map-location/map-location.component';

import { PractitionerService } from '../../../services/practitioner.service';
import { SettingsService } from '../../../services/settings.service';
import { PracticeService } from '../../../services/practice.service';

import { Location } from '../../../interfaces/location';
import { ProviderSearchResult } from '../../../interfaces/provider-search-result';
import { SearchResponse } from '../../../interfaces/search-response';
import { SearchCriteria } from '../../../interfaces/search-criteria';
import { GlobalStaticService } from 'src/app/services/global.static-service';

@Component({
  selector: 'app-list-practitioner',
  templateUrl: './list-practitioner.component.html',
  styleUrls: ['./list-practitioner.component.scss'],
})
export class ListPractitionerComponent implements OnInit, OnChanges {
  pageNumber: number;
  searchResults: ProviderSearchResult[];
  locations: Location[];
  hasNoResults: boolean;
  isMoreResultsAvailable: boolean;
  whitecoatDomainUrl: string;
  isLoading: boolean;
  showResults = true;
  ratingOrder: number;
  practicionersReturned: number;
  hasReviewFeature: boolean;
  offsetNumber: number = 0;

  @Input()
  searchCriteria: SearchCriteria;

  @Input()
  modality: string;

  @Input()
  searchText: string;

  @Input()
  state: string;

  @Input()
  postcode: string;

  @Input()
  suburb: string;

  @Input()
  location: Location;

  @ViewChild(MapLocationComponent)
  map: MapLocationComponent;

  @Input()
  gaTagCSSSuffix: string;

  @Output()
  onResultsLoaded: EventEmitter<any> = new EventEmitter();

  @Input()
  withBookingOnly: boolean;

  @Input()
  isTelehealth: boolean;

  @Input()
  isPaywithWhitecoat: boolean;

  @Input()
  isDynamoDb: boolean;

  isSearchList = true;
  constructor(
    private practitionerService: PractitionerService,
    private settingsService: SettingsService,
    private practiceService: PracticeService,
  ) {
    this.init();
  }

  init() {
    this.hasReviewFeature = this.settingsService.hasReviewFeature();
    this.isLoading = false;
    this.pageNumber = 1;
    this.searchResults = [];
    this.locations = [];
  }

  ngOnInit() {
    this.whitecoatDomainUrl = GlobalStaticService.whitecoatDomainUrl;
  }

  ngOnChanges() {
    this.init();
    this.updateSearchCriteria();
    this.loadResults();
  }

  getLocations(providerList: ProviderSearchResult[]): Location[] {
    if (!providerList) {
      return [];
    }

    const locations: Array<Location> = [];
    for (const p of providerList) {
      locations.push({
        label: p.displayName,
        url: p.providerUrl,
        latitude: p.latitude,
        longitude: p.longitude,
      });
    }

    return locations;
  }

  loadMore() {
    this.pageNumber++;
    this.loadResults();
  }

  loadResults() {
    this.isLoading = true;
    this.pageNumber = this.practiceService.calculatePageToLoad(
      this.offsetNumber,
      this.pageNumber,
    );
    this.practitionerService
      .search(
        this.modality,
        this.searchText,
        this.state,
        this.postcode,
        this.suburb,
        this.location,
        this.pageNumber,
        this.isDynamoDb,
        this.withBookingOnly,
        this.isTelehealth,
        this.isPaywithWhitecoat,
      )
      .subscribe((searchResponse: SearchResponse) => {
        this.searchResults = this.searchResults.concat(
          searchResponse.resultCardList,
        );
        this.searchResults.forEach((r) => {
          let newName = '';
          if (r.businessName) {
            r.businessName.split(' ').forEach((split) => {
              newName =
                (newName ? newName + ' ' : newName) +
                split.substring(0, 1).toUpperCase() +
                split.substring(1).toLowerCase();
            });
          }
          r.businessName = newName;
        });
        this.locations = this.getLocations(this.searchResults);
        this.isMoreResultsAvailable = searchResponse.isMoreResultsAvailable;
        this.refreshMap();
        this.hasNoResults = this.searchResults.length === 0;
        this.isLoading = false;
        this.practicionersReturned = this.searchResults.length;
        this.onResultsLoaded.emit({
          number: this.practicionersReturned,
          locations: this.locations,
        });
      });
  }

  onMoreClick() {
    this.loadMore();
  }

  updateSearchCriteria() {
    this.searchCriteria = {
      modality: this.modality,
      postcode: this.postcode,
      searchText: this.searchText,
      state: this.state,
      suburb: this.suburb,
    };
  }

  refreshMap() {
    if (this.map) this.map.refresh();
  }
}
