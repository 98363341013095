import { Component, Input } from '@angular/core';

import { OnlineBookingDetails } from '../../../interfaces/online-booking-details';
import { SearchCriteria } from '../../../interfaces/search-criteria';

@Component({
  selector: 'app-booking-fap',
  templateUrl: './booking-fap.component.html',
  styleUrls: ['./booking-fap.component.scss'],
})
export class BookingFapComponent {
  bookingType: string;
  onlineBookingDetails: OnlineBookingDetails;

  @Input()
  searchCriteria: SearchCriteria;

  @Input()
  gaTagCSSSuffix: string;

  @Input()
  providerId: number;

  @Input()
  providerName: string;

  @Input()
  modality: string;

  @Input()
  useAltView: boolean;

  @Input()
  isWithoutBookingButton: boolean;

  @Input('onlineBookingDetails')
  set model(value: OnlineBookingDetails) {
    this.onlineBookingDetails = value;
    this.setIntegrationType();
    if (
      value &&
      value.bookingUrl &&
      value.bookingUrl.indexOf(value.bookingId) == -1
    )
      this.updateBookingUrl();
  }

  setIntegrationType() {
    if (
      !this.onlineBookingDetails ||
      !this.onlineBookingDetails.integrationType
    ) {
      if (this.isWithoutBookingButton) {
        this.bookingType = 'disabled';
        return;
      }

      this.bookingType = 'want-to-book';
      return;
    }

    let integrationType =
      this.onlineBookingDetails.integrationType.toLowerCase();
    if (integrationType === 'iframe') {
      this.bookingType = 'iframe';
    } else {
      this.bookingType = 'httpredirect';
    }
  }

  isBookingType(bookingType: string) {
    return this.bookingType === bookingType;
  }

  updateBookingUrl(): void {
    if (!this.onlineBookingDetails || !this.onlineBookingDetails.bookingUrl)
      return;

    if (
      this.onlineBookingDetails.bookingId &&
      this.onlineBookingDetails.bookingUrl.indexOf('apptsrc') >= 0
    ) {
      this.onlineBookingDetails.bookingUrl =
        this.onlineBookingDetails.bookingUrl
          .replace('?apptsrc=', '')
          .replace('&apptsrc=', '')
          .replace('?apptsrc', '')
          .replace('&apptsrc', '');
    } else {
      this.onlineBookingDetails.bookingUrl =
        this.onlineBookingDetails.bookingUrl;
    }
  }
}
