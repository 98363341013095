import { Component, Input } from '@angular/core';
import { ProfileDetailsFap } from '../../../interfaces/profile-details-fap';

@Component({
  selector: 'app-practice-about',
  templateUrl: './practice-about.component.html',
  styleUrls: ['./practice-about.component.scss'],
})
export class PracticeAboutComponent {
  @Input()
  practiceDetails: ProfileDetailsFap;
}
