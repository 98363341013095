import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-list-not-found',
  templateUrl: './list-not-found.component.html',
  styleUrls: ['./list-not-found.component.scss'],
})
export class ListNotFoundComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
