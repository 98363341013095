<div
  class="modal fade container"
  #headerModal
  id="headerModal"
  tabindex="-1"
  role="dialog"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content content">
      <div class="modal-header header">
        <button
          type="button"
          class="close"
          data-bs-dismiss="modal"
          aria-label="Close"
          (click)="resetChild()"
        ></button>
      </div>

      <div class="modal-body">
        <app-nav-modal *ngIf="modalType === navType"> </app-nav-modal>

        <app-search-modal
          *ngIf="modalType === searchType"
          (redirectedToAnotherPage)="onRedirectedToAnotherPage()"
        ></app-search-modal>
      </div>
    </div>
  </div>
</div>
