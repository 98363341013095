import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { NavModalComponent } from '../nav-modal/nav-modal.component';
import { SharedService } from '../../../services/shared.service';

@Component({
  selector: 'app-header-modal',
  templateUrl: './header-modal.component.html',
  styleUrls: ['./header-modal.component.scss'],
})
export class HeaderModalComponent implements OnInit {
  readonly searchType: string;
  readonly navType: string;

  @Input() modalType: string;

  @ViewChild('headerModal') headerModal: ElementRef;
  @ViewChild(NavModalComponent) childNavComponent: NavModalComponent;

  constructor(private sharedService: SharedService) {
    this.searchType = this.sharedService.getSearchModalType();
    this.navType = this.sharedService.getNavModalType();
  }

  ngOnInit() {}

  resetChild() {
    if (this.childNavComponent) {
      this.childNavComponent.resetStatus();
    }
  }
  onRedirectedToAnotherPage() {
    this.closeModal();
  }

  closeModal() {
    // to decouple close method with default close button
    if (this.headerModal) {
      //remove all class and child components from the dialog
      this.headerModal.nativeElement.classList.remove('show');
      this.headerModal.nativeElement.style.display = 'none';
      document.body.classList.remove('modal-open');

      //remove backdrop, it may fix exsiting "booking online modal" background issue
      const backdrops = document.getElementsByClassName('modal-backdrop');
      for (let i = 0; i < backdrops.length; i++) {
        document.body.removeChild(backdrops[i]);
      }
    }
  }
}
