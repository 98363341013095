<div style="min-height: 402px">
  <div *ngIf="isLoadingPersionalDeatils()">
    <app-loader-map [type]="'profile'"></app-loader-map>
  </div>

  <section aria-label="Practitioner basic information">
    <app-info-header
      *ngIf="!isLoadingPersionalDeatils()"
      [isPractice]="false"
      [image]="detailImage"
      [displayName]="detailDisplayName"
      [businessName]="detailBusinessName"
      [modality]="detailModality"
      [website]="detailWebsite"
      [websiteText]="detailWebsiteText"
      [email]="detailEmail"
      [phone]="detailPhone"
      [address]="detailAddress"
      [appointment]="detailAppointment"
      [integrationType]="detailIntegrationType"
      [isClaimed]="isRegistered"
      [locations]="locations"
      [signUpPage]="signUpPage"
      [providerId]="providerid"
      [modalityId]="modalityId"
    ></app-info-header>
  </section>
</div>

<div
  class="container container-body"
  style="overflow-x: hidden; min-height: 100px"
>
  <!-- for about section -->
  <div class="info-panel" [hidden]="!isShowTabPanel()">
    <section aria-label="Practitioner detail information">
      <app-info-panel
        [about]="about"
        [openHours]="openHours"
        [services]="services"
        [facilities]="facilities"
      >
      </app-info-panel>
    </section>
  </div>

  <hr class="info-seperator" [hidden]="!isShowSeperator()" />

  <div class="loader-container" *ngIf="isLoadingPersionalDeatils()">
    <app-loader-card [hideTick]="true"></app-loader-card>
    <app-loader-card [hideTick]="true"></app-loader-card>
    <app-loader-card [hideTick]="true"></app-loader-card>
  </div>

  <div class="nearby-section" [hidden]="!isShowNearbyPanel()">
    <section aria-label="Provider nearby">
      <app-provider-nearby
        [isPractice]="false"
        [providerNearBy]="providerNearBy"
      >
      </app-provider-nearby>
    </section>
  </div>
</div>

<app-toast-notification></app-toast-notification>
