<div class="info-header-wrapper">
  <div class="info-map">
    <app-map-location-profile
      [locations]="locations"
    ></app-map-location-profile>
  </div>
  <div class="info-container">
    <div
      class="info-header"
      [class.info-header__practitioner]="!isPractice"
      [class.info-header__practice]="isPractice"
    >
      <app-registered-tick *ngIf="isClaimed"></app-registered-tick>
      <article>
        <div class="info-header--top">
          <div class="info-header--image" *ngIf="image">
            <img src="{{ image }}" />
          </div>
          <div class="info-header--title">
            <div class="info-header--practitioner">
              {{ displayName }}
            </div>
            <div class="info-header--practice" *ngIf="!isPractice">
              {{ businessName }}
            </div>
          </div>
        </div>
        <div class="info-header--second">
          <div class="info-header--badge">
            {{ modality }}
          </div>
        </div>
        <div class="info-header--body">
          <div class="info-header--website-detail" *ngIf="website">
            <a href="{{ website }}" target="_blank">{{ websiteText }}</a>
          </div>
          <div class="info-header--email-detail" *ngIf="email">
            <a href="mailto:{{ email }}" target="_blank">{{ email }}</a>
          </div>
          <div class="info-header--phone-detail" *ngIf="phone">
            <ng-container>
              <a [href]="'tel:' + getPhoneNumber()">{{ getPhoneNumber() }} </a>
            </ng-container>
          </div>
          <div class="info-header--address-detail" *ngIf="address">
            {{ address }}
          </div>
        </div>
        <div class="info-header--bottom">
          <a
            *ngIf="appointment && integrationType === 'httpredirect'"
            class="info-header--appointment"
            href="{{ appointment }}"
            target="_blank"
            >Request appointment
            <span class="info-header--appointment-icon"></span
          ></a>
          <button
            *ngIf="appointment && integrationType !== 'httpredirect'"
            class="info-header--appointment"
            data-bs-toggle="modal"
            data-bs-target="#appointmentModal"
            type="button"
          >
            Request appointment
            <span class="info-header--appointment-icon"></span>
          </button>
          <a
            *ngIf="!isClaimed"
            href="{{ signUpPage }}"
            class="info-header--claim"
          >
            Is this you? Claim profile!
          </a>
        </div>
      </article>
    </div>
  </div>
</div>
<div
  class="modal fade container appointment-modal"
  #appointmentModal
  id="appointmentModal"
  data-bs-backdrop="static"
  tabindex="-1"
  role="dialog"
>
  <div class="modal-dialog" role="document" aria-label="Request appointment">
    <div class="modal-content content">
      <div class="modal-body">
        <button
          type="button"
          class="close"
          data-bs-dismiss="modal"
          aria-label="Close"
          (click)="closeModal()"
        ></button>
        <div class="appointment-modal--header">
          <div class="appointment-modal--header-image" *ngIf="image">
            <img src="{{ image }}" />
          </div>
          <div class="appointment-modal--header-title">
            <div class="appointment-modal--header-practitioner">
              {{ displayName }}
            </div>
            <div class="appointment-modal--header-practice" *ngIf="!isPractice">
              {{ businessName }}
            </div>
          </div>
        </div>
        <div class="appointment-modal--body" *ngIf="!submitted">
          <div class="appointment-modal--body-container-top">
            <div class="appointment-modal--body-title">Request appointment</div>
            <div class="appointment-modal--body-description">
              Enter your details below and the
              {{ isPractice ? "Practice" : "Practitioner" }} will be in touch to
              confirm your appointment.
            </div>
            <div class="appointment-modal--body-alert" tabindex="0">
              This booking is for standard appointments and not for emergencies.
            </div>
            <div
              class="appointment-modal--body-form"
              [class.appointment-modal--body-form--validated]="validated"
            >
              <form [formGroup]="appointmentForm">
                <div
                  class="appointment-modal--body-form-field"
                  role="group"
                  aria-labelledby="name"
                  tabindex="0"
                >
                  <div class="appointment-modal--body-form-label">Name</div>
                  <div class="appointment-modal--body-form-input">
                    <input
                      type="text"
                      formControlName="name"
                      placeholder="Name"
                      aria-required="true"
                      [attr.aria-label]="validated &&
                      appointmentForm.controls['name'].invalid &&
                      (appointmentForm.controls['name'].dirty ||
                        appointmentForm.controls['name'].touched)
                          ? appointmentForm.controls['name'].errors['required'] ? 'Please complete this required field, Name' : 'Please enter a valid name, Name' 
                          : ''"
                      #nameDom
                    />
                    <div
                      *ngIf="
                        validated &&
                        appointmentForm.controls['name'].invalid &&
                        (appointmentForm.controls['name'].dirty ||
                          appointmentForm.controls['name'].touched)
                      "
                      class="appointment-modal--body-form-error"
                      aria-live="polite"
                    >
                      <div
                        *ngIf="
                          appointmentForm.controls['name'].errors['required']
                        "
                      >
                        Please complete this required field
                      </div>
                      <div
                        *ngIf="
                          !appointmentForm.controls['name'].errors['required']
                        "
                      >
                        Please enter a valid name
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="appointment-modal--body-form-field"
                  role="group"
                  aria-labelledby="mobile number"
                  tabindex="0"
                >
                  <div class="appointment-modal--body-form-label">
                    Mobile number
                  </div>
                  <div class="appointment-modal--body-form-input">
                    <input
                      type="text"
                      formControlName="mobile"
                      placeholder="Mobile number"
                      aria-required="true"
                      [attr.aria-label]="validated &&
                      appointmentForm.controls['mobile'].invalid &&
                      (appointmentForm.controls['mobile'].dirty ||
                        appointmentForm.controls['mobile'].touched)
                          ? appointmentForm.controls['mobile'].errors['required'] ? 'Please complete this required field, Mobile number' : 'Please enter a valid mobile number (eg 0412345678), Mobile number' 
                          : ''"
                      #mobileDom
                    />
                    <div
                      *ngIf="
                        validated &&
                        appointmentForm.controls['mobile'].invalid &&
                        (appointmentForm.controls['mobile'].dirty ||
                          appointmentForm.controls['mobile'].touched)
                      "
                      class="appointment-modal--body-form-error"
                      aria-live="polite"
                    >
                      <div
                        *ngIf="
                          appointmentForm.controls['mobile'].errors['required']
                        "
                      >
                        Please complete this required field
                      </div>
                      <div
                        *ngIf="
                          !appointmentForm.controls['mobile'].errors['required']
                        "
                      >
                        Please enter a valid mobile number (eg 0412345678)
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="appointment-modal--body-container-bottom">
            <div class="appointment-modal--body-button">
              <button
                [class.loading]="isLoading"
                type="submit"
                (click)="bookAppointment()"
              >
                Submit
                <app-loader
                  *ngIf="isLoading"
                  class="more-btn-loader"
                ></app-loader>
              </button>
            </div>
          </div>
        </div>
        <div class="appointment-modal--body" *ngIf="submitted">
          <div class="appointment-modal--body-container-top">
            <div
              class="appointment-modal--body-title appointment-modal--body-title__2"
            >
              Thank you, you’ll be contacted soon.
            </div>
            <div class="appointment-modal--body-description">
              We’ll notify {{ displayName }} right away that you have requested
              an appointment. Expect a phone call within the next business day.
            </div>
          </div>
          <div class="appointment-modal--body-container-bottom">
            <div class="appointment-modal--body-button">
              <button
                type="submit"
                (click)="closeModal()"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
