import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from 'src/environments/environment';
import { GlobalStaticService } from './services/global.static-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  showHeaderFooter: boolean = true;

  constructor(private router: Router) {
    GlobalStaticService.whitecoatDomainUrl = environment.whitecoatDomainUrl;
    GlobalStaticService.whitecoatDomainPagesUrl =
      environment.whitecoatDomainPagesUrl;
    GlobalStaticService.API_BASE_URL = environment.API_BASE_URL;
    GlobalStaticService.ProviderPortalUrl = environment.ProviderPortalUrl;

    this.showHeaderFooter = true;
    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        if (event['url'].indexOf('practice/find-provider') != -1) {
          this.showHeaderFooter = false;
        }
      }
    });
  }
}
