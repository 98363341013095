<div class="col-md-4">
  <h3>Ratings</h3>
  <ul class="ratingSummary">
    <li *ngFor="let s of ratingSummaries">
      <div class="row">
        <div class="star-rating-summary col-md-12 col-xs-12">
          <div class="pull-left star-rating">
            <div class="star-ratings-sprite-sm"><span [style.width.%]="averageStarRatingPercent(s.score)" class="star-ratings-sprite-sm-rating"></span></div>
          </div>
        </div>

      </div>
      <div class="row ratingName">
        {{s.question.ratingName}}
      </div>
    </li>
  </ul>
</div>
<div class="col-md-8" *ngIf="pagedReviews.length > 0">
  <h3>Comments</h3>

  <ul class="comments">
    <li class="comment" *ngFor="let r of pagedReviews">
      {{r.text}}
      <p class="ratingDate">
        {{r.createdDate | date: 'dd/MM/yyyy' }}
      </p>
      <ul [hidden]="!r.reply.text">
        <li class="reply">
          {{r.reply.text}}
          <p class="ratingDate">
            {{r.reply.createDate | date: 'dd/MM/yyyy' }}
          </p>
        </li>
      </ul>

    </li>
  </ul>

  <ul class="pagination" *ngIf="pageNumbers && pageNumbers.length > 1">
    <li *ngFor="let p of pageNumbers">
      <a (click)="gotoPage(p)">{{p + 1}}</a>
    </li>
  </ul>
</div>
