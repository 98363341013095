import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { SearchBaseService } from './search-base.service';

import { SearchResponse } from '../interfaces/search-response';
import { Location } from '../interfaces/location';
import { Practitioner } from '../interfaces/practitioner';
import { ProviderSearchResult } from '../interfaces/provider-search-result';
import { environment } from 'src/environments/environment';

@Injectable()
export class PractitionerService extends SearchBaseService {
  private practitionerApiUrl = environment.API_BASE_URL + 'api/practitioner';

  constructor(protected override http: HttpClient) {
    super(http);
  }

  getPractitionerByProfileId(
    providerId: string,
    modalityId: string,
  ): Observable<Practitioner> {
    return this.http
      .get(this.practitionerApiUrl + `/${providerId}/${modalityId}`)
      .pipe(
        map((response: any) => response as Practitioner),
        catchError(<T>(error: any, c?: T) => {
          return throwError(() => {
            error.error || 'Server error';
          });
        }),
      );
  }

  search(
    modality: string,
    searchText: string,
    state: string,
    postcode: string,
    suburb: string,
    location: Location,
    pageNumber: number,
    isDynamoDb: boolean = false,
    withBookingOnly: boolean,
    IsTeleHealth: boolean,
    isPaywithWhitecoat: boolean,
  ): Observable<SearchResponse> {
    return this.direcotrySearch(
      this.practitionerApiUrl,
      modality,
      searchText,
      state,
      postcode,
      suburb,
      location,
      pageNumber,
      isDynamoDb,
      withBookingOnly,
      IsTeleHealth,
      isPaywithWhitecoat,
    );
  }

  getPractitionerListNearby(
    excludedId: string,
    modality: string,
    state: string,
    postcode: string,
    suburb: string,
  ): Observable<ProviderSearchResult[]> {
    return this.http
      .get(
        this.practitionerApiUrl +
          `/nearby/${modality}${this.getNearbyQueryString(excludedId, state, postcode, suburb)}`,
      )
      .pipe(
        map((response: any) => response as ProviderSearchResult[]),
        catchError(<T>(error: any, c?: T) => {
          return throwError(() => {
            error.error || 'Server error';
          });
        }),
      );
  }
}
