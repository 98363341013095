<div class="panel panel-default practicioner-panel" *ngIf="provider">
  <div class="panel-body" style="position: relative">
    <a [routerLink]="[provider.providerUrl]">
      <app-registered-tick *ngIf="provider.isRegistered"></app-registered-tick>
      <div>
        <p class="title">
          {{ provider.displayName }}
        </p>
        <p class="info">
          {{ provider.modalityName }}
        </p>
      </div>
    </a>
  </div>
</div>
