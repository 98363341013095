import { HttpClient } from '@angular/common/http';
import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Location } from '../../../interfaces/location';
import { GlobalStaticService } from 'src/app/services/global.static-service';

@Component({
  selector: 'app-info-header',
  templateUrl: './info-header.component.html',
  styleUrls: ['./info-header.component.scss'],
})
export class InfoHeaderComponent implements OnInit, OnChanges {
  @Input() isPractice: boolean;
  @Input() image: string;
  @Input() displayName: string;
  @Input() businessName: string;
  @Input() modality: string;
  @Input() website: string;
  @Input() websiteText: string;
  @Input() email: string;
  @Input() phone: string;
  @Input() address: string;
  @Input() appointment: string;
  @Input() integrationType: string;
  @Input() isClaimed: boolean;
  @Input() locations: Location[];
  @Input() signUpPage: string;
  @Input() providerId: string;
  @Input() modalityId: string;

  @ViewChild('nameDom') nameDom: ElementRef;
  @ViewChild('mobileDom') mobileDom: ElementRef;
  @ViewChild('appointmentModal') appointmentModal;
  validated: boolean = false;
  submitted: boolean = false;
  appointmentForm: FormGroup;
  isLoading: boolean = false;

  url = GlobalStaticService.API_BASE_URL;

  constructor(
    private formBuilder: FormBuilder,
    private httpClient: HttpClient,
  ) {}

  ngOnInit() {
    this.resetForm();
  }

  ngOnChanges() {
    this.resetForm();
  }

  bookAppointment() {
    this.validated = true;
    if (this.appointmentForm.valid) {
      this.isLoading = true;
      this.httpClient
        .post(this.url + `api/booking/booknow-email`, {
          patientName: this.appointmentForm.get('name').value,
          patientPhoneNumber: this.appointmentForm.get('mobile').value,
          providerId: this.providerId,
          isPractice: this.isPractice,
          modalityId: this.modalityId,
        })
        .subscribe(
          (response) => {
            console.log(response);
            this.isLoading = false;
            this.submitted = true;
          },
          (error) => {
            console.error(error);
            this.isLoading = false;
          },
        );
    } else {
      this.appointmentForm.get('name').markAsDirty();
      this.appointmentForm.get('mobile').markAsDirty();
      if (this.appointmentForm.get('name').errors) {
        this.nameDom.nativeElement.focus();
      } else if (this.appointmentForm.get('mobile').errors) {
        this.mobileDom.nativeElement.focus();
      }
    }
  }

  resetForm() {
    this.validated = false;
    this.submitted = false;
    this.appointmentForm = this.formBuilder.group({
      name: [
        null,
        [
          Validators.required,
          Validators.pattern(/^[a-zA-Z]{1,}(\ [a-zA-Z]{1,})*$/),
        ],
      ],
      mobile: [
        null,
        [Validators.required, Validators.pattern(/^(04[0-9]{8})$/)],
      ],
    });
  }

  closeModal() {
    if (!this.submitted) {
      this.resetForm();
    }
  }

  getPhoneNumber() {
    if (this.phone) {
      if (this.phone.indexOf('02') === 0 && this.phone.length === 10) {
        return `${this.phone.substring(0, 2)} ${this.phone.substring(
          2,
          6,
        )} ${this.phone.substring(6, 10)}`;
      }
      //the logic here is wired
      if (
        (this.phone.indexOf('04') === 0 && this.phone.length === 10) ||
        (this.phone.indexOf('02') !== 0 && this.phone.indexOf('04') !== 0)
      ) {
        return `${this.phone.substring(0, 4)} ${this.phone.substring(
          4,
          7,
        )} ${this.phone.substring(7, 10)}`;
      }
    }
    return null;
  }
}
