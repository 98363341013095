import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { SharedService } from '../../services/shared.service';
import { GlobalStaticService } from 'src/app/services/global.static-service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  GlobalStaticSrv = GlobalStaticService;
  readonly searchModalType: string;
  readonly navModalType: string;
  modality: string;
  textFilter: string;
  latitude: number;
  longitude: number;
  modalType: string;

  // component for mobile view dropdown icon
  downArrowImage = 'url(/assets/images/chevron-down-blue.svg)';
  upArrowImage = 'url(/assets/images/chevron-up-blue.svg)';
  testImage: string = '';

  isRegisterExpanded = false;
  showDropdown: boolean = false;

  page: string = '';

  blurRegisterTimeout;

  // registerList: any;

  // location constructor for back button
  constructor(
    private sharedService: SharedService,
    private location: Location,
    private router: Router,
  ) {
    this.searchModalType = sharedService.getSearchModalType();
    this.navModalType = sharedService.getNavModalType();
    this.page = this.router.url.split('?')[0];
    this.router.events.subscribe(() => {
      this.page = this.router.url.split('?')[0];
    });
  }

  ngOnInit() {}

  // function for dropdown Register list
  toggleImage(ddpName: string) {
    switch (ddpName) {
      case 'register':
        this.isRegisterExpanded = !this.isRegisterExpanded;
        break;
      default:
        this.isRegisterExpanded = false;
        break;
    }
  }

  blurRegister() {
    // temp solution, blocked by Angular 17 upgrade
    this.blurRegisterTimeout = setTimeout(() => {
      const test = document.getElementById('registerList');
      test?.classList.remove('show');
      this.toggleImage('');
    }, 305);
  }

  focusRegister() {
    if (this.blurRegisterTimeout) {
      clearTimeout(this.blurRegisterTimeout);
    }
  }

  returnImage(ddpName: string) {
    let isExpanded = false;
    switch (ddpName) {
      case 'register':
        isExpanded = this.isRegisterExpanded;
        break;
      default:
        break;
    }

    if (isExpanded) {
      return this.upArrowImage;
    } else {
      return this.downArrowImage;
    }
  }

  goBack() {
    this.location.back();
  }

  toggleDropdown() {
    this.showDropdown = !this.showDropdown;
  }

  openModal(mType: string) {
    this.sharedService.sendSubjectMsg(mType);
    this.modalType = mType;
  }
}
