<div class="panel panel-default practicioner-panel" *ngIf="provider">
  <div class="panel-body">
    <div>
      <a [routerLink]="[provider.providerUrl]">
        <app-registered-tick
          *ngIf="provider.isRegistered"
        ></app-registered-tick>
        <div>
          <p class="title">
            {{ getCardTitle() }}
          </p>
          <p class="info" [ngClass]="getModalityInfoClass()">
            {{ provider.modality.name }}
          </p>

          <p
            class="practitioner-number"
            *ngIf="
              isPractice &&
              provider.practitioners &&
              provider.practitioners.length
            "
          >
            {{ provider.practitioners.length }} practitioner<span
              *ngIf="provider.practitioners.length > 1"
              >s</span
            >&nbsp;at this practice
          </p>

          <p *ngIf="!isPractice && provider.businessName" class="businessName">
            {{ provider.businessName }}
          </p>
          <p class="address-info">
            {{ provider.address }} {{ provider.suburb }} {{ provider.state }}
            {{ provider.postcode }}
          </p>
        </div>
      </a>
    </div>
  </div>
</div>
