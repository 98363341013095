import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-toast-notification',
  templateUrl: './toast-notification.component.html',
  styleUrls: ['./toast-notification.component.scss'],
})
export class ToastNotificationComponent implements OnInit {
  isShow: boolean = false;

  ngOnInit() {
    const toastNotification = localStorage.getItem('toast-notification');
    const now = new Date();
    const expiredAt = now.getTime() - 6 * 30 * 24 * 60 * 60 * 1000; // 6 months
    if (toastNotification && Number(toastNotification) >= expiredAt) {
      this.isShow = false;
    } else {
      this.isShow = true;
    }
  }

  close() {
    const now = new Date();
    localStorage.setItem('toast-notification', now.getTime().toString());
    this.isShow = false;
  }
}
