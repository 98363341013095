import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { PatientDetails } from '../interfaces/patient-details';
import { environment } from 'src/environments/environment';

@Injectable()
export class BookingService {
  private bookingApiUrl = environment.API_BASE_URL + 'api/booking/';
  constructor(private http: HttpClient) {}

  wantToBookUrl = 'want-to-book';
  bookNowUrl = 'booknow';

  submitWantToBook(patientDetails: PatientDetails): Observable<boolean> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http
      .post(
        this.bookingApiUrl + this.wantToBookUrl,
        JSON.stringify(patientDetails),
        { headers },
      )
      .pipe(
        map((response: any) => response),
        catchError(<T>(error: any, c?: T) => {
          return throwError(() => new Error(error.error || 'Server error'));
        }),
      );
  }

  bookNow(providerId: number, source: string): Observable<boolean> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let qs =
      'utm_source=Whitecoat&utm_medium=referral&utm_campaign=whitecoat_booking';

    if (this.bookNowUrl.indexOf('?') > -1) {
      let params = this.bookNowUrl.split('?');

      if (qs !== params[1]) {
        this.bookNowUrl = params[0] + '?' + qs + '&' + params[1];
      }
    } else {
      this.bookNowUrl = this.bookNowUrl + '?' + qs;
    }

    return this.http
      .post(
        this.bookingApiUrl + this.bookNowUrl,
        JSON.stringify({ ProviderId: providerId, Source: source }),
        { headers },
      )
      .pipe(
        map((response: any) => response),
        catchError(<T>(error: any, c?: T) => {
          return throwError(() => new Error(error.error || 'Server error'));
        }),
      );
  }
}
