import {
  Component,
  OnInit,
  OnChanges,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { MapLocationComponent } from '../map-location/map-location.component';

import { PracticeService } from '../../../services/practice.service';
import { SettingsService } from '../../../services/settings.service';

import { EscapeUrlPipe } from '../../../pipes/escape-url.pipe';

import { Location } from '../../../interfaces/location';
import { ProviderSearchResult } from '../../../interfaces/provider-search-result';
import { SearchResponse } from '../../../interfaces/search-response';
import { SearchCriteria } from '../../../interfaces/search-criteria';
import { GlobalStaticService } from 'src/app/services/global.static-service';

@Component({
  selector: 'app-list-practice',
  templateUrl: './list-practice.component.html',
  styleUrls: ['./list-practice.component.scss'],
})
export class ListPracticeComponent implements OnInit, OnChanges {
  pageNumber: number;
  searchResults: ProviderSearchResult[];
  locations: Location[];
  isMoreResultsAvailable: boolean;
  whitecoatDomainUrl: string;
  isLoading: boolean;
  ratingOrder: number;
  practicesReturned: number;
  isPracticeBooking: boolean = true;
  premiumList: ProviderSearchResult[];
  offsetNumber: number;
  isSearchList: boolean = true;
  profileLink: string;

  @Input()
  gaTagCSSSuffix: string;

  @Input()
  searchCriteria: SearchCriteria;

  showResults = true;

  @Input()
  modality: string;

  @Input()
  searchText: string;

  @Input()
  state: string;

  @Input()
  postcode: string;

  @Input()
  suburb: string;

  @Input()
  location: Location;

  @ViewChild(MapLocationComponent)
  map: MapLocationComponent;

  @Output()
  onResultsLoaded: EventEmitter<any> = new EventEmitter();

  @Input()
  withBookingOnly: boolean;

  @Input()
  isTelehealth: boolean;

  @Input()
  isPaywithWhitecoat: boolean;

  @Input()
  isDynamoDb: boolean;

  constructor(
    private router: Router,
    private escapeUrlPipe: EscapeUrlPipe,
    private practiceService: PracticeService,
    private settingsService: SettingsService,
  ) {
    this.init();
  }

  init() {
    this.isLoading = false;
    this.pageNumber = 1;
    this.searchResults = [];
    this.locations = [];

    this.isPracticeBooking = true;
  }

  ngOnInit() {
    this.whitecoatDomainUrl = GlobalStaticService.whitecoatDomainUrl;
  }

  ngOnChanges() {
    this.init();
    this.updateSearchCriteria();
    this.loadResults();
  }

  getLocations(practiceList: ProviderSearchResult[]): Location[] {
    if (!practiceList) {
      return [];
    }

    const locations: Array<Location> = [];
    for (const p of practiceList) {
      const providerUrl = this.router
        .createUrlTree([
          '/directory/practice',
          this.escapeUrl(p.modality.name),
          p.state,
          this.escapeUrl(p.suburb),
          this.escapeUrl(this.getPracticeName(p)),
          p.providerId,
          p.modality.id,
        ])
        .toString();
      locations.push({
        label: p.displayName,
        url: providerUrl,
        latitude: p.latitude,
        longitude: p.longitude,
      });
    }

    return locations;
  }

  escapeUrl(url: string): string {
    return this.escapeUrlPipe.transform(url);
  }

  loadMore() {
    this.pageNumber++;
    this.loadResults();
  }

  loadResults() {
    this.isLoading = true;
    this.pageNumber = this.practiceService.calculatePageToLoad(
      this.offsetNumber,
      this.pageNumber,
    );
    this.practiceService
      .search(
        this.modality,
        this.searchText,
        this.state,
        this.postcode,
        this.suburb,
        this.location,
        this.pageNumber,
        this.isDynamoDb,
        this.withBookingOnly,
        this.isTelehealth,
        this.isPaywithWhitecoat,
      )
      .subscribe((searchResponse: SearchResponse) => {
        this.searchResults = this.searchResults.concat(
          searchResponse.resultCardList,
        );
        this.locations = this.getLocations(this.searchResults);
        this.isMoreResultsAvailable = searchResponse.isMoreResultsAvailable;
        this.practicesReturned = this.searchResults.length;
        this.onResultsLoaded.emit({
          number: this.practicesReturned,
          locations: this.locations,
        });
        this.refreshMap();
        this.isLoading = false;
      });
  }

  getPracticeName(provider: ProviderSearchResult) {
    if (provider.businessName) {
      return provider.businessName;
    } else {
      return provider.displayName;
    }
  }

  onMoreClick() {
    this.loadMore();
  }

  refreshMap() {
    this.map.refresh();
  }

  isDefaultProviderPicture(providerPicture: string): boolean {
    return providerPicture.endsWith('default-profile.png');
  }

  updateSearchCriteria() {
    this.searchCriteria = {
      modality: this.modality,
      postcode: this.postcode,
      searchText: this.searchText,
      state: this.state,
      suburb: this.suburb,
      isPractice: true,
    };
  }
}
