<div class="row">
  <div class="col-md-12 title">
    <span>{{ sectionTitle }}</span>
  </div>

  <div class="desktop-only">
    <div
      *ngFor="let p of providerNearBy; let i = index"
      class="col-sm-6 col-lg-4 col-12"
    >
      <app-provider-card [isPractice]="isPractice" [provider]="p">
      </app-provider-card>
    </div>
  </div>

  <div
    #providerNearby
    id="providerNearby"
    class="carousel slide provider-list mobile-only pointer-event"
    data-bs-ride="false"
    data-bs-interval="false"
  >
    <!-- contents -->
    <div class="carousel-inner">
      <div
        *ngFor="let p of providerNearBy; let i = index"
        [ngClass]="i === 0 ? 'carousel-item active' : 'carousel-item'"
        class="col-md-12"
      >
        <app-provider-card [isPractice]="isPractice" [provider]="p">
        </app-provider-card>
      </div>
    </div>

    <!-- swipe buttions -->
    <a
      class="left carousel-control swipe-button"
      data-bs-target="#providerNearby"
      data-bs-slide="prev"
    >
      <span class="glyphicon glyphicon-chevron-left"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a
      class="right carousel-control swipe-button"
      data-bs-target="#providerNearby"
      data-bs-slide="next"
    >
      <span class="glyphicon glyphicon-chevron-right"></span>
      <span class="sr-only">Next</span>
    </a>

    <!-- indicatores -->
    <div *ngIf="providerNearBy && providerNearBy.length > 1">
      <ol class="carousel-indicators">
        <li
          *ngFor="let p of providerNearBy; let i = index"
          data-bs-target="#providerNearby"
          [attr.data-bs-slide-to]="i"
          [ngClass]="{ active: i === 0 }"
        ></li>
      </ol>
    </div>
  </div>
</div>
