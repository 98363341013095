import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SettingsService } from '../../../services/settings.service';

@Component({
  selector: 'app-practice-links-fap',
  templateUrl: './practice-links-fap.component.html',
  styleUrls: ['./practice-links-fap.component.scss'],
})
export class PracticeLinksFapComponent {
  @Input()
  activeLink: string;

  @Input()
  showServices: boolean;

  @Input()
  showPractitioners: boolean;

  @Input()
  showAbout: boolean;

  @Input()
  showHealthfunds: boolean;

  @Output()
  onLinkClicked = new EventEmitter<string>();

  hasReviewFeature: boolean = false;
  constructor(private settings: SettingsService) {
    this.hasReviewFeature = settings.hasReviewFeature();
  }

  linkClicked(activeLink: any) {
    this.activeLink = activeLink;
    this.onLinkClicked.emit(activeLink);
  }
}
