<div class="row page-password-reset-request">
    <ng-container *ngIf="!submitted">
        <div class="col-lg-6 page-password-reset-request__form">
            <div class="page-password-reset-request__form-wrapper">
                <h2>Recover your account</h2>

                <form class="page-password-reset-request__reset-form" [formGroup]="resetForm">
                    <div
                    class="page-password-reset-request__reset-form-field"
                    [class.page-password-reset-request__reset-form-field--focus-decoration]="resetForm.controls['email'].invalid"
                    [class.page-password-reset-request__reset-form-field--valid-decoration]="resetForm.controls['email'].valid"

                    role="group"
                    aria-labelledby="email"
                    tabindex="0"
                    >
                        <input type="text" formControlName="email" placeholder="Enter your email" aria-required="true">
                        <div
                        *ngIf="
                            resetForm.controls['email'].invalid &&
                            (resetForm.controls['email'].dirty ||
                            resetForm.controls['email'].touched)
                        "
                        class="page-password-reset-request__reset-form-error"
                        aria-live="polite"
                        >
                            <div
                                *ngIf="
                                resetForm.controls['email'].errors['required']
                                "
                            >
                                Please fill out this field
                            </div>
                            
                            <div
                                *ngIf="
                                !resetForm.controls['email'].errors['required']
                                "
                            >
                                Please enter a valid email
                            </div>
                        </div>
                    </div>
                    <div
                    class="page-password-reset-request__reset-form-field 
                    page-password-reset-request__reset-form-field--line-decoration
                    page-password-reset-request__reset-form-field--static-decoration"
                    >
                        <button
                        class="page-password-reset-request__reset-form-button"
                        (click)="submitForm()"
                        type="button"
                        >
                        RESET PASSWORD
                        <span class="glyphicon glyphicon-menu-right"></span>
                        </button>
                    </div>
                    <div
                        *ngIf="error"
                        class="page-password-reset-request__reset-form-server-error"
                        aria-live="polite"
                        >
                        Something wrong is happening, please try again later.
                    </div>
                    <div
                    class="page-password-reset-request__reset-form-register"
                    >
                        <a href="{{
                            GlobalStaticSrv.whitecoatDomainUrl
                        }}">Register now</a>
                    </div>
                </form>
            </div>
        </div>
        <div class="col-lg-6 page-password-reset-request__intro">
            <div class="page-password-reset-request__intro-wrapper">
                <h1>Whitecoat Online Appointments</h1>
                <p>Have you tried Whitecoat's online appointments?</p>
                <ul class="page-password-reset-request__intro-list">
                    <li class="easy">
                        Easy to use, easy to setup. One off flat <br>fee - starting at less than $2 per week
                    </li>
                    <li class="never">
                        Never miss another appointment. <br>40% of requests are made out of hours.
                    </li>
                    <li class="link">
                        Link to your existing PMS, appointment <br>engine, or website
                    </li>
                </ul>
            </div>
        </div>
    </ng-container>

    <div class="page-password-reset-request__result" *ngIf="submitted">
        <div class="page-password-reset-request__result-wrapper">
         
            <h2>Email Sent</h2>
        
            <p>An email has been sent to</p>
        
            <p><strong>{{resetForm.controls['email'].value}}</strong></p>
        
            <p>
                You will receive this email within the next 2 hours. If you don’t, please <a href="{{
                    GlobalStaticSrv.whitecoatDomainUrl
                  }}/pages/contact/">contact us</a>. If you have made an error or no longer require a password reset, you can ignore this email and your request will expire in 24 hours.
            </p>
        </div>
    </div>
</div>