import { Component, EventEmitter, Output, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

import { SuburbAddress } from '../../../interfaces/suburb-address';
import { LocationService } from '../../../services/location.service';
import { SharedService } from '../../../services/shared.service';

@Component({
  selector: 'app-search-text',
  templateUrl: './search-text.component.html',
  styleUrls: ['./search-text.component.scss'],
})
export class SearchTextComponent implements OnInit {
  searchFormControl: FormControl;
  @Input() inline = false;
  @Output()
  onTextFilterChanged = new EventEmitter<string | null>();

  @Output()
  onSuburbSelected = new EventEmitter<SuburbAddress | null>();

  textFilter: Event | string;
  isCurrentlySearching: boolean;
  searchResults: SuburbAddress[];
  isTextSuggestionBoxVisible: boolean;

  optionSelected: boolean = false;

  optionTimeout: any;

  constructor(
    private locationService: LocationService,
    private sharedService: SharedService,
  ) {
    this.isCurrentlySearching = false;
    this.searchFormControl = new FormControl();
  }

  ngOnInit(): void {
    //iniitalize text
    this.textFilter = this.sharedService.getSharedInitialSearchLoation();

    this.searchFormControl.valueChanges.forEach((value: string) => {
      this.filterTextSuggestionBox(value);
    });

    //set the drop down list items, but do not show the suggestiong box in initializtion
    if (this.textFilter && this.textFilter.length > 0) {
      this.filterTextSuggestionBox(this.textFilter, false);
    }

    //so far only subscribe from the message subject to initialize the searchbox
    this.sharedService.message$.subscribe((msg) => {
      this.textFilter = this.sharedService.getSharedInitialSearchLoation();
    });
  }

  clear() {
    this.textFilter = '';
    this.onTextFilterChanged.emit(null);
    this.onSuburbSelected.emit(null);
  }

  showTextSuggestionBox() {
    if (!this.searchResults || this.searchResults.length === 0) {
      this.isTextSuggestionBoxVisible = false;
    } else {
      this.isTextSuggestionBoxVisible = true;
      clearTimeout(this.optionTimeout);
    }
  }

  hideTextSuggestionBox() {
    this.optionTimeout = setTimeout(() => {
      this.isTextSuggestionBoxVisible = false;
    }, 200);
  }

  filterTextSuggestionBox(filter: string, isShowSuggestion: boolean = true) {
    this.isTextSuggestionBoxVisible = false;
    this.onTextFilterChanged.emit(filter);
    this.onSuburbSelected.emit(null);

    if (!filter || filter.length < 3) {
      return;
    }

    if (this.isCurrentlySearching) {
      return;
    }

    this.isCurrentlySearching = true;
    this.locationService.search(filter).subscribe(
      (results: SuburbAddress[]) => {
        console.log(results);
        this.searchResults = results;
        if (isShowSuggestion) {
          if (!this.optionSelected) {
            this.showTextSuggestionBox();
          } else {
            this.optionSelected = false;
          }
        }
        this.isCurrentlySearching = false;
      },
      (error) => {
        this.isCurrentlySearching = false;
        console.log(error);
      },
    );
  }

  setSelectedLocation(suburb: SuburbAddress) {
    this.optionSelected = true;
    this.textFilter =
      suburb.suburb + ' ' + suburb.state + ' ' + suburb.postcode;
    this.onSuburbSelected.emit(suburb);
    this.onTextFilterChanged.emit(null);
    this.hideTextSuggestionBox();
  }
}
