import { Component, Input, OnInit } from '@angular/core';
import { ProviderSearchResult } from '../../interfaces/provider-search-result';

@Component({
  selector: 'app-provider-card',
  templateUrl: './provider-card.component.html',
  styleUrls: ['./provider-card.component.scss'],
})
export class ProviderCardComponent implements OnInit {
  @Input() provider: ProviderSearchResult;
  @Input() partientPracticeQueryString: string;
  @Input() isPractice: boolean;

  profileUrl: string;
  constructor() {
    this.isPractice = false;
  }

  ngOnInit() {}

  getModalityInfoClass() {
    if (this.isPractice) {
      return 'info practice-info';
    } else {
      return 'info practitioner-info';
    }
  }

  getCardTitle() {
    if (this.isPractice) {
      return this.provider.businessName
        ? this.provider.businessName
        : this.provider.displayName;
    } else return this.provider.displayName;
  }
}
