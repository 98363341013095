import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { finalize, switchMap } from 'rxjs/operators';
import { ProviderSearchResult } from '../../interfaces/provider-search-result';
import { Practice } from '../../interfaces/practice';
import { PractitionerBriefSummary } from '../../interfaces/practitioner-brief-summary';
import { SettingsService } from '../../services/settings.service';
import { SeoService } from '../../services/seo.service';
import { PracticeService } from '../../services/practice.service';
import { BaseProfileComponent } from '../../components/base-profile/base-profile.component';

@Component({
  selector: 'app-practice',
  templateUrl: './practice.component.html',
  styleUrls: ['./practice.component.scss'],
})
export class PracticeComponent extends BaseProfileComponent implements OnInit {
  practitioners: PractitionerBriefSummary[];

  override loadingPersionalInfo = true;
  override loadingNearbyProviders = true;

  constructor(
    protected override activatedRoute: ActivatedRoute,
    private practiceService: PracticeService,
    protected override settingsService: SettingsService,
    protected override titleService: Title,
    protected override seoService: SeoService,
    protected override meta: Meta,
  ) {
    super(seoService, settingsService, titleService, meta, activatedRoute);
  }

  ngOnInit() {
    this.initializeData();
  }

  initializeData() {
    this.activatedRoute.params.subscribe(
      (params: Params) => {
        this.loadingPersionalInfo = true;
        this.loadingNearbyProviders = true;

        this.modality = params['modality'];
        this.modalityId = params['modalityId'];
        this.state = params['state'];
        this.providerid = params['providerId'];
        this.suburb = params['suburb'];
        this.surname = params['businessName'];
        if (this.providerid && this.modalityId) {
          this.practiceService
            .getPracticeByProviderId(this.providerid, this.modalityId)
            .pipe(
              switchMap((practice) => {
                this.initPracticeDetails(practice);
                this.loadingPersionalInfo = false;

                //only show nearby for practice without subscription
                if (!this.detailAppointment) {
                  return this.practiceService.getPracticeListNearby(
                    this.providerid,
                    practice.profileDetails.personalSummary.modality.name,
                    this.state,
                    practice.profileDetails.personalSummary.address.postcode,
                    practice.profileDetails.personalSummary.address.suburb,
                  );
                } else {
                  return null;
                }
              }),
            )
            .pipe(
              finalize(() => {
                this.loadingNearbyProviders = false;
                this.setNearbySectionShown();
                console.log('getPracticeListNearby is completed');
              }),
            )
            .subscribe(
              (resultList: ProviderSearchResult[]) => {
                if (resultList) {
                  this.providerNearBy = resultList.slice(0, 3);
                }
              },
              (error) => {
                console.log(error);
              },
            );
        }

        this.createCanonicalURL();
      },

      (error) => console.error(error),
    );
    () => {
      this.loadingPersionalInfo = false;
      this.loadingNearbyProviders = false;
    };
  }

  initPracticeDetails(practice: Practice) {
    if (practice) {
      this.profileDetails = practice.profileDetails;
      this.practitioners = practice.practitioners;
      this.detailDisplayName = practice.name ? practice.name : '';
      this.SetProfileDetails(this.profileDetails, this.detailDisplayName);
      let practiceTitle = this.detailDisplayName.endsWith('Practice')
        ? this.detailDisplayName
        : `${this.detailDisplayName} Practice`;
      let modalityName = this.profileDetails.personalSummary.modality.name;
      let suburb =
        this.profileDetails.personalSummary.address &&
        this.profileDetails.personalSummary.address.suburb
          ? this.profileDetails.personalSummary.address.suburb
          : '';
      this.setPageTitle(practiceTitle, modalityName, suburb);
    }
  }
}
