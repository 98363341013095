import { Component, Input } from '@angular/core';
import { PractitionerBriefSummary } from '../../../interfaces/practitioner-brief-summary';

@Component({
  selector: 'app-sub-provider-list',
  templateUrl: './sub-provider-list.component.html',
  styleUrls: ['./sub-provider-list.component.scss'],
})
export class SubProviderListComponent {
  @Input() practitioners: PractitionerBriefSummary[];
  constructor() {}
}
