import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hypheniseUrl',
})
export class HypheniseUrlPipe implements PipeTransform {
  transform(url: string) {
    try {
      if (!url) return '';

      return url.toLowerCase().replace(/-/g, '--').replace(/ /g, '-');
    } catch (e) {
      return '';
    }
  }
}
