import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SuburbAddress } from '../interfaces/suburb-address';
import { environment } from 'src/environments/environment';

@Injectable()
export class LocationService {
  private locationApiUrl;
  constructor(private http: HttpClient) {
    this.locationApiUrl = environment.API_BASE_URL + 'api/location';
  }

  search(filter: string): Observable<SuburbAddress[]> {
    var searchEndpint = this.locationApiUrl + '/search/' + `${filter}`;
    return this.http.get<SuburbAddress[]>(searchEndpint);
  }

  geoLocationSearch(
    latitude: any,
    longitude: any,
  ): Observable<SuburbAddress[]> {
    var geoLocationUrl =
      this.locationApiUrl +
      '/geo?latitude=' +
      latitude +
      '&longitude=' +
      longitude;
    return this.http.get<SuburbAddress[]>(geoLocationUrl);
  }
}
