import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalStaticService } from 'src/app/services/global.static-service';
import { SettingsService } from 'src/app/services/settings.service';

import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-password-reset-enter',
  templateUrl: './password-reset-enter.component.html',
  styleUrls: ['./password-reset-enter.component.scss'],
})
export class PasswordResetEnterComponent implements OnInit {
  GlobalStaticSrv = GlobalStaticService;
  resetForm: FormGroup;
  submitted = false;
  token: string;
  error = false;

  constructor(
    private fb: FormBuilder,
    private settingService: SettingsService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.resetForm = this.fb.group({
      password: [null, [Validators.required, passwordRuleValidatior]],
      confirmPassword: [
        null,
        [Validators.required, passwordMatchingValidatior],
      ],
    });
    this.resetForm.valueChanges.subscribe(() => {
      this.error = false;
    });
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (params['Token'] || params['token']) {
        this.token = params['Token'] || params['token'];
      } else {
        this.router.navigate(['/']);
      }
    });
  }

  submitForm() {
    if (this.resetForm.valid) {
      this.error = false;
      // API request to submit
      this.settingService
        .resetPassword(
          this.token,
          this.resetForm.get('password').value,
          this.resetForm.get('confirmPassword').value,
        )
        .subscribe(
          (res) => {
            this.submitted = true;
          },
          (err) => {
            this.error = true;
          },
        );
    }
  }
}

export const passwordMatchingValidatior: ValidatorFn = (
  control: AbstractControl,
): ValidationErrors | null => {
  const formGroup = control.parent as FormGroup;

  if (!formGroup) {
    return null;
  }

  const password = formGroup.get('password');
  const confirmPassword = formGroup.get('confirmPassword');

  if (!password || !confirmPassword) {
    return null;
  }

  return password?.value === confirmPassword?.value
    ? null
    : { notmatched: true };
};

export const passwordRuleValidatior: ValidatorFn = (
  control: AbstractControl,
): ValidationErrors | null => {
  const formGroup = control.parent as FormGroup;

  if (!formGroup) {
    return null;
  }

  const password = formGroup.get('password');

  if (!password) {
    return null;
  }

  return /^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&]).*$/.test(
    password.value,
  )
    ? null
    : { notmatched: true };
};
