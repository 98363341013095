import { Component, Inject, Optional, PLATFORM_ID, inject  } from '@angular/core';
import {  isPlatformServer } from '@angular/common';
import { Response } from 'express';
import { RESPONSE } from '../../../../token/server.token';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent {
  platformId = inject(PLATFORM_ID);
  constructor(@Optional() @Inject(RESPONSE) private response: Response) {
       // Only executes server-side
       if (isPlatformServer(this.platformId)) {
        this.response?.status(404);
    }

  }
}
