import { Component, Input } from '@angular/core';
import { PractitionerBriefSummary } from '../../../interfaces/practitioner-brief-summary';

@Component({
  selector: 'app-simple-provider-card',
  templateUrl: './simple-provider-card.component.html',
  styleUrls: ['./simple-provider-card.component.scss'],
})
export class SimpleProviderCardComponent {
  @Input() provider: PractitionerBriefSummary;
  constructor() {}
}
