<hr class="top-hr" />

<ul class="nav nav-pills stacked">
  <li *ngIf="showPractitioners" (click)="linkClicked('Practitioner')" [ngClass]="{'active': activeLink === 'Practitioner' }"><a>Practitioner</a></li>
  <li *ngIf="showAbout" (click)="linkClicked('about')" [ngClass]="{'active': activeLink === 'about' }"><a>About Practice</a></li>
  <li *ngIf="showServices" (click)="linkClicked('services')" [ngClass]="{'active': activeLink === 'services' }"><a>Services</a></li>
  <li *ngIf="showHealthfunds" (click)="linkClicked('healthfunds')" [ngClass]="{'active': activeLink === 'healthfunds' }"><a>Health Funds</a></li>
  <li *ngIf ='hasReviewFeature' (click)="linkClicked('reviews')" [ngClass]="{'active': activeLink === 'reviews' }"><a>Reviews</a></li>
  </ul>

<hr class="bottom-hr" />
