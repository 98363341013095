import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GlobalStaticService } from 'src/app/services/global.static-service';
import { SettingsService } from 'src/app/services/settings.service';

import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-password-reset-request',
  templateUrl: './password-reset-request.component.html',
  styleUrls: ['./password-reset-request.component.scss'],
})
export class PasswordResetRequestComponent {
  GlobalStaticSrv = GlobalStaticService;
  resetForm: FormGroup;
  submitted = false;
  error = false;

  constructor(
    private fb: FormBuilder,
    private settingService: SettingsService,
  ) {
    this.resetForm = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
    });
    this.resetForm.valueChanges.subscribe(() => {
      this.error = false;
    });
  }

  submitForm() {
    this.error = false;
    if (this.resetForm.valid) {
      // API request to submit
      this.settingService
        .sendResetLink(this.resetForm.get('email').value)
        .subscribe(
          (res) => {
            this.submitted = true;
          },
          (err) => {
            this.error = true;
          },
        );
    }
  }
}
