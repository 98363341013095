import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-star-rating-fap',
  templateUrl: './star-rating-fap.component.html',
  styleUrls: ['./star-rating-fap.component.scss'],
})
export class StarRatingFapComponent {
  size: string;

  @Input('size')
  set model(value: string) {
    if (value && value.toLowerCase() === 'large') this.size = 'lg';
  }

  @Input()
  averageStarRating: number;

  @Input()
  reviewCount: number;

  constructor() {
    this.size = 'sm';
  }

  averageStarRatingPercent() {
    return ((this.averageStarRating / 5) * 100).toFixed(2);
  }
}
